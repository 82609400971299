const { dateIsToday } = require('../../shared/tools/helpers/dateComparator');

angular.module('mTransportApp').controller('TripInfoController', [
    '$scope',
    '$rootScope',
    '$location',
    '$translate',
    '$timeout',
    '$controller',
    'sortingTools',
    '$interval',
    function ($scope, $rootScope, $location, $translate, $timeout, $controller, sortingTools, $interval) {
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('GlobalMapController', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('PDFDirectionController', { $scope: $scope }));

        /* =======================================================================================================================
       INITIALIZATION
    =======================================================================================================================  */
        const REFRESH_INTERVAL = 1000 * 60; // 60 seconds

        $scope.initializeTripInfo = async function () {
            initializeTripInfoVariables();

            let $wrapper = $('#wrapper');
            // Make sure we don't register events twice
            $wrapper.off('show.bs.collapse hide.bs.collapse hidden.bs.collapse shown.bs.collapse', '.collapse');
            // Disable click on bootstrap collapsing events
            $wrapper.on('show.bs.collapse hide.bs.collapse', '.collapse', function () {
                $('.panel-heading.pointer').css('pointerEvents', 'none');
            });
            $wrapper.on('hidden.bs.collapse shown.bs.collapse', '.collapse', function () {
                $('.panel-heading.pointer').css('pointerEvents', 'auto');
            });

            /* Note : the view doesnt wait after asynchronous fetchtripdata to render  */
            await fetchTripData();

            // If $scope.tripInfo has been populated
            if ($scope.tripInfo) {
                if ($scope.tripInfo.route.id && $scope.canEditSettingsCommunications) {
                    getRouteDetails($scope.tripInfo.route.id);
                }

                if ($scope.tripId && ['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                    getTripForms($scope.tripId);
                }
            }
        };

        /**
         * Initialize trip info variables
         */
        function initializeTripInfoVariables() {
            $scope.tripId = $scope.getIdFromURL();
            if (!$scope.tripId) {
                let error = {
                    status: 404,
                };
                $scope.catchErrorDefault(error);
            }
            $scope.pageLoading = true;
            $scope.loading = true;
            // Current time in this format : HH:mm
            $scope.currentTimeHM;
            $scope.tripDate = null;
            $scope.tripInFuture = false;
            $scope.tripInfo = null;
            $scope.passengers = null;

            $scope.passengersToShow = [];
            $scope.timeoutPromises = [];
            $scope.searchValue = {
                value: '',
            };
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;

            $scope.loadingStops = true;
            $scope.isInit = true;
            $scope.showReport = false;
            $scope.orderType = {
                orderBy: 'internalID',
                ascending: true,
            };
            $scope.lastReport = false;
            $scope.pdfLoading = false;
            $scope.showModalAttendanceTracking = false;
            $scope.showModalNotificationsGuardians = false;
            $scope.showModalAutomaticDelayGuardians = false;
            $scope.showModalCancellationAnnouncements = false;
            $scope.announcements = {
                modalAnnouncement: false,
                delayAnnouncement: false,
                announcementMessage: '',
            };
            $scope.announcementUpdateLoading = false;
            $scope.canEditSettingsCommunications = false;
            $scope.canAnnounceDelay = false;
            $scope.canAnnounceOthers = false;
            $scope.isShowingStops = null;
            $scope.isShowingPassengers = null;
            $scope.isShowingForms = null;
            $scope.tripForms = null;
            $scope.tripFormsCount = 0;
            $scope.formsLoading = true;
            $scope.orderTypeForms = {
                orderBy: 'date',
                ascending: false,
            };
            $scope.currentNotificationsStatus = false;
            $scope.notificationsModalText = '';
            $scope.isLocateDeviceActive = false;
            $scope.route = {};

            $scope.isShowingLocateBannerFound = false;
            $scope.isShowingLocateBannerNotFound = false;
            $scope.isShowingLocateBannerExpired = false;

            $scope.newAnnouncedDelay = {
                value: '',
                enabled: true,
            };
            $scope.announcedDelayOptions = [
                { value: '', label: 'selectEllipsis' },
                { value: 'none', label: 'announcedDelayNone' },
                { value: 'fewMinutes', label: 'announcedDelayFewMinutes' },
                { value: '15min', label: 'announcedDelay15min' },
                { value: '30min', label: 'announcedDelay30min' },
                { value: '45min', label: 'announcedDelay45min' },
                { value: '60min', label: 'announcedDelay60min' },
            ];

            $scope.newCancellationAnnouncement = {
                value: '',
                enabled: true,
            };
            $scope.cancellationAnnouncementOptions = [
                { value: '', label: 'selectEllipsis' },
                { value: 'cancelled', label: 'cancelled_transport' },
                { value: 'restored', label: 'restored_transport' },
            ];

            $scope.deviceLocations = null;
            $scope.locationRequestId = null;

            // TODO : MOVE LOGIC TO MAP WHEN WE HAVE TIME TO DO IT CORRECTLY
            $scope.mustCreateSpeedDot = $rootScope.loggedUserRole !== 'observer';

            $scope.replayProgress = 1000;
            $scope.autoPlayRef = null;
            $scope.playPauseIcon = false;
        }

        /**
         * Call the function fetchTripDetails and fetchRelatedTrips to refresh data that may change on this page.
         */
        function refreshCallback() {
            // Trips that are completed (no longer in progress) will not auto refresh
            // Past trips that were in progress but not completed will not auto refresh
            // Trips that are in progress will still auto refresh
            if ($scope.isTripInProgressForToday) {
                $scope.refreshing = true;
                fetchTripDetails();
                fetchRelatedTrips();
                // Restart refresh promise
                $scope.refreshing = false;
                $scope.cancelPromiseMap();
                startPromiseMap();
            }
        }

        /**
         * Call the function fetchTripData() to refresh the data after a delay announcement
         * We have to wait until the data has updated in case someone else announced a delay too
         * For the loading to be done and to close the delay modal
         */
        async function refreshCallbackAnnouncementsUpdate() {
            await fetchTripData();
            $scope.announcements.delayAnnouncement = false;
            $scope.announcements.modalAnnouncement = false;
            $scope.announcementUpdateLoading = false;
            $scope.showModalCancellationAnnouncements = false;
            $scope.$apply();
        }

        function validatePauseRefreshCountdownCallback() {
            return $scope.refreshing || $scope.pageLoading;
        }

        function startPromiseMap() {
            $rootScope.promiseTripInfo = $scope.startRefreshPromise(500, REFRESH_INTERVAL, refreshCallback, validatePauseRefreshCountdownCallback);
        }

        /**
         * Check if the currently logged user can edit the infos in "Settings and communications" tab
         * When tripInfo.schedule is passed, user is not allowed anymore to edit setting
         * @return {Boolean} if the user is allowed or not
         */
        function checkIfUserCanEditSettingsCommunications() {
            const tripSchedule = new Date($scope.tripInfo.schedule).getTime();
            return (
                // eslint-disable-next-line no-undef
                tripSchedule > Date.now() &&
                $scope.tripDate === moment(new Date()).format('YYYY-MM-DD') &&
                $scope.tripInfo.status === 'planned' &&
                ($rootScope.loggedUserRole === 'manager' || $rootScope.loggedUserRole === 'agent')
            );
        }

        /**
         * User can locate device if it's 30 minutes before the start of the trip and if the arrival time is not reached yet
         * @return {Boolean} if the user is allowed or not to locate device
         */
        const checkIfUserCanLocateDevice = () => {
            const currentDate = new Date().getTime();
            const tripDepartureMinus30Min = new Date($scope.tripInfo.schedule).getTime() - 30 * 60000;
            const tripArrival = new Date($scope.tripInfo.scheduledArrival).getTime();

            return tripDepartureMinus30Min <= currentDate && currentDate <= tripArrival;
        };

        /**
         * Check if the currently logged user can send new announce delay (in the Settings and communications tab)
         * @return {Boolean} if the user is allowed or not, true means they are allowed
         */
        function checkIfUserCanAnnounceDelay() {
            return (
                // eslint-disable-next-line no-undef
                $scope.tripDate === moment(new Date()).format('YYYY-MM-DD') &&
                ($scope.tripInfo.status === 'planned' || $scope.tripInfo.status === 'inProgress') &&
                ($rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager')
            );
        }

        /**
         * Check if the currently logged user can cancel/restore trip
         * @return {Boolean} if the user is allowed or not, true means they are allowed
         */
        function checkIfUserCanCancelAnnouncements() {
            // eslint-disable-next-line no-undef
            const today = moment(new Date()).format('YYYY-MM-DD');

            return (
                // Check if tripDate is today or in the future
                $scope.tripDate >= today &&
                $scope.tripInfo.status === 'planned' &&
                ($rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager')
            );
        }

        /**
         * Check if the currently logged user can send announcements other than delay alerts (in the Settings and communications tab)
         * @return {Boolean} if the user is allowed or not, true means they are allowed
         */
        function checkIfUserCanAnnounceOthers() {
            return (
                // eslint-disable-next-line no-undef
                $scope.tripDate === moment(new Date()).format('YYYY-MM-DD') &&
                ($rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager')
            );
        }

        /* =======================================================================================================================
            DATA GATHERING
        =======================================================================================================================  */

        /**
         * Verify if the directions exists in the fetched data
         * @param {Object} data the data fetched from the api
         * @return {Boolean}
         */
        function isDirectionsExist(data) {
            const { stops } = data;
            return stops.some(function ({ directions }) {
                return directions.length !== 0;
            });
        }

        /**
         * Fetch the data (details) of the specified trip in the url
         * @return {Promise}
         */
        function fetchTripData() {
            if ($scope.tripId) {
                return $scope
                    .requestTripDetails($scope.tripId)
                    .then((data) => {
                        const promises = [
                            $scope.requestSurroundingTrips($scope.tripId),
                            $scope.requestRelatedTrips($scope.tripId),
                            $scope.requestTripChanges($scope.tripId),
                        ];
                        return Promise.all(promises)
                            .then(([surroundingTripsData, relatedTripsData, tripChangesData]) => {
                                $scope.refreshRequests = true;
                                $scope.surroundingTrips = surroundingTripsData;
                                $scope.relatedTrips = {
                                    count: relatedTripsData.relatedTripCount,
                                    inProgressCount: relatedTripsData.relatedTrips.filter((trip) => trip.status === 'inProgress').length,
                                    trips: relatedTripsData.relatedTrips
                                        .sort((a, b) => new Date(a.schedule) - new Date(b.schedule))
                                        .map((trip) => {
                                            trip.isToday = dateIsToday(trip.schedule);
                                            return trip;
                                        }),
                                };

                                $scope.tripChanges = tripChangesData;

                                $scope.hasPreviousTrip = $scope.tripChanges.previousTrip !== null;
                                // If $scope.tripChanges object contains previousTrip with other data, there were changes in this trip
                                $scope.hasTripChanged = $scope.tripChanges?.previousTrip != null && Object.keys($scope.tripChanges).length > 1;
                                // If the value of $scope.tripChanges is not false, route changes analysis is available
                                $scope.isAnalysisAvailable = $scope.tripChanges?.featureAvailable !== false;

                                tripDataFetched(data);
                                // Start refresh promise
                                $scope.refreshing = false;
                                $scope.cancelPromiseMap();
                                startPromiseMap();
                            })
                            .catch(() => {
                                tripDataFetched(data);
                            });
                    })
                    .catch((error) => {
                        $scope.catchErrorDefault(error);
                        const map = document.getElementById('tripMap');
                        map.style.display = 'none';
                        $scope.loadingStops = false;
                        $scope.pageLoading = false;
                        $scope.$apply();
                    });
            }
        }

        /**
         * Fetch trip details
         * This function retrieves detailed information about a trip based on the current trip ID
         * @return {Promise}
         */
        function fetchTripDetails() {
            if ($scope.tripId) {
                $scope
                    .requestTripDetails($scope.tripId)
                    .then((data) => {
                        tripDataFetched(data);
                    })
                    .catch((error) => {
                        $scope.catchErrorDefault(error);
                        $scope.pageLoading = false;
                        $scope.$apply();
                    });
            }
        }

        /**
         * Fetch related trips
         */
        function fetchRelatedTrips() {
            $scope
                .requestRelatedTrips($scope.tripId)
                .then((data) => {
                    $scope.relatedTrips = {
                        count: data.relatedTripCount,
                        inProgressCount: data.relatedTrips.filter((trip) => trip.status === 'inProgress').length,
                        trips: data.relatedTrips
                            .sort((a, b) => new Date(a.schedule) - new Date(b.schedule))
                            .map((trip) => {
                                trip.isToday = dateIsToday(trip.schedule);
                                return trip;
                            }),
                    };
                })
                .catch((error) => {
                    $scope.catchErrorDefault(error);
                    $scope.pageLoading = false;
                    $scope.$apply();
                });
        }

        /**
         * Function to get the route details
         * @param {String} routeId
         */
        function getRouteDetails(routeId) {
            $scope.requestRouteDetail(routeId).then(
                function (data) {
                    $scope.route = data;

                    // TODO:: Remove once backend is updated to send a string for preferences.notifications instead of a boolean ( Discuss with Ben around August 2021 to decide whether this could be deleted)
                    $scope.route = transformNotificationsBooleanToString($scope.route);
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        }

        /**
         * Function to cancel/restore a planned trip
         * @param {String} trip
         */
        $scope.updateCancellationAnnouncements = function (trip) {
            if ($rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager') {
                $scope.announcementUpdateLoading = true;
                const body = {
                    announcement: $scope.newCancellationAnnouncement.value,
                };
                if (body.announcement) {
                    $scope.postTripCancellationAnnouncements(trip.id, body).then(
                        function () {
                            refreshCallbackAnnouncementsUpdate();
                        },
                        function (error) {
                            $scope.announcementUpdateLoading = false;
                            $scope.showModalCancellationAnnouncements = false;
                            $rootScope.$broadcast('request-error-main', error);
                        }
                    );
                }
                $scope.newCancellationAnnouncement.value = '';
            }
        };

        /**
         * Get trip forms information and format the form type, sort by date
         * @param {String} tripId
         */
        function getTripForms(tripId) {
            $scope.angRequestGetDispatchForms(tripId).then(
                function (data) {
                    const formCount = data.data.formCount;
                    $scope.tripFormsCount = formCount;
                    if (formCount != 0) {
                        $scope.tripForms = data.data.forms;

                        $scope.tripForms.forEach((form) => {
                            switch (form.type) {
                                case 'disciplinary':
                                    form.formattedType = $translate.instant('discipline');
                                    break;
                                case 'photos':
                                default:
                                    form.formattedType = $translate.instant('photo');
                                    break;
                            }
                        });
                    }

                    $scope.sortForms('date');
                    $scope.formsLoading = false;

                    // isShowingForms for behavior of the arrow icon/behavior of Bootstrap collapsible content in the forms section (shows if there is at least one form)
                    $scope.isShowingForms = formCount > 0;
                    // If isShowingForms is true then shows Bootstrap collapsible content in forms section (default is hidden)
                    $scope.isShowingForms && $('#formsTab').collapse('show');
                },
                function (error) {
                    $rootScope.$broadcast('request-error-trip-forms', error);
                    $scope.formsLoading = false;
                }
            );
        }

        const compareDates = function (date1, date2) {
            return new Date(date1) > new Date(date2);
        };

        // Get last report from list, and alphabetically sort question ids.
        const getLastReport = function (reports) {
            let report = false;

            if (typeof reports !== 'undefined') {
                reports.forEach(function (currentReport) {
                    if (currentReport.reportType === 'endTrip') {
                        if (compareDates(currentReport.timestamp, report.timestamp) || !report) {
                            report = currentReport;
                        }
                    }
                });

                // show report if theres a note and user is carrier user
                report.report.forEach(function (item) {
                    if (item.question === 'notes' && ['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                        if (item.answer !== '') {
                            $scope.showReport = true;
                        }
                    }
                    if (item.question === 'deviceName') {
                        $scope.deviceName = item.answer;
                    }
                    if (item.question === 'appVersion') {
                        $scope.appVersion = item.answer;
                    }

                    if (item.question === 'mdmDeviceName') {
                        $scope.mdmDeviceName = item.answer;
                    }

                    if (item.question === 'mdmDeviceSerialNumber') {
                        $scope.mdmDeviceSerialNumber = item.answer;
                    }
                });
            }

            return report;
        };

        /**
         * Manage and organize data after being fetched
         * @param {Object} data the data fetched
         */
        async function tripDataFetched(data) {
            const mailHash = sha256($rootScope.loggedUserMail);
            let favourites = [];
            const favouritesRaw = window.localStorage.getItem('favouriteRoutes-' + mailHash);
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            $scope.tripInfo = data;
            $scope.isTripInProgressForToday = $scope.tripInfo.status === 'inProgress' && dateIsToday($scope.tripInfo.schedule);

            // Only show the right panel on page init, then leave it to the user to collapse or show them
            if ($scope.isInit) {
                // isShowingPassengers for behavior of the arrow icon/behavior of Bootstrap collapsible content in passengers section (initialized to studentsBoardingRegistrations value (true or false))
                $scope.isShowingPassengers = $scope.tripInfo.preferences.studentsBoardingRegistrations;
                // isShowingStops for behavior of the arrow icon/behavior of Bootstrap collapsible content in the stop section (initialized to the opposite of studentsBoardingRegistrations value (true or false)).
                $scope.isShowingStops = !$scope.tripInfo.preferences.studentsBoardingRegistrations;

                // If isShowingPassengers is true then shows Bootstrap collapsible content in passengers section (default is hidden)
                if ($scope.isShowingPassengers) {
                    $('#tripDetailPassengersPanel__content').collapse('show');
                    $('#tripDetailPassengersPanel__header').find('.custom-caret').addClass('fa-caret-down').removeClass('fa-caret-right');
                }

                // If isShowingStops is true then shows Bootstrap collapsible content in stop section (default is hidden)
                if ($scope.isShowingStops) {
                    $('#stopsList').collapse('show');
                    $('#stopsListHeader').find('.custom-caret').addClass('fa-caret-down').removeClass('fa-caret-right');
                }

                // $scope.$apply to force the update of the view after asynchronous call to fetchTripData
                $scope.$apply();
            }

            // TODO:: Remove once backend is updated to send a string for preferences.notifications instead of a boolean ( Discuss with Ben around August 2021 to decide whether this could be deleted)
            $scope.tripInfo = transformNotificationsBooleanToString($scope.tripInfo);

            if ($scope.tripInfo.preferences.studentsBoardingRegistrations) $scope.orderType.orderBy = 'timePickUp';

            $scope.tripInfo.route['isFavourite'] = favourites.indexOf($scope.tripInfo.route.id) >= 0;
            $scope.tripInfo.hasWaypoints = $scope.tripInfo.stops.some((stop) => stop.waypoints?.length > 0);
            $scope.tripDate = getTripDate($scope.tripInfo);
            $scope.tripInFuture = $scope.isFuture($scope.tripDate);
            $scope.isShowingBus = $scope.tripDate === $scope.today;
            $scope.realTimeSchedule = getRealTimeSchedule();
            $scope.deviceName = null;
            $scope.appVersion = null;
            $scope.mdmDeviceName = null;
            $scope.mdmDeviceSerialNumber = null;

            // Format arrival time, add delta to object and add stopDuration to object
            for (const stopI in $scope.tripInfo.stops) {
                if ($scope.tripInfo.stops[stopI].arrival != null) {
                    const stop = $scope.tripInfo.stops[stopI];
                    // eslint-disable-next-line no-undef
                    stop.timeDelta = $scope.timeDelta(moment(stop.arrival).format('HH:mm'), stop.schedule, true);
                    // eslint-disable-next-line no-undef
                    stop.stopDuration = Math.round(moment.duration(moment(stop.departure).diff(stop.arrival)).asMinutes());
                }
            }

            // Hiding loading state
            const tripLoader = document.getElementById('loading-trip-info');
            if (tripLoader) {
                tripLoader.style.display = 'none';
            }
            $scope.loadingStops = false;

            $scope.lastReport = getLastReport($scope.tripInfo.reports);

            const unknownStudents = data.unknownStudents;
            // Take only last onBoarding and offBoarding for each unknownStudent
            unknownStudents.forEach((student) => {
                student.lastStatus = { status: 'fileNumberSpotted' };
                student.firstName = '';
                student.lastName = '';
                for (const status of student.status) {
                    if (status.stopSequenceNumber % 1 !== 0 || data.stops[status.stopSequenceNumber - 1].kind === 'pickup') {
                        if (!student.onBoard || (student.onBoard && new Date(student.onBoard.timestamp) < new Date(status.timestamp))) {
                            student.onBoard = status;
                        }
                    } else if (!student.offBoard || (student.offBoard && new Date(student.offBoard.timestamp) < new Date(status.timestamp))) {
                        student.offBoard = status;
                    }
                }
            });

            const allStudents = data.students.concat(unknownStudents);
            $scope.passengers = allStudents;
            $scope.loadPassengersTable(allStudents);
            passengersLoaded();
            $scope.$apply();

            $scope.canEditSettingsCommunications = checkIfUserCanEditSettingsCommunications();
            $scope.isLocateDeviceActive = checkIfUserCanLocateDevice();
            $scope.canAnnounceDelay = checkIfUserCanAnnounceDelay();
            $scope.canCancelAnnouncements = checkIfUserCanCancelAnnouncements();
            $scope.canAnnounceOthers = checkIfUserCanAnnounceOthers();

            $scope.isInit = false;
        }

        /* =======================================================================================================================
            PREFERENCES/FAVORITE TOGGLES
        =======================================================================================================================  */

        // TODO:: Remove once backend is updated to send a string for preferences.notifications instead of a boolean ( Discuss with Ben around August 2021 to decide whether this could be deleted)
        /**
         * Temporary function for frontend compatibility for MTR-822 : Transforms boolean preferences notifications of a trip into a string
         * Will be removed when backend sends a string for preferences.notifications instead of a boolean
         * @param {Object} tripOrRoute
         * @return {Object} tripOrRoute
         */
        function transformNotificationsBooleanToString(tripOrRoute) {
            if (tripOrRoute.preferences.notifications === false) {
                tripOrRoute.preferences.notifications = 'none';
            } else if (tripOrRoute.preferences.notifications === true && tripOrRoute.preferences.studentsBoardingRegistrations === false) {
                tripOrRoute.preferences.notifications = 'vehicleOnly';
            } else if (tripOrRoute.preferences.notifications === true && tripOrRoute.preferences.studentsBoardingRegistrations === true) {
                tripOrRoute.preferences.notifications = 'vehicleAndStudents';
            }
            return tripOrRoute;
        }

        /**
         * Sets the student boarding registrations preferences of a trip or associated route on backend and updates front view for trip after response from backend
         * Also hides the students Boarding registrations modal after response from backend
         * @param {Object} routeOrTrip
         * @param {Boolean} studentsBoardingRegistrationsStatus
         * @param {Boolean} forAllFutureTrips To know if we will be working on a trip or on a route
         */
        $scope.toggleStudentsBoardingRegistrations = function (routeOrTrip, studentsBoardingRegistrationsStatus, forAllFutureTrips = false) {
            if ($scope.canEditSettingsCommunications) {
                const body = {
                    studentsBoardingRegistrations: studentsBoardingRegistrationsStatus,
                };

                $scope[forAllFutureTrips ? 'requestRouteNotificationsUpdate' : 'requestTripNotificationsUpdate'](routeOrTrip.id, body)
                    .then(
                        (data) => {
                            // TODO:: Remove once backend is updated to send a string for preferences.notifications instead of a boolean ( Discuss with Ben around August 2021 to decide whether this could be deleted)
                            data = transformNotificationsBooleanToString(data);

                            // Updating the TRIP(view) with studentsBoardingRegistrations preferences of the SERVER (whether it's TRIP or ROUTE(upcoming trip + future trips)).
                            $scope.tripInfo.preferences.studentsBoardingRegistrations = data.preferences.studentsBoardingRegistrations;

                            // Updating the TRIP(view) with notifications preferences of the TRIP(server)
                            if (!forAllFutureTrips) {
                                $scope.tripInfo.preferences.notifications = data.preferences.notifications;
                            } else {
                                /* Else updating the ROUTE($scope) with the studentsBoardingRegistrations preferences of the ROUTE(server)
                                (This is useful for knowing when the route(upcoming + future trips) button in the GUARDIANS NOTIFICATIONS MODAL should be disabled */
                                $scope.route.preferences.studentsBoardingRegistrations = data.preferences.studentsBoardingRegistrations;

                                // Updating the TRIP(view) notifications preferences to "vehicleOnly" if we are toggling OFF the studentsBoardingRegistrations preferences and notifications are at "vehicleAndStudents"
                                if (!studentsBoardingRegistrationsStatus && $scope.tripInfo.preferences.notifications === 'vehicleAndStudents') {
                                    $scope.tripInfo.preferences.notifications = 'vehicleOnly';
                                }
                            }
                        },
                        (error) => {
                            // back to original value before toggling
                            $scope.tripInfo.preferences.studentsBoardingRegistrations = !studentsBoardingRegistrationsStatus;

                            $rootScope.$broadcast('request-error-settings-and-comms', error);
                        }
                    )
                    .then(() => {
                        // To prevent digest loop errors : https://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply
                        $timeout(function () {
                            $scope.showModalAttendanceTracking = false;
                            $scope.$apply();
                        });
                    });
            }
        };
        /**
         * Sets the student notifications preferences of a trip or associated route
         * (Saves current notification status, updates front view for trip with desired new status, then shows notifications modal with proper text or requests update on backend and updates front for trip after response)
         * Also hides the  notifications modal after response from backend
         * @param {Object} routeOrTrip
         * @param {String} notificationsStatus
         * @param {Boolean} forAllFutureTrips To know if we will be working on a trip or on a route
         */
        $scope.toggleNotifications = function (routeOrTrip, notificationsStatus, forAllFutureTrips = false) {
            if ($scope.canEditSettingsCommunications) {
                if (!$scope.showModalNotificationsGuardians) {
                    $scope.currentNotificationsStatus = $scope.tripInfo.preferences.notifications;
                    $scope.tripInfo.preferences.notifications = notificationsStatus;

                    switch (notificationsStatus) {
                        case 'none':
                            $scope.notificationsModalText = `${$translate.instant('tripToggleConfirmationMessageOff')} "${$translate.instant(
                                'guardiansNotification'
                            )}" ?`;
                            break;
                        case 'vehicleOnly':
                            $scope.notificationsModalText = `${$translate.instant('tripToggleConfirmationMessageOn')} "${$translate.instant(
                                'vehicleOnlyNotifications'
                            )}" ?`;
                            break;
                        case 'vehicleAndStudents':
                            $scope.notificationsModalText = `${$translate.instant('tripToggleConfirmationMessageOn')} "${$translate.instant(
                                'vehicleAndStudentsNotifications'
                            )}" ?`;
                            break;

                        default:
                            break;
                    }

                    $scope.showModalNotificationsGuardians = true;
                } else {
                    const body = {
                        notifications: notificationsStatus,
                    };

                    $scope[forAllFutureTrips ? 'requestRouteNotificationsUpdate' : 'requestTripNotificationsUpdate'](routeOrTrip.id, body)
                        .then(
                            (data) => {
                                $scope.tripInfo.preferences.notifications = data.preferences.notifications;
                            },
                            (error) => {
                                // back to original value before toggling
                                $scope.tripInfo.preferences.notifications = $scope.currentNotificationsStatus;

                                $rootScope.$broadcast('request-error-settings-and-comms', error);
                            }
                        )
                        .then(() => {
                            // To prevent digest loop errors : https://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply
                            $timeout(function () {
                                $scope.showModalNotificationsGuardians = false;
                                $scope.$apply();
                            });
                        });
                }
            }
        };

        /**
         * Go back to current notifications status for current trip
         */
        $scope.goBackToCurrentNotificationsStatus = function () {
            $scope.tripInfo.preferences.notifications = $scope.currentNotificationsStatus;
        };

        /**
         * Sets the student automatic delay notifications preferences of trip or associated route and updates front view for trip  after response from backend
         * Also hides AutomaticDelayGuardians Modal after response from server
         * @param {Object} routeOrTrip
         * @param {Boolean} automaticDelayNotificationsStatus
         * @param {Boolean} forAllFutureTrips To know if we will be working on a trip or on a route
         */
        $scope.toggleAutomaticDelayNotifications = function (routeOrTrip, automaticDelayNotificationsStatus, forAllFutureTrips = false) {
            if ($scope.canEditSettingsCommunications) {
                const body = {
                    automaticDelayNotifications: automaticDelayNotificationsStatus,
                };

                $scope[forAllFutureTrips ? 'requestRouteNotificationsUpdate' : 'requestTripNotificationsUpdate'](routeOrTrip.id, body)
                    .then(
                        (data) => {
                            $scope.tripInfo.preferences.automaticDelayNotifications = data.preferences.automaticDelayNotifications;
                        },
                        (error) => {
                            // back to original value before toggling
                            $scope.tripInfo.preferences.automaticDelayNotifications = !automaticDelayNotificationsStatus;
                            $rootScope.$broadcast('request-error-settings-and-comms', error);
                        }
                    )
                    .then(() => {
                        // To prevent digest loop errors : https://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply
                        $timeout(function () {
                            $scope.showModalAutomaticDelayGuardians = false;
                            $scope.$apply();
                        });
                    });
            }
        };

        $scope.toggleFavourite = function (trip) {
            const mailHash = sha256($rootScope.loggedUserMail);
            const routeId = trip.route.id;
            // Get favourites for user.
            let favourites = [];
            const favouritesRaw = window.localStorage.getItem('favouriteRoutes-' + mailHash);

            // Validate if current user has favourites.
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            // Search for current trip's route in favourites and add/remove.
            const index = favourites.indexOf(routeId);
            if (index > -1) {
                favourites.splice(index, 1);
            } else {
                favourites.push(routeId);
            }

            // Save favourites.
            window.localStorage.setItem('favouriteRoutes-' + mailHash, JSON.stringify(favourites));

            // Update items.
            $scope.updateTripForFavourite(index < 0);
        };

        $scope.updateTripForFavourite = function (isFavourite) {
            $scope.tripInfo.route['isFavourite'] = isFavourite;
            $timeout(function () {
                $scope.$apply();
            });
        };

        /* =======================================================================================================================
            DELAY AND VARIOUS ANNOUNCEMENTS
        =======================================================================================================================  */

        /**
         * Sets scope variables for the delay announcement modal confirmation text and opens the modal
         * @param {String} newAnnouncedDelay the delay chosen by the user (none, 15min, 30min, 45min or 60min)
         */
        $scope.openDelayAnnouncementModal = (newAnnouncedDelay) => {
            if (newAnnouncedDelay) {
                // eslint-disable-next-line no-undef
                $scope.currentTimeHM = moment().format('HH:mm');
                switch (newAnnouncedDelay) {
                    case 'fewMinutes':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'announcedDelayConfirmationLateFewMinutes',
                            {
                                vehicle: $scope.tripInfo.route.bus.number,
                                time: $scope.currentTimeHM,
                            }
                        )}"`;
                        break;
                    case '15min':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'announcedDelayConfirmationLate',
                            {
                                delay: 15,
                                vehicle: $scope.tripInfo.route.bus.number,
                                time: $scope.currentTimeHM,
                            }
                        )}"`;
                        break;
                    case '30min':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'announcedDelayConfirmationLate',
                            {
                                delay: 30,
                                vehicle: $scope.tripInfo.route.bus.number,
                                time: $scope.currentTimeHM,
                            }
                        )}"`;
                        break;
                    case '45min':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'announcedDelayConfirmationLate',
                            {
                                delay: 45,
                                vehicle: $scope.tripInfo.route.bus.number,
                                time: $scope.currentTimeHM,
                            }
                        )}"`;
                        break;
                    case '60min':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'announcedDelayConfirmationLate',
                            {
                                delay: 60,
                                vehicle: $scope.tripInfo.route.bus.number,
                                time: $scope.currentTimeHM,
                            }
                        )}"`;
                        break;
                    case 'none':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'announcedDelayConfirmationNone',
                            {
                                vehicle: $scope.tripInfo.route.bus.number,
                                time: $scope.currentTimeHM,
                            }
                        )}"`;
                        break;
                    default:
                        $scope.announcements.announcementMessage = '';
                }
                $scope.announcedDelay = newAnnouncedDelay;
                $scope.announcements.delayAnnouncement = true;
                $scope.announcements.modalAnnouncement = true;
            }
        };

        /**
         * Sets scope variables for the cancellation announcement modal confirmation text and opens the modal
         * @param {String} newCancellationAnnouncement cancelled or restored
         */
        $scope.openCancellationAnnouncementsModal = (newCancellationAnnouncement) => {
            if (newCancellationAnnouncement) {
                const scheduleDate = moment($scope.tripInfo.schedule);
                const busNumber = $scope.tripInfo.route.bus.number;
                const scheduleTime = $scope.tripInfo.route.schedule.departure;
                const isToday = scheduleDate.isSame(moment(), 'day');

                let displayDate = '';
                if (isToday) {
                    displayDate = $translate.instant('today');
                } else {
                    // Format example : Friday, October 3 or Vendredi 3 octobre
                    displayDate =
                        $rootScope.language === 'fr'
                            ? $scope.capitalizeFirstLetter(scheduleDate.format('dddd D MMMM'))
                            : scheduleDate.format('dddd, MMMM D');
                }

                switch (newCancellationAnnouncement) {
                    case 'cancelled':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'cancellationModalContent',
                            {
                                busNumber: busNumber,
                                time: scheduleTime,
                                date: displayDate,
                            }
                        )}"`;
                        break;
                    case 'restored':
                        $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant(
                            'restorationModalContent',
                            {
                                busNumber: busNumber,
                                time: scheduleTime,
                                date: displayDate,
                            }
                        )}"`;
                        break;
                    default:
                        $scope.announcements.announcementMessage = '';
                }
                $scope.showModalCancellationAnnouncements = true;
            }
        };

        $scope.closeAnnouncementModal = () => {
            $scope.announcements.modalAnnouncement = false;
        };

        /**
         * Structure the data to send and call API
         * To send new delay notification for the trip
         * @param {String} trip trip info
         */
        $scope.updateAnnouncedDelayNotification = function (trip) {
            if ($rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager') {
                $scope.announcementUpdateLoading = true;
                const body = {
                    delayType: $scope.announcedDelay,
                };

                if (body.delayType) {
                    $scope.postTripAnnouncedDelay(trip.id, body).then(
                        function () {
                            refreshCallbackAnnouncementsUpdate();
                        },
                        function (error) {
                            $scope.announcementUpdateLoading = false;
                            $scope.announcements.modalAnnouncement = false;
                            $rootScope.$broadcast('request-error-main', error);
                        }
                    );
                }

                // Reset announced delay value in select component
                $scope.newAnnouncedDelay.value = '';
            }
        };

        $scope.openVariousAnnouncementModal = function (type) {
            switch (type) {
                case 'erroneousNotifications':
                    $scope.variousAnnouncementType = 'erroneousNotifications';
                    $scope.announcements.announcementMessage = `${$translate.instant('messageColon')} "${$translate.instant('errorsDetectedMessage', {
                        vehicle: $scope.tripInfo.route.bus.number,
                    })}"`;
                    break;
                default:
                    $scope.announcements.announcementMessage = '';
                    $scope.variousAnnouncementType = '';
                    break;
            }
            if (type) {
                $scope.announcements.delayAnnouncement = false;
                $scope.announcements.modalAnnouncement = true;
            }
        };

        /**
         * Structure the data to send and call API
         * To send new various announcement notification for the trip
         * @param {String} trip trip info
         */
        $scope.updateVariousAnnouncementsNotification = function (trip) {
            if ($rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager') {
                $scope.announcementUpdateLoading = true;
                const body = {
                    message: $scope.variousAnnouncementType,
                };

                if (body.message) {
                    $scope.postTripVariousAnnouncements(trip.id, body).then(
                        function () {
                            refreshCallbackAnnouncementsUpdate();
                        },
                        function (error) {
                            $scope.announcementUpdateLoading = false;
                            $scope.announcements.modalAnnouncement = false;
                            $rootScope.$broadcast('request-error-main', error);
                        }
                    );
                }
            }
        };

        // =======================================================================================================================

        /**
         * Get the real time when the bus started the trip and finished it. Only return values when the bus is at stop one and above.
         * @return {Object}
         */
        function getRealTimeSchedule() {
            const realTimeSchedule = {
                departure: '. . .',
                arrival: '. . .',
            };

            if ($scope.tripInfo.status === 'inProgress' && $scope.tripInfo.currentStopSequenceNumber >= 1) {
                // Display real time departure only when driver arrive at first stop.
                // eslint-disable-next-line no-undef
                realTimeSchedule.departure = moment($scope.tripInfo.stops[0].arrival).format('HH:mm');
            } else if ($scope.tripInfo.status === 'completed') {
                // Display real time only if driver has made minimum one stop
                realTimeSchedule.departure = $scope.tripInfo.stops[0].arrival
                    ? // eslint-disable-next-line no-undef
                      moment($scope.tripInfo.stops[0].arrival).format('HH:mm')
                    : // When driver mismanipulate and complete trip without arriving to first stop, real time departure is when he/she start a trip
                      // eslint-disable-next-line no-undef
                      moment($scope.tripInfo.departure).format('HH:mm');

                realTimeSchedule.arrival =
                    $scope.tripInfo.locations && $scope.tripInfo.locations.length > 0
                        ? // eslint-disable-next-line no-undef
                          moment($scope.tripInfo.locations[$scope.tripInfo.locations.length - 1].timestamp).format('HH:mm')
                        : // When driver mismanipulate and complete trip without arriving to first stop, real time arrival is when he/she complete trip
                          // eslint-disable-next-line no-undef
                          moment($scope.tripInfo.arrival).format('HH:mm');
            }

            return realTimeSchedule;
        }

        function getTripDate(tripInfo) {
            let dateValue = tripInfo.schedule;
            if (dateValue === null || dateValue === $scope.today || dateValue === '') {
                dateValue = $scope.today;
            }
            let formatedDate = moment(dateValue).format('YYYY-MM-DD');
            return formatedDate;
        }

        $scope.sortForms = function (orderBy) {
            $scope.formsLoading = true;

            if (orderBy) {
                if ($scope.orderTypeForms.orderBy === orderBy) {
                    $scope.orderTypeForms = {
                        orderBy: orderBy,
                        ascending: !$scope.orderTypeForms.ascending,
                    };
                } else {
                    $scope.orderTypeForms = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            $scope.tripForms = sortingTools.sortTripForms($scope.tripForms, $scope.orderTypeForms.orderBy, $scope.orderTypeForms.ascending);
            $scope.formsLoading = false;
        };

        /**
         * Expands the passengers panel
         */
        $scope.openPassengersPanel = function () {
            $scope.isShowingPassengers = true;
            $('#tripDetailPassengersPanel__content').collapse('show');
            $('#tripDetailPassengersPanel__header').find('.custom-caret').addClass('fa-caret-down').removeClass('fa-caret-right');
        };

        /**
         * Populates passengersToShow according to search & sorting
         * @param {array} passengers - The passengers of the trip
         */
        $scope.loadPassengersTable = function (passengers) {
            if (!$scope.registeringDisplayFunctions) {
                let filteredPassengers = $scope.applyFilters(passengers);

                filteredPassengers = sortingTools.sortStudents(filteredPassengers, $scope.orderType.orderBy, $scope.orderType.ascending);

                if (filteredPassengers === 0) {
                    $scope.loading = false;
                    $scope.sorting = false;
                    $scope.passengersToShow = [];
                    $scope.registeringDisplayFunctions = false;
                    return;
                }

                recursiveStudentsDisplay(filteredPassengers, 0, filteredPassengers.length);
                $scope.registeringDisplayFunctions = false;

                $scope.passengersToShow = filteredPassengers;
            }
        };

        $scope.sortPassengers = function (orderBy) {
            const passengerLoader = document.getElementById('loading-trip-passengers');
            if (passengerLoader) {
                passengerLoader.style.display = 'inline';
            }
            if (orderBy) {
                if ($scope.orderType.orderBy === orderBy) {
                    const ascending = !$scope.orderType.ascending;
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: ascending,
                    };
                } else {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
                $scope.loadPassengersTable($scope.passengers);
                if (passengerLoader) {
                    passengerLoader.style.display = 'none';
                }
            }
        };

        //
        // FILTERING
        //
        $scope.applyFilters = function (passengers) {
            // Cancel current timeouts for data display
            for (const promise in $scope.timeoutPromises) {
                if ($scope.timeoutPromises[promise]) {
                    $timeout.cancel($scope.timeoutPromises[promise]);
                }
            }

            // Reset timeOutPromises once they're cleared
            $scope.timeoutPromises = [];
            let filteredArray = [...passengers];

            if ($scope.searchValue.value !== '') {
                filteredArray = filterArrayFromSearchValues($scope.passengers);
            }

            return filteredArray;
        };

        const filterArrayFromSearchValues = function (array) {
            const pushStudents = [];
            array.forEach((student) => {
                if (isInFilter(student)) {
                    pushStudents.push(student);
                }
            });

            return pushStudents;
        };

        /**
         * Determines if a given student passes the specified filters
         *
         * @param {Object} student - The student object to be filtered.
         * @return {boolean} - Returns true if the student passes the filters, otherwise false.
         */
        function isInFilter(student) {
            const dataToCompare = {
                studentID: student.internalId,
                studentFirstName: student.firstName,
                studentLastName: student.lastName,
            };

            return $scope.containsSearchValue(dataToCompare, $scope.searchValue.value);
        }

        /**
         * Pushes students (in batches of 50) to displayed array with an incremental timeout
         * @param filteredArray (total array to be displayed)
         * @param i (current index, starting at 0)
         * @param limit (filteredArray length to avoid assigning it in each run)
         * @returns {*} nothing or self
         */
        const recursiveStudentsDisplay = function (filteredArray, i, limit) {
            const max = i + 50;
            const arrayToDisplay = filteredArray.slice(i, max);
            const timeout = i * 2;
            if (i < limit) {
                // Push to global timeouts array to be able to cancel it if data is filtered
                $scope.timeoutPromises.push(
                    $timeout(function () {
                        if (limit < max + 50) {
                            $scope.sorting = false;
                            $scope.loading = false;
                        }
                        if (i === 0) {
                            $scope.passengersToShow = arrayToDisplay;
                        } else {
                            $scope.passengersToShow = $scope.passengersToShow.concat(arrayToDisplay);
                        }
                    }, timeout)
                );
                return recursiveStudentsDisplay(filteredArray, max, limit);
            }
            return;
        };

        //
        // END OF FILTERING
        //

        function passengersLoaded() {
            let passengerLoader = document.getElementById('loading-trip-passengers');
            if (passengerLoader) {
                passengerLoader.style.display = 'none';
            }
            $scope.pageLoading = false;
            $scope.$apply();

            if ($scope.refreshing) {
                $scope.refreshGlobalMapForMultipleTrips([$scope.tripInfo]);
            } else {
                initializeTripMap();
            }
        }

        $scope.getFullDate = function (timestamp) {
            // eslint-disable-next-line no-undef
            return timestamp ? moment(timestamp).format('LL') + ' ' + moment(timestamp).format('HH:mm:ss') : '';
        };

        /**
         * Get the name of the stop that of the specified sequence number. If the sequence number if between two stop, it will return also the proper (between x and y)
         * @param {Number} number - the sequence number of the stop
         * @return {String|null}
         */
        $scope.getStopNameFromSequenceNumber = function (number) {
            if (!number) return null;
            let sequenceNumber = number;
            const betweenTwoStops = sequenceNumber % 1 > 0;
            const numberOfStops = $scope.tripInfo.stops.length;
            let stopName = null;
            for (let i = 0; i < numberOfStops; i++) {
                if (betweenTwoStops) {
                    if (!stopName) {
                        // Number of the last passed stop
                        sequenceNumber = sequenceNumber - (sequenceNumber % 1);
                    } else {
                        // Number of the next stop
                        sequenceNumber += 1;
                    }
                }
                if ($scope.tripInfo.stops[i].sequenceNumber === sequenceNumber) {
                    if (betweenTwoStops) {
                        if (!stopName) {
                            stopName = `${$translate.instant('between')} ${$scope.tripInfo.stops[i].name} ${$translate.instant('and')} `;
                        } else {
                            stopName += $scope.tripInfo.stops[i].name;
                            return stopName;
                        }
                    } else {
                        stopName = $scope.tripInfo.stops[i].name;
                        return stopName;
                    }
                }
            }
            return null;
        };

        /* =======================================================================================================================
            PAGE LAYOUT
        =======================================================================================================================  */
        $(window).bind('load resize', function () {
            adjustTripDetailsContainersHeight();
        });

        function adjustTripDetailsContainersHeight() {
            const map = document.getElementById('tripMap');
            const info = document.getElementById('trip-details-info-panel__content');

            // Resize map to Information box size.
            if (map && info) {
                const infoHeight = info.clientHeight;
                map.style.height = infoHeight + 'px';
            }
        }

        /* =======================================================================================================================
            MAP
        =======================================================================================================================  */
        function initializeTripMap() {
            let trips = [];
            trips.push($scope.tripInfo);
            $scope.initializeGlobalMapForMultipleTrips('tripMap', trips);
            adjustTripDetailsContainersHeight();
        }

        /**
         * Close the locate device modal
         */
        $scope.closeModal = () => {
            $scope.isShowingLocateModal = false;
        };

        /**
         * Locate device by creating a location request
         */
        $scope.requestLocateDevice = () => {
            // Resetting the banners
            $scope.isShowingLocateBannerFound = false;
            $scope.isShowingLocateBannerExpired = false;

            const query = { tripId: $scope.tripId };
            $scope.angRequestCreateLocationRequest(query).then(
                function (data) {
                    $scope.locationRequestId = data.data.id;
                    $scope.eraseDeviceLocations();
                    $scope.locationRefreshInterval = $interval(() => $scope.locateDevices(), 20000);
                    $scope.locateDevices();
                },
                function (error) {
                    if (error.status === 400 && error.data.error.code === 1001) {
                        error.key = $translate.instant('deviceLocationError');
                    }
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
            $scope.closeModal();
        };

        /**
         * Locate devices and displaying on map
         */
        $scope.locateDevices = () => {
            $scope.angRequestGetDeviceLocations($scope.locationRequestId).then(
                function (data) {
                    $scope.deviceLocations = data.data.devices;
                    const isActive = data.data.status === 'active';
                    if (isActive) {
                        if ($scope.deviceLocations.length > 0) {
                            $scope.displayDeviceLocations().then(() => {
                                $scope.isShowingLocateBannerNotFound = false;
                                $scope.isShowingLocateBannerFound = true;
                                $interval.cancel($scope.locationRefreshInterval);
                                $scope.$apply();
                            });
                        } else {
                            $scope.isShowingLocateBannerNotFound = true;
                        }
                    } else {
                        $scope.isShowingLocateBannerNotFound = false;
                        $scope.isShowingLocateBannerExpired = true;

                        $interval.cancel($scope.locationRefreshInterval);
                    }
                },
                function (error) {
                    $scope.isShowingLocateBannerNotFound = false;
                    $interval.cancel($scope.locationRefreshInterval);
                    error.key = $translate.instant('deviceCouldNotBeLocatedError');
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        /**
         * Cancel device location interval to make sure it doesn't continue after we change the page
         */
        $scope.$on('$destroy', () => {
            $interval.cancel($scope.locationRefreshInterval);
        });

        /* =======================================================================================================================
            PAGE REFRESH / RELOAD INDICATOR
	    =======================================================================================================================  */
        $scope.startRefreshPromise = function (updateRate, refreshAfter, refreshCallback, pauseRefreshValidationCallback) {
            $scope.currentProgress = 0;
            // Cancel existing intervals to make sure loader isnt being updated simulteanously
            $interval.cancel($rootScope.promiseTripInfo);
            return $interval(function () {
                $scope.progressTowardRefresh(updateRate, refreshAfter, refreshCallback, pauseRefreshValidationCallback);
            }, updateRate);
        };

        $scope.progressTowardRefresh = function (updateRate, refreshAfter, refreshCallback, pauseRefreshValidationCallback) {
            if (pauseRefreshValidationCallback()) {
                return;
            }

            $scope.currentProgress += updateRate;
            if ($scope.currentProgress >= refreshAfter) {
                $scope.currentProgress = 0;
                refreshCallback();
            }

            // Setting the progress percentage for the loader
            $scope.loaderProgress = 100 - ($scope.currentProgress * 100) / refreshAfter;
        };

        /* ========================================
            trip direction generation
        =========================================== */

        $scope.getTripDirectionPDF = function () {
            $scope.pdfLoading = true;
            $scope.generateTripDirectionPDF($scope.tripInfo.id, () => {
                $scope.pdfLoading = false;
                $scope.$apply();
            });
        };

        // Cancel intervals on map destroy
        $scope.$on('$destroy', function () {
            // Cancel existing intervals to make sure loader isnt being updated simulteanously
            $interval.cancel($scope.promiseTripInfo);
        });

        // If selecting a delay that exists in the previous annoucedDelays, block the user
        $scope.$watch('newAnnouncedDelay.value', function (seletedDelay) {
            if ($scope.tripInfo != null) {
                const lastDelayAnnoucement = $scope.tripInfo.announcedDelays
                    .map((announcement) => ({ ...announcement }))
                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0];
                if (lastDelayAnnoucement != null && lastDelayAnnoucement.delayType === seletedDelay) {
                    $scope.newAnnouncedDelay.enabled = false;
                } else {
                    $scope.newAnnouncedDelay.enabled = true;
                }
            }
        });

        $scope.$watch('newCancellationAnnouncement.value', function (selectValue) {
            if ($scope.tripInfo != null) {
                const lastCancellationAnnouncement = $scope.tripInfo.cancellationAnnouncements
                    .map((announcement) => ({ ...announcement }))
                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0];
                if (lastCancellationAnnouncement != null && lastCancellationAnnouncement.announcement === selectValue) {
                    $scope.newCancellationAnnouncement.enabled = false;
                } else {
                    $scope.newCancellationAnnouncement.enabled = true;
                }
            }
        });

        /**
         * Starts the auto play on play button clicked
         */
        $scope.onPlayClick = function () {
            if ($scope.replayProgress === 1000) {
                $scope.replayProgress = 0;
                $scope.updateReplayProgress($scope.replayProgress);
            }

            startAutoPlay();
        };

        /**
         * Stops the auto play on stop button clicked
         */
        $scope.onPauseClick = function () {
            stopAutoPlay();
        };

        /**
         * Start auto play
         */
        function startAutoPlay() {
            $scope.autoPlayRef = setInterval(function () {
                if ($scope.replayProgress < 1000) {
                    $scope.replayProgress += 1;
                    $scope.updateReplayProgress($scope.replayProgress);
                    $scope.$apply();
                }
            }, 100);
        }

        /**
         * Stop the auto play
         */
        function stopAutoPlay() {
            if ($scope.autoPlayRef) {
                clearInterval($scope.autoPlayRef);
                $scope.autoPlayRef = null;
            }
        }

        /**
         * Update the replay progress when the range input value change
         */
        $scope.onReplayTimeChange = function () {
            $scope.updateReplayProgress($scope.replayProgress);
        };

        /**
         * Return the replay time in hours, minutes, and optionally seconds
         * @param {Date} replayTime
         * @param {boolean} seconds - If true, includes seconds in the return value
         * @return {string} - The formatted replay time
         */
        $scope.formatReplayTime = function (replayTime, seconds = false) {
            if (!replayTime) {
                return '';
            }

            const pad = (num) => num.toString().padStart(2, '0');
            let formattedDate = `${pad(replayTime.getHours())}:${pad(replayTime.getMinutes())}`;

            if (seconds) {
                formattedDate += `:${pad(replayTime.getSeconds())}`;
            }

            return formattedDate;
        };

        const StopState = {
            ARRIVED: 'ARRIVED',
            SKIPPED: 'SKIPPED',
            SKIPPED_AUTOMATIC: 'SKIPPED_AUTOMATIC',
            POSTPONED: 'POSTPONED',
            POSTPONED_DONE: 'POSTPONED_DONE',
        };

        /**
         * Returns the state of the stop so relevant information can be displayed in stops panel
         * @param {Object} stop - The stop object
         * @return {String} The state of the stop.
         */
        $scope.getStopState = function (stop) {
            const { arrival, isSkipped, postponedAt, departure, isAutomaticArrival, isAutomaticDeparture } = stop;

            // If stop is postponed but not skipped
            if (postponedAt && !isSkipped) {
                if (!departure) {
                    // Stop postponed
                    return StopState.POSTPONED;
                }
                if (departure) {
                    // Stop was initially postponed
                    return StopState.POSTPONED_DONE;
                }
            }

            // If stop was not postponed
            if (arrival) {
                if (isSkipped) {
                    if (isAutomaticArrival && isAutomaticDeparture) {
                        // Stop is skipped automatically
                        return StopState.SKIPPED_AUTOMATIC;
                    } else {
                        // Stop is skipped manually
                        return StopState.SKIPPED;
                    }
                } else {
                    // Arrived at stop
                    return StopState.ARRIVED;
                }
            }
        };
    },
]);
