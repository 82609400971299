const { dateIsToday } = require('./helpers/dateComparator');

app.service('sortingTools', [
    '$translate',
    function ($translate) {
        /* ========================< Custom sorting functions for the app >======================= */
        /*                        Import sortingTools in your controlers ex:                       */
        /* app.controller('xController',['$scope''sortingTools', function ($scope, sortingTools) { */
        /*                                                                                         */
        /* }]);                                                                                    */
        /* ======================================================================================= */

        // Routes sorting ========================================================================================= //
        this.sortRoutes = function (routes, sortingType, ascending) {
            if (routes && sortingType) {
                if (routes.length <= 1) {
                    return routes;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'checkIn':
                        return sortRoutesByCheckIn(routes, ascending);
                    case 'favourite':
                        return sortRoutesByFavourite(routes, ascending);
                    case 'routeName':
                        return sortRoutesByName(routes, ascending);
                    case 'busNumber':
                        return sortRoutesByBusNumber(routes, ascending);
                    case 'carrier':
                        return sortRoutesByCarrier(routes, ascending);
                    case 'startTime':
                        return sortRoutesByStartTime(routes, ascending);
                    case 'endTime':
                        return sortRoutesByEndTime(routes, ascending);
                    case 'vehiculeType':
                        return sortRoutesByVehiculeType(routes, ascending);
                    case 'watcherCount':
                        return sortRoutesByWatcherCount(routes, ascending);
                    case 'notifications':
                        return sortRoutesByNotifications(routes, ascending);
                    case 'automaticDelayNotifications':
                        return sortRoutesByautomaticDelayNotifications(routes, ascending);
                    case 'drivers':
                        return sortRoutesByDriversEmails(routes, ascending);
                    case 'client':
                        return sortRoutesByClient(routes, ascending);
                    case 'studentCount':
                        return sortRoutesByStudentNumber(routes, ascending);
                    case 'directionCount':
                        return sortRoutesByDirections(routes, ascending);
                    case 'hasDirections':
                        return sortRoutesByHasDirections(routes, ascending);
                    case 'stopsWithoutDirections':
                        return sortRoutesByStopsWithoutDirectionsCount(routes, ascending);
                    case 'addOrEditDirections':
                        return sortRoutesByDirectionsEdition(routes, ascending);
                    case 'lastEdited':
                        return sortRoutesByLastEdited(routes, ascending);
                    case 'institutions':
                        return sortItemsByPropertyArray(routes, 'institutions', 'name', ascending);
                    default:
                        return sortRoutesByName(routes, ascending);
                }
            } else {
                return null;
            }
        };

        this.sortRoutesByBatchSettingsSelection = function (routes, ascending) {
            return sortBoolean(routes, 'isSelected', null, ascending);
        };

        this.sortBusNumbers = function (busNumbers) {
            return busNumbers.sort((a, b) => {
                const isNumericA = /^\d+$/.test(a.name);
                const isNumericB = /^\d+$/.test(b.name);

                // If both "a" and "b" are numeric, compare them as numbers
                if (isNumericA && isNumericB) {
                    return parseInt(a.name) - parseInt(b.name);
                }

                // If both a and b are not numeric, use localeCompare with numeric sorting
                if (!isNumericA && !isNumericB) {
                    return a.name.localeCompare(b.name, { numeric: true });
                }

                return isNumericA ? -1 : 1;
            });
        };

        this.sortTripOfTheWeek = function (trips, ascending) {
            if (trips && ascending) {
                if (trips.length <= 1) {
                    return trips;
                }
                return sortRouteTripOfTheWeekByDate(trips, ascending);
            } else {
                return null;
            }
        };

        function sortRouteTripOfTheWeekByDate(trips, ascending) {
            return quicksortDate(trips, 'schedule', null, ascending);
        }

        this.sortTripHistory = function (routeTripHistory, sortingType, ascending) {
            if (routeTripHistory && sortingType) {
                if (routeTripHistory.length <= 1) {
                    return routeTripHistory;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'date':
                        return sortRouteHistoryByDate(routeTripHistory, ascending);
                    case 'status':
                        return sortRouteHistoryByStatus(routeTripHistory, ascending);
                    case 'notes':
                        return sortRouteHistoryByNotes(routeTripHistory, ascending);
                    case 'firstStopActualTime':
                        return sortRouteHistoryByFirstStopActualTime(routeTripHistory, ascending);
                    case 'lastStopActualTime':
                        return sortRouteHistoryByLastStopActualTime(routeTripHistory, ascending);
                    default:
                        return sortRouteHistoryByDate(routeTripHistory, ascending);
                }
            } else {
                return null;
            }
        };

        this.sortTrips = function (trips, sortingType, ascending) {
            if (trips && sortingType) {
                if (trips.length <= 1) {
                    return trips;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'name':
                        return quicksort(trips, 'route', 'name', ascending);
                    case 'busNumber':
                        return quicksort(trips, 'busNumber', null, ascending);
                    case 'startTime':
                        return quicksort(trips, 'schedule', null, ascending);
                    case 'status':
                        return quicksort(trips, 'status', null, ascending);
                    case 'lastNotificationSent':
                        return quicksortDate(trips, 'lastAnnouncement', 'timestamp', ascending);
                    default:
                        return quicksort(trips, sortingType, null, ascending);
                }
            } else {
                return null;
            }
        };

        this.sortTripForms = function (tripForms, sortingType, ascending) {
            if (tripForms && sortingType) {
                if (tripForms.length <= 1) {
                    return tripForms;
                }
                switch (sortingType) {
                    case 'date':
                        return quicksortDate(tripForms, 'createdAt', null, ascending);
                    case 'type':
                        return quicksort(tripForms, 'formattedType', null, ascending);
                    case 'createdBy':
                        return quicksort(tripForms, 'sender', 'email', ascending);
                    default:
                        return quicksort(tripForms, orderBy, null, ascending);
                }
            } else {
                return null;
            }
        };

        this.sortCommunications = function (communications, orderBy) {
            // No break in switch case: Unreachable code
            switch (orderBy.attr) {
                case 'lastUpdatedBy':
                    return quicksort(communications, orderBy.attr, 'displayName', orderBy.asc);
                case 'createdAt':
                    return quicksortDate(communications, orderBy.attr, null, orderBy.asc);
                default:
                    return quicksort(communications, orderBy.attr, null, orderBy.asc);
            }
        };

        // Dispatch messages and forms sorting ========================================================================================= //
        this.sortDispatchMessages = function (messages, orderBy, ascending) {
            switch (orderBy) {
                case 'subject':
                    return quicksort(messages, 'subject', null, ascending);
                case 'sender':
                    return quicksort(messages, 'sender', 'email', ascending);
                case 'createdAt':
                    return quicksortDate(messages, 'createdAt', null, ascending);
                case 'lastReply':
                    return quicksortDate(messages, 'lastReply', null, ascending);
                case 'replyCount':
                    return quicksortNumbers(messages, 'replyCount', null, ascending);
                case 'fileNo':
                    return quicksort(messages, 'student', 'internalId', ascending);
                case 'student':
                    return quicksort(messages, 'student', 'firstName', ascending);
                case 'institutions':
                    return quicksort(messages, 'institutions', null, ascending);
                case 'busNo':
                    return quicksort(messages, 'busNumber', null, ascending);
                case 'validatedAt':
                    return quicksort(messages, 'validatedAt', null, ascending);
                case 'followUp':
                    return quicksort(messages, 'followUp', null, ascending);
                default:
                    return quicksort(messages, orderBy, null, ascending);
            }
        };

        // Dispatch drivers sorting ========================================================================================= //
        this.sortDispatchDrivers = function (drivers, orderBy, ascending) {
            switch (orderBy) {
                case 'email':
                    return quicksort(drivers, 'email', null, ascending);
                case 'selection':
                    return sortBoolean(drivers, 'isSelected', null, ascending);
                default:
                    return quicksort(drivers, orderBy, null, ascending);
            }
        };

        // Dispatch recipients sorting ========================================================================================= //
        this.sortDispatchRecipients = function (recipients, orderBy, ascending) {
            switch (orderBy) {
                case 'email':
                    return quicksort(recipients, 'user', 'email', ascending);
                case 'firstName':
                    return quicksort(recipients, 'user', 'firstName', ascending);
                case 'lastName':
                    return quicksort(recipients, 'user', 'lastName', ascending);
                case 'deliveredAt':
                    return quicksortDate(recipients, 'deliveredAt', null, ascending);
                case 'readAt':
                    return quicksortDate(recipients, 'readAt', null, ascending);
                case 'reply':
                    return quicksort(recipients, 'reply', null, ascending);
                case 'repliedAt':
                    return quicksortDate(recipients, 'repliedAt', null, ascending);
                default:
                    return quicksort(recipients, orderBy, null, ascending);
            }
        };

        // Dispatch disciplinary reports sorting ========================================================================================= //
        this.sortDisciplinaryReports = function (disciplinaryReports, orderBy, ascending) {
            if (disciplinaryReports && orderBy) {
                if (disciplinaryReports.length <= 1) {
                    return disciplinaryReports;
                }
                // No break in switch case: Unreachable code
                switch (orderBy) {
                    case 'internalID':
                        return quicksort(disciplinaryReports, 'student', 'internalId', ascending);
                    case 'school':
                        return quicksort(disciplinaryReports, 'studentInstitutions', null, ascending);
                    case 'busNumber':
                        return quicksort(disciplinaryReports, 'busNumber', null, ascending);
                    case 'carrier':
                        return quicksort(disciplinaryReports, 'carrierName', null, ascending);
                    case 'name':
                        return quicksort(disciplinaryReports, 'studentName', null, ascending);
                    case 'followUp':
                        return quicksort(disciplinaryReports, 'followUp', null, ascending);
                    case 'validation':
                        return quicksortDate(disciplinaryReports, 'validatedAt', null, ascending);
                    case 'received':
                        return quicksortDate(disciplinaryReports, 'createdAt', null, ascending);
                    case 'date':
                    default:
                        return quicksortDate(disciplinaryReports, 'createdAt', null, ascending);
                }
            } else {
                return null;
            }
        };

        // Dashboard future trips sorting ========================================================================================= //
        this.sortScheduledTripsWithSchedule = function (scheduledTrips, orderBy, ascending) {
            if (!scheduledTrips || !orderBy) {
                return null;
            }

            if (scheduledTrips.length <= 1) {
                return scheduledTrips;
            }

            switch (orderBy) {
                case 'routeName':
                    return quicksort(scheduledTrips, 'route', 'name', ascending);
                case 'busNumber':
                    return quicksort(scheduledTrips, 'busNumber', null, ascending);
                case 'carrier':
                    return quicksort(scheduledTrips, 'carrierName', null, ascending);
                case 'client':
                    return quicksort(scheduledTrips, 'clientName', null, ascending);
                case 'startTime':
                default:
                    return quicksortDate(scheduledTrips, 'schedule', null, ascending);
            }
        };

        this.sortScheduledTripsWithoutSchedule = function (scheduledTrips, orderBy, ascending) {
            if (!scheduledTrips || !orderBy) {
                return null;
            }

            if (scheduledTrips.length <= 1) {
                return scheduledTrips;
            }

            switch (orderBy) {
                case 'routeName':
                    return quicksort(scheduledTrips, 'route', 'name', ascending);
                case 'busNumber':
                    return quicksort(scheduledTrips, 'busNumber', null, ascending);
                case 'carrier':
                    return quicksort(scheduledTrips, 'carrierName', null, ascending);
                case 'client':
                    return quicksort(scheduledTrips, 'clientName', null, ascending);
                case 'startTime':
                default:
                    return quicksort(scheduledTrips, 'schedule', null, ascending);
            }
        };

        /**
         * Sorting routes object based on drivers array first email
         * @param {Object} routes object contain the info list of all routes
         * @param {Boolean} ascending Boolean to check the sorting type
         * @return {Object} it return a sorted object depends on the ascending option
         */
        function sortRoutesByDriversEmails(routes, ascending) {
            const sortedDrivers = routes.sort((a, b) => {
                return a.drivers[0]?.email.localeCompare(b.drivers[0]?.email);
            });

            return ascending ? sortedDrivers : sortedDrivers.reverse();
        }

        function sortRouteHistoryByDate(routeHistory, ascending) {
            return quicksortDate(routeHistory, 'schedule', null, ascending);
        }

        /**
         * Sorts the given route history
         *
         * @param {Array} routeHistory - The route history to sort
         * @param {boolean} ascending - If true, sorts the route history in ascending order. If false, sorts in descending order.
         * @return {Array} - The sorted route history.
         */
        function sortRouteHistoryByStatus(routeHistory, ascending) {
            const statusOrder = ['cancelled', 'notUsed', 'incomplete', 'inProgress', 'completed', 'planned'];

            routeHistory.sort((a, b) => {
                let aStatus = a.status;
                let bStatus = b.status;

                // Check if the trip is 'incomplete' aka 'inProgress' but past day
                if (a.status != null && a.status === 'inProgress' && !dateIsToday(a.schedule)) {
                    aStatus = 'incomplete';
                }
                if (b.status != null && b.status === 'inProgress' && !dateIsToday(b.schedule)) {
                    bStatus = 'incomplete';
                }

                const aStatusIndex = statusOrder.indexOf(aStatus);
                const bStatusIndex = statusOrder.indexOf(bStatus);

                if (ascending) {
                    return aStatusIndex - bStatusIndex;
                } else {
                    return bStatusIndex - aStatusIndex;
                }
            });

            return routeHistory;
        }

        function sortRouteHistoryByFirstStopActualTime(routeHistory, ascending) {
            return quicksortDate(routeHistory, 'firstStop', 'arrival', ascending, true);
        }

        function sortRouteHistoryByLastStopActualTime(routeHistory, ascending) {
            return quicksortDate(routeHistory, 'lastStop', 'arrival', ascending, true);
        }

        function sortRouteHistoryByNotes(routeHistory, ascending) {
            return quicksort(routeHistory, 'notes', null, ascending);
        }

        function sortRoutesByFavourite(routes, ascending) {
            return sortBoolean(routes, 'isFavourite', null, ascending);
        }

        function sortRoutesByCheckIn(routes, ascending) {
            return sortBoolean(routes, 'preferences', 'studentsBoardingRegistrations', ascending);
        }

        function sortRoutesByName(routes, ascending) {
            return quicksort(routes, 'name', null, ascending);
        }

        function sortRoutesByBusNumber(routes, ascending) {
            routes = sortRoutesByName(routes, ascending);
            return quicksort(routes, 'bus', 'number', ascending);
        }

        function sortRoutesByCarrier(routes, ascending) {
            const sortedCarrier = routes.sort((a, b) => {
                // Routes that have no carrier name are placed at the end
                if (!a.carrier?.name && b.carrier?.name) {
                    return 1;
                } else if (a.carrier?.name && !b.carrier?.name) {
                    return -1;
                }
                return (a.carrier?.name || '').localeCompare(b.carrier?.name || '');
            });

            return ascending ? sortedCarrier : sortedCarrier.reverse();
        }

        function sortRoutesByStartTime(routes, ascending) {
            return quicksortHoursAndMinutesFromString(routes, 'schedule', 'departure', ascending);
        }

        function sortRoutesByEndTime(routes, ascending) {
            return quicksortHoursAndMinutesFromString(routes, 'schedule', 'arrival', ascending);
        }

        function sortRoutesByVehiculeType(routes, ascending) {
            return quicksort(routes, 'bus', 'model', ascending);
        }

        function sortRoutesByWatcherCount(routes, ascending) {
            return quicksortNumbers(routes, 'watcherCount', null, ascending);
        }

        function sortRoutesByNotifications(routes, ascending) {
            return quicksort(routes, 'preferences', 'notifications', ascending);
        }
        function sortRoutesByautomaticDelayNotifications(routes, ascending) {
            return sortBoolean(routes, 'preferences', 'automaticDelayNotifications', ascending);
        }

        function sortRoutesByClient(routes, ascending) {
            return quicksort(routes, 'client', 'name', ascending);
        }
        function sortRoutesByStudentNumber(routes, ascending) {
            return quicksortNumbers(routes, 'studentCount', null, ascending);
        }
        function sortRoutesByDirections(routes, ascending) {
            return quicksortNumbers(routes, 'directionCount', null, ascending);
        }

        function sortRoutesByHasDirections(routes, ascending) {
            return sortBoolean(routes, 'hasDirections', null, ascending);
        }

        function sortRoutesByStopsWithoutDirectionsCount(routes, ascending) {
            return quicksortNumbers(routes, 'stopWithoutDirectionsCount', null, ascending);
        }

        function sortRoutesByDirectionsEdition(routes, ascending) {
            return quicksort(routes, 'directionsEditionStatus', null, ascending);
        }

        function sortRoutesByLastEdited(routes, ascending) {
            return quicksortDate(routes, 'directionsUpdatedAt', null, ascending);
        }

        // Reports sorting ========================================================================================= //
        this.sortReportData = function (reportData, property, sortingType, ascending) {
            if (reportData && property && sortingType) {
                if (reportData.length <= 1 || sortingType === 'unsortable') {
                    return reportData;
                }
                //No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'string':
                        return sortReportByStringProperty(reportData, property, ascending);
                    case 'link':
                        return sortReportByLinkProperty(reportData, property, ascending);
                    case 'number':
                        return sortReportByNumberProperty(reportData, property, ascending);
                    case 'percentage':
                        return sortReportByPercentageProperty(reportData, property, ascending);
                    case 'numberWithPDF':
                        return sortReportByNumberSubProperty(reportData, property, ascending);
                    case 'boolean':
                        return sortReportByBooleanProperty(reportData, property, ascending);
                    case 'array':
                        return sortReportByArrayProperty(reportData, property, ascending);
                    default:
                        return reportData;
                }
            } else {
                return null;
            }
        };

        function sortReportByStringProperty(reportData, property, ascending) {
            return quicksort(reportData, property, null, ascending);
        }

        function sortReportByLinkProperty(reportData, property, ascending) {
            return quicksort(reportData, property, 'label', ascending);
        }

        function sortReportByNumberProperty(reportData, property, ascending) {
            return quicksortNumbers(reportData, property, null, ascending);
        }

        function sortReportByPercentageProperty(reportData, property, ascending) {
            // Create a new array with a number property to sort by
            const dataToSort = reportData.map((data) => {
                return {
                    ...data,
                    sortByProperty: Number(data[property].replace('%', '')),
                };
            });
            const sortedData = quicksortNumbers(dataToSort, 'sortByProperty', null, ascending);

            // Delete the temporary property
            sortedData.forEach((data) => {
                delete data.sortByProperty;
            });

            return sortedData;
        }

        function sortReportByNumberSubProperty(reportData, property, ascending) {
            return quicksortNumbers(reportData, property, 'name', ascending);
        }

        function sortReportByBooleanProperty(reportData, property, ascending) {
            return sortBoolean(reportData, property, 'value', ascending);
        }

        function sortReportByArrayProperty(reportData, property, ascending) {
            return sortReportWithArray(reportData, property, 'data', ascending);
        }

        // Unassigned and alphabetical routes sorting ========================================================================================= //
        this.sortUnassignedRoutes = function (routes, property, ascending) {
            let assignedRoutes = [],
                unassignedRoutes = [];
            for (const route of routes) {
                if (route.driverCount && route.driverCount > 0) {
                    assignedRoutes.push(route);
                } else {
                    unassignedRoutes.push(route);
                }
            }

            unassignedRoutes = quicksort(unassignedRoutes, property, null, ascending);
            assignedRoutes = quicksort(assignedRoutes, property, null, ascending);

            return unassignedRoutes.concat(assignedRoutes);
        };

        // Unassigned and alphabetical drivers sorting ========================================================================================= //
        this.sortUnassignedDrivers = function (drivers, property, ascending) {
            let assignedDrivers = [],
                unassignedDrivers = [];
            for (const driver of drivers) {
                if (driver.routeCount && driver.routeCount > 0) {
                    assignedDrivers.push(driver);
                } else {
                    unassignedDrivers.push(driver);
                }
            }

            unassignedDrivers = quicksort(unassignedDrivers, property, null, ascending);
            assignedDrivers = quicksort(assignedDrivers, property, null, ascending);

            return unassignedDrivers.concat(assignedDrivers);
        };

        // Carriers sorting ========================================================================================= //
        this.sortCarriers = function (carriers, orderBy, ascending) {
            if (carriers && orderBy) {
                if (carriers.length <= 1) {
                    return carriers;
                }
                // No break in switch case: Unreachable code
                switch (orderBy) {
                    case 'name':
                        return quicksort(carriers, 'name', null, ascending);
                    case 'driverCount':
                        return quicksortNumbers(carriers, 'driverCount', null, ascending);
                    case 'routeCount':
                        return quicksortNumbers(carriers, 'routeCount', null, ascending);
                    case 'busCount':
                        return quicksortNumbers(carriers, 'busCount', null, ascending);
                    case 'vehicleCount':
                        return quicksortNumbers(carriers, 'vehicleCount', null, ascending);
                    default:
                        return carriers;
                }
            } else {
                return null;
            }
        };

        /**
         * Custom implementation of quicksort that allows the sorting of an array of values or an array of objects having a property
         * returning an object containing a subproperty containing another array, the second array must contain strings or numerical
         * values directly, not complex objects. Both accents and numbers are considered when the value is a string.
         * The comparison between value is made by a locale compare (alphanumeric comparison, character by character)
         * and only apply to the first element of the sub-array.
         * @param {array} array The array to sort.
         * @param {string} propertyName The property containing an object containing an array and the "IsArray" property.
         * @param {string} subPropertyName The name of the sub-array in the object.
         * @param {boolean} ascending Specifies if you want to sort ascending or descending. Ascending if null.
         * @return {array} The sorted array
         */
        function sortReportWithArray(array, propertyName, subPropertyName, ascending) {
            if (array.length <= 1 || !propertyName || !subPropertyName) {
                return array;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = array.pop();
                const length = array.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let name1 = '';
                        let name2 = '';
                        if (array[i][propertyName][subPropertyName] && array[i][propertyName][subPropertyName].length > 0) {
                            name1 = array[i][propertyName][subPropertyName][0];
                        }
                        if (pivot[propertyName][subPropertyName] && pivot[propertyName][subPropertyName].length > 0) {
                            name2 = pivot[propertyName][subPropertyName][0];
                        }
                        let answer = name1.localeCompare(name2);
                        if (answer <= 0) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let name1 = '';
                        let name2 = '';
                        if (array[i][propertyName][subPropertyName] && array[i][propertyName][subPropertyName].length > 0) {
                            name1 = array[i][propertyName][subPropertyName][0];
                        }
                        if (pivot[propertyName][subPropertyName] && pivot[propertyName][subPropertyName].length > 0) {
                            name2 = pivot[propertyName][subPropertyName][0];
                        }
                        let answer = name1.localeCompare(name2);
                        if (answer >= 0) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                }
                return newArray.concat(
                    sortReportWithArray(left, propertyName, subPropertyName, ascending),
                    pivot,
                    sortReportWithArray(right, propertyName, subPropertyName, ascending)
                );
            }
        }

        // Students sorting ======================================================================================= //
        this.sortStudents = function (students, sortingType, ascending) {
            if (students && sortingType) {
                if (students.length <= 1) {
                    return students;
                }
                //No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'internalID':
                        return sortStudentsByInternalID(students, ascending);
                    case 'lastName':
                        return sortStudentsByLastName(students, ascending);
                    case 'firstName':
                        return sortStudentsByFirstName(students, ascending);
                    case 'status':
                        return sortStudentsByStatus(students, ascending);
                    case 'stopPickUp':
                        return sortStudentsByStopPickUp(students, ascending);
                    case 'timePickUp':
                        return sortStudentsByTimePickUp(students, ascending);
                    case 'pickupInputType':
                        return sortStudentsByInputTypePickUp(students, ascending);
                    case 'stopDropOff':
                        return sortStudentsByStopDropOff(students, ascending);
                    case 'timeDropOff':
                        return sortStudentsByTimeDropOff(students, ascending);
                    case 'dropOffInputType':
                        return sortStudentsByInputTypeDropOff(students, ascending);
                    case 'school':
                        return sortStudentsBySchoolName(students, ascending);
                    case 'notes':
                        return sortStudentsByNotes(students, ascending);
                    default:
                        return sortStudentsByInternalID(students, ascending);
                }
            } else {
                return null;
            }
        };

        function sortStudentsByInternalID(students, ascending) {
            return quicksort(students, 'internalId', null, ascending);
        }

        function sortStudentsByLastName(students, ascending) {
            return quicksort(students, 'lastName', null, ascending);
        }

        function sortStudentsByNotes(students, ascending) {
            return quicksort(students, 'notes', null, ascending);
        }

        function sortStudentsByFirstName(students, ascending) {
            return quicksort(students, 'firstName', null, ascending);
        }

        function sortStudentsByStatus(students, ascending) {
            if (students.length <= 1) {
                return students;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = students.pop();
                const length = students.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let status1 = $translate.instant('expectedM');
                        if (students[i].lastStatus) {
                            status1 = students[i].lastStatus.status;
                        }

                        let status2 = $translate.instant('expectedM');
                        if (pivot.lastStatus) {
                            status2 = pivot.lastStatus.status;
                        }

                        let answer = Intl.Collator().compare(status1, status2);
                        if (answer <= 0) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let status1 = $translate.instant('expectedM');
                        if (students[i].lastStatus) {
                            status1 = students[i].lastStatus.status;
                        }

                        let status2 = $translate.instant('expectedM');
                        if (pivot.lastStatus) {
                            status2 = pivot.lastStatus.status;
                        }

                        let answer = Intl.Collator().compare(status1, status2);
                        if (answer >= 0) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                }
                return newArray.concat(sortStudentsByStatus(left, ascending), pivot, sortStudentsByStatus(right, ascending));
            }
        }

        function sortStudentsByStopPickUp(students, ascending) {
            if (students.length <= 1) {
                return students;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = students.pop();
                const length = students.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let stopSequenceNumber1 = 30000;
                        if (students[i].onBoard) {
                            stopSequenceNumber1 = students[i].onBoard.stopSequenceNumber;
                        }

                        let stopSequenceNumber2 = 30000;
                        if (pivot.onBoard) {
                            stopSequenceNumber2 = pivot.onBoard.stopSequenceNumber;
                        }

                        if (stopSequenceNumber1 <= stopSequenceNumber2) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let stopSequenceNumber1 = 30000;
                        if (students[i].onBoard) {
                            stopSequenceNumber1 = students[i].onBoard.stopSequenceNumber;
                        }

                        let stopSequenceNumber2 = 30000;
                        if (pivot.onBoard) {
                            stopSequenceNumber2 = pivot.onBoard.stopSequenceNumber;
                        }

                        if (stopSequenceNumber1 >= stopSequenceNumber2) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                }
                return newArray.concat(sortStudentsByStopPickUp(left, ascending), pivot, sortStudentsByStopPickUp(right, ascending));
            }
        }

        function sortStudentsByTimePickUp(students, ascending) {
            return quicksortDate(students, 'onBoard', 'timestamp', ascending);
        }

        function sortStudentsByInputTypePickUp(students, ascending) {
            if (students.length <= 1) {
                return students;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = students.pop();
                const length = students.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let inputType1 = 'null';
                        if (students[i].onBoard?.inputMode) {
                            inputType1 = students[i].onBoard.inputMode;
                        }

                        let inputType2 = 'null';
                        if (pivot.onBoard?.inputMode) {
                            inputType2 = pivot.onBoard.inputMode;
                        }

                        if (compareInputType(inputType1, inputType2) <= 0) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let inputType1 = 'null';
                        if (students[i].onBoard?.inputMode) {
                            inputType1 = students[i].onBoard.inputMode;
                        }

                        let inputType2 = 'null';
                        if (pivot.onBoard?.inputMode) {
                            inputType2 = pivot.onBoard.inputMode;
                        }

                        if (compareInputType(inputType1, inputType2) >= 0) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                }
                return newArray.concat(sortStudentsByInputTypePickUp(left, ascending), pivot, sortStudentsByInputTypePickUp(right, ascending));
            }
        }

        function sortStudentsByStopDropOff(students, ascending) {
            if (students.length <= 1) {
                return students;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = students.pop();
                const length = students.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let stopSequenceNumber1 = 30000;
                        if (students[i].offBoard) {
                            stopSequenceNumber1 = students[i].offBoard.stopSequenceNumber;
                        }

                        let stopSequenceNumber2 = 30000;
                        if (pivot.offBoard) {
                            stopSequenceNumber2 = pivot.offBoard.stopSequenceNumber;
                        }

                        if (stopSequenceNumber1 <= stopSequenceNumber2) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let stopSequenceNumber1 = 30000;
                        if (students[i].offBoard) {
                            stopSequenceNumber1 = students[i].offBoard.stopSequenceNumber;
                        }

                        let stopSequenceNumber2 = 30000;
                        if (pivot.offBoard) {
                            stopSequenceNumber2 = pivot.offBoard.stopSequenceNumber;
                        }

                        if (stopSequenceNumber1 >= stopSequenceNumber2) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                }
                return newArray.concat(sortStudentsByStopDropOff(left, ascending), pivot, sortStudentsByStopDropOff(right, ascending));
            }
        }

        function sortStudentsByTimeDropOff(students, ascending) {
            return quicksortDate(students, 'offBoard', 'timestamp', ascending);
        }

        function sortStudentsByInputTypeDropOff(students, ascending) {
            if (students.length <= 1) {
                return students;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = students.pop();
                const length = students.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let inputType1 = 'null';
                        if (students[i].offBoard?.inputMode) {
                            inputType1 = students[i].offBoard.inputMode;
                        }

                        let inputType2 = 'null';
                        if (pivot.offBoard?.inputMode) {
                            inputType2 = pivot.offBoard.inputMode;
                        }

                        if (compareInputType(inputType1, inputType2) <= 0) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let inputType1 = 'null';
                        if (students[i].offBoard?.inputMode) {
                            inputType1 = students[i].offBoard.inputMode;
                        }

                        let inputType2 = 'null';
                        if (pivot.offBoard?.inputMode) {
                            inputType2 = pivot.offBoard.inputMode;
                        }

                        if (compareInputType(inputType1, inputType2) >= 0) {
                            left.push(students[i]);
                        } else {
                            right.push(students[i]);
                        }
                    }
                }
                return newArray.concat(sortStudentsByInputTypeDropOff(left, ascending), pivot, sortStudentsByInputTypeDropOff(right, ascending));
            }
        }

        function sortStudentsBySchoolName(students, ascending) {
            if (students.length <= 1) {
                return students;
            } else {
                return quicksort(students, 'institutionName', null, ascending);
            }
        }

        // Student notes sorting ========================================================================================= //
        this.sortStudentNotes = function (studentNotes, sortingType, ascending) {
            if (studentNotes && sortingType) {
                if (studentNotes.length <= 1) {
                    return studentNotes;
                } else {
                    switch (sortingType) {
                        case 'createdAt':
                        default:
                            return quicksort(studentNotes, 'createdAt', null, ascending);
                    }
                }
            }
        };

        // Student history sorting ========================================================================================= //
        this.sortStudentHistory = function (studentHistory, sortingType, ascending) {
            if (studentHistory && sortingType) {
                if (studentHistory.length <= 1) {
                    return studentHistory;
                } else {
                    switch (sortingType) {
                        case 'timestamp':
                        default:
                            return quicksort(studentHistory, 'timestamp', null, ascending);
                    }
                }
            }
        };

        // Institutions sorting ========================================================================================= //
        this.sortInstitutions = function (institutions, sortingType = 'name', ascending = true) {
            if (institutions && sortingType) {
                if (institutions.length <= 1) {
                    return institutions;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'name':
                        return quicksort(institutions, 'name', null, ascending);
                    case 'childInstitutionsName':
                        return quicksort(institutions, 'childInstitutionsName', null, ascending);
                    case 'agent':
                        return quicksortNumbers(institutions, 'agentCount', null, ascending);
                    case 'observer':
                        return quicksortNumbers(institutions, 'observerCount', null, ascending);
                    default:
                        return quicksort(institutions, 'name', null, ascending);
                }
            }
        };

        // Managers sorting =========================================================================================
        this.sortManagers = function (managers, sortingType = 'lastName', ascending = true) {
            if (managers && sortingType) {
                if (managers.length <= 1) {
                    return managers;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'lastName':
                        return quicksort(managers, 'lastName', null, ascending);
                    case 'firstName':
                        return quicksort(managers, 'firstName', null, ascending);
                    default:
                        return quicksort(managers, 'lastName', null, ascending);
                }
            }
        };

        // Users sorting ========================================================================================= //
        this.sortUsers = function (users, sortingType, ascending) {
            if (users && sortingType) {
                if (users.length <= 1) {
                    return users;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'firstName':
                        return quicksort(users, 'firstName', null, ascending);
                    case 'role':
                        return quicksort(users, 'roleTranslated', null, ascending);
                    case 'activityStatus':
                        return quicksortActivityStatus(users, ascending);
                    case 'lastName':
                    default:
                        return quicksort(users, 'lastName', null, ascending);
                }
            }
        };

        // Drivers sorting ========================================================================================= //
        this.sortDrivers = function (drivers, sortingType, ascending) {
            if (drivers && sortingType) {
                if (drivers.length <= 1) {
                    return drivers;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'busNumberAssignment':
                        return sortItemsByPropertyArray(drivers, 'assignments', 'bus.number', ascending);
                    case 'routeCount':
                        return quicksortNumbers(drivers, 'routeCount', null, ascending);
                    default:
                        // Generic sorting types (email, firstName, lastName)
                        return quicksort(drivers, sortingType, null, ascending);
                }
            }
        };

        // driver routes sorting ========================================================================================= //
        this.sortDriverRoutes = function (routes, sortingType, ascending) {
            if (routes && sortingType) {
                if (routes.length <= 1) return routes;

                switch (sortingType) {
                    case 'name':
                        return quicksort(routes, 'name', null, ascending);
                    case 'busNumber':
                        return quicksort(routes, 'bus', 'number', ascending);
                    case 'startTime':
                        return quicksortHoursAndMinutesFromString(routes, 'schedule', 'departure', ascending);
                    case 'client':
                        return quicksort(routes, 'client', 'name', ascending);
                    case 'startDate':
                        return quicksortAssignmentDate(routes, ascending);
                    default:
                        return quicksort(routes, 'name', null, ascending);
                }
            }
        };
        // driver busNumberAssignments sorting ========================================================================================= //
        this.sortBusNumberAssignments = function (assignments, sortingType, ascending) {
            if (assignments && sortingType) {
                if (assignments.length <= 1) return assignments;

                switch (sortingType) {
                    case 'busNumber':
                        // Sort by client, then by BusNumber
                        return quicksort(quicksort(assignments, 'client', 'name', ascending), 'bus', 'number', ascending);
                    case 'client':
                        return quicksort(assignments, 'client', 'name', ascending);
                    case 'runsNumber':
                        return quicksortNumbers(assignments, 'routeCount', null, ascending);
                    default:
                        return quicksort(assignments, 'bus', 'number', ascending);
                }
            }
        };

        /**
         * Sorts an array of objects by a specified property (which is an another array). If the property array has multiple values,
         * the first value is used for sorting.
         *
         * @param {Array} items - The array of objects to sort.
         * @param {string} property - The name of the property that contains the array to sort by.
         * @param {string} subPropertyPath - The path to the sub property within the array items to sort by.
         * @param {boolean} ascending - If true, sorts in ascending order; otherwise, sorts in descending order.
         * @return {Array} The sorted array of objects.
         */
        function sortItemsByPropertyArray(items, property, subPropertyPath, ascending) {
            return items.sort((item1, item2) => {
                // Find value from nested sub properties, using subPropertyPath, for the first element of the property array
                const value1 = subPropertyPath.split('.').reduce((acc, key) => acc?.[key], item1[property][0]) || '';
                const value2 = subPropertyPath.split('.').reduce((acc, key) => acc?.[key], item2[property][0]) || '';
                const comparison = value1.localeCompare(value2);

                return ascending ? comparison : -comparison;
            });
        }

        // driver trip notes sorting ========================================================================================= //
        this.sortDriverTripNotes = function (notes, sortingType, ascending) {
            if (notes && sortingType) {
                if (notes.length <= 1) return notes;

                switch (sortingType) {
                    case 'date':
                        return quicksort(notes, 'schedule', null, ascending);
                    case 'routeName':
                        return quicksort(notes, 'route', 'name', ascending);
                    case 'startTime':
                        return quicksortHoursAndMinutesFromString(notes, 'route', 'startTime', ascending);
                    case 'busNumber':
                        return quicksort(notes, 'route', 'busNumber', ascending);
                    default:
                        return quicksort(notes, 'schedule', null, ascending);
                }
            }
        };

        // driver communications sorting ========================================================================================= //
        this.sortDriverCommunications = function (communications, sortingType, ascending) {
            if (communications && sortingType) {
                if (communications.length <= 1) return communications;

                switch (sortingType) {
                    case 'sender':
                        return quicksort(communications, 'sender', null, ascending);
                    case 'createdAt':
                        return quicksortDate(communications, 'createdAt', null, ascending);
                    default:
                        return quicksort(communications, 'sender', null, ascending);
                }
            }
        };

        // Vehicles sorting ========================================================================================= //
        this.sortVehicles = function (vehicles, sortingType, ascending) {
            if (vehicles && sortingType) {
                if (vehicles.length <= 1) {
                    return vehicles;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'identifier':
                        return quicksort(vehicles, 'identifier', null, ascending);
                    case 'licencePlate':
                        return quicksort(vehicles, 'licencePlate', null, ascending);
                    case 'make':
                        return quicksort(vehicles, 'make', null, ascending);
                    case 'model':
                        return quicksort(vehicles, 'model', null, ascending);
                    case 'features':
                        return quicksort(vehicles, 'features', null, ascending);
                    case 'year':
                        return quicksortDate(vehicles, 'year', null, ascending);
                    case 'alternateId':
                        return quicksort(vehicles, 'alternateId', null, ascending);
                    case 'vin':
                        return quicksort(vehicles, 'vin', null, ascending);
                    case 'operator':
                        return quicksort(vehicles, 'operator', null, ascending);
                    case 'territory':
                        return quicksort(vehicles, 'territory', null, ascending);
                    case 'status':
                        return quicksort(vehicles, 'status', null, ascending);
                    case 'notes':
                        return quicksort(vehicles, 'notes', null, ascending);
                    case 'lastInspection':
                        return quicksortDate(vehicles, 'lastInspection', 'signedAt', ascending);
                    default:
                        return quicksort(vehicles, 'identifier', null, ascending);
                }
            }
        };

        // Inspections sorting ========================================================================================= //
        this.sortInspections = function (inspections, sortingType, ascending) {
            if (inspections && sortingType) {
                if (inspections.length <= 1) {
                    return inspections;
                }

                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'fullName':
                        return quicksort(inspections, 'fullName', null, ascending);
                    case 'signedAt':
                        return quicksortDate(inspections, 'firstSignature', 'signedAt', ascending);
                    case 'minorCount':
                        return quicksortNumbers(inspections, 'minorCount', null, ascending);
                    case 'majorCount':
                        return quicksortNumbers(inspections, 'majorCount', null, ascending);
                    case 'vehicleIdentifier':
                        return quicksort(inspections, 'vehicleIdentifier', null, ascending);
                    case 'repairedOn':
                        return quicksortDate(inspections, 'repairedOn', null, ascending);
                    default:
                        return quicksortDate(inspections, 'firstSignature', 'signedAt', ascending);
                }
            }
        };

        // Defects sorting ========================================================================================= //
        this.sortDefects = function (defects, sortingType, ascending) {
            if (defects && sortingType) {
                if (defects.length <= 1) {
                    return defects;
                }

                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'identifier':
                        return quicksortNumbers(defects, 'identifier', null, ascending);
                    default:
                        return quicksortNumbers(defects, 'identifier', null, ascending);
                }
            }
        };

        // Clients sorting ========================================================================================= //

        this.sortClients = function (clients, sortingType = 'name', ascending = true) {
            if (clients && sortingType) {
                if (clients.length <= 1) {
                    return clients;
                }
                // No break in switch case: Unreachable code
                switch (sortingType) {
                    case 'fullName':
                        return quicksort(clients, 'fullName', null, ascending);
                    case 'routeCount':
                        return quicksortNumbers(clients, 'routeCount', null, ascending);
                    case 'busCount':
                        return quicksortNumbers(clients, 'busCount', null, ascending);
                    case 'tripCountToday':
                        return quicksortNumbers(clients, 'tripCount', 'today', ascending);
                    case 'tripCountTomorrow':
                        return quicksortNumbers(clients, 'tripCount', 'tomorrow', ascending);
                    case 'tripCountIn2Days':
                        return quicksortNumbers(clients, 'tripCount', 'in2Days', ascending);
                    case 'tripCountIn3Days':
                        return quicksortNumbers(clients, 'tripCount', 'in3Days', ascending);
                    case 'tripCountIn4Days':
                        return quicksortNumbers(clients, 'tripCount', 'in4Days', ascending);
                    case 'routesStudentsBoardingRegistrations':
                        return quicksortNumbers(clients, 'preferencesSummary', 'studentsBoardingRegistrations', ascending);
                    case 'routesNotifications':
                        return quicksortNumbers(clients, 'preferencesSummary', 'notifications', ascending);
                    case 'routesAutomaticDelayNotifications':
                        return quicksortNumbers(clients, 'preferencesSummary', 'automaticDelayNotifications', ascending);
                    case 'name':
                    default:
                        return quicksort(clients, 'name', null, ascending);
                }
            }
        };

        // Reusable sorting & comparison algorythms ======================================================================================= //
        /**
         * Custom implementation of quicksort that allows the sorting of an array of values or an array of objects having a property
         * returning a value, the value can be a string or a numerical value. Both accents and numbers are considered when the value is a string.
         * The comparison between value is made by a locale compare (alphanumeric comparison, character by character).
         * @param {array} array The array to sort.
         * @param {string} propertyName If the sorting needs to happen on a property of the objects in the array,
         *                              specify the property name as a string, else, make it null.
         * @param {string} subPropertyName If the sorting needs to happen on a subProperty of a property of the objects
         *                                 in the array, specify the subproperty name as a string, else, make it null.
         * @param {boolean} ascending Specifies if you want to sort ascending or descending. Ascending if null.
         */
        function quicksort(array, propertyName, subPropertyName, ascending) {
            if (array.length <= 1) {
                return array;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = array.pop();
                const length = array.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let name1;
                        let name2;
                        if (!propertyName) {
                            name1 = array[i];
                            name2 = pivot;
                        } else if (propertyName && !subPropertyName) {
                            name1 = array[i][propertyName];
                            name2 = pivot[propertyName];
                        } else if (propertyName && subPropertyName) {
                            name1 = array[i][propertyName][subPropertyName];
                            name2 = pivot[propertyName][subPropertyName];
                        }
                        let answer = name1.localeCompare(name2);
                        if (answer <= 0) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let name1;
                        let name2;
                        if (!propertyName) {
                            name1 = array[i];
                            name2 = pivot;
                        } else if (propertyName && !subPropertyName) {
                            name1 = array[i][propertyName];
                            name2 = pivot[propertyName];
                        } else if (propertyName && subPropertyName) {
                            name1 = array[i][propertyName][subPropertyName];
                            name2 = pivot[propertyName][subPropertyName];
                        }
                        let answer = name1.localeCompare(name2);
                        if (answer >= 0) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                }
                return newArray.concat(
                    quicksort(left, propertyName, subPropertyName, ascending),
                    pivot,
                    quicksort(right, propertyName, subPropertyName, ascending)
                );
            }
        }

        /**
         * Custom implementation of quicksort that allows the sorting of an array of values or an array of objects having a property
         * returning a value, the value can be a string or a numerical value. Both accents and numbers are considered when the value is a string.
         * @param {array} array The array to sort.
         * @param {string} propertyName If the sorting needs to happen on a property of the objects in the array,
         *                              specify the property name as a string, else, make it null.
         * @param {string} subPropertyName If the sorting needs to happen on a subProperty of a property of the objects
         *                                 in the array, specify the subproperty name as a string, else, make it null.
         * @param {boolean} ascending Specifies if you want to sort ascending or descending. Ascending if null.
         * @return {array}
         */
        function quicksortNumbers(array, propertyName, subPropertyName, ascending) {
            if (array.length <= 1) {
                return array;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = array.pop();
                const length = array.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let num1;
                        let num2;
                        if (!propertyName) {
                            num1 = parseFloat(array[i]);
                            num2 = parseFloat(pivot);
                        } else if (propertyName && !subPropertyName) {
                            num1 = parseFloat(array[i][propertyName]);
                            num2 = parseFloat(pivot[propertyName]);
                        } else if (propertyName && subPropertyName) {
                            num1 = parseFloat(array[i][propertyName][subPropertyName]);
                            num2 = parseFloat(pivot[propertyName][subPropertyName]);
                        }
                        if (num1 <= num2) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let num1;
                        let num2;
                        if (!propertyName) {
                            num1 = parseFloat(array[i]);
                            num2 = parseFloat(pivot);
                        } else if (propertyName && !subPropertyName) {
                            num1 = parseFloat(array[i][propertyName]);
                            num2 = parseFloat(pivot[propertyName]);
                        } else if (propertyName && subPropertyName) {
                            num1 = parseFloat(array[i][propertyName][subPropertyName]);
                            num2 = parseFloat(pivot[propertyName][subPropertyName]);
                        }
                        if (num1 >= num2) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                }
                return newArray.concat(
                    quicksortNumbers(left, propertyName, subPropertyName, ascending),
                    pivot,
                    quicksortNumbers(right, propertyName, subPropertyName, ascending)
                );
            }
        }

        /**
         * Custom implementation of quicksort that allows the sorting of an array of values or an array of objects having a property
         * returning a value, the value is a string in the format HOURS:MINUTES
         * @param {array} array The array to sort.
         * @param {string} propertyName If the sorting needs to happen on a property of the objects in the array,
         *                              specify the property name as a string, else, make it null.
         * @param {string} subPropertyName If the sorting needs to happen on a subProperty of a property of the objects
         *                                 in the array, specify the subproperty name as a string, else, make it null.
         * @param {boolean} ascending Specifies if you want to sort ascending or descending. Ascending if null.
         */
        function quicksortHoursAndMinutesFromString(array, propertyName, subPropertyName, ascending) {
            if (array.length <= 1) {
                return array;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = array.pop();
                const length = array.length;

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let time1 = 0;
                        let time2 = 0;
                        if (!propertyName) {
                            if (array[i]) {
                                let minutes1 = array[i].split(':')[1];
                                let hours1 = array[i].split(':')[0];
                                time1 = parseInt(minutes1) + parseInt(hours1) * 60;
                            }
                            if (pivot) {
                                let minutes2 = pivot.split(':')[1];
                                let hours2 = pivot.split(':')[0];
                                time2 = parseInt(minutes2) + parseInt(hours2) * 60;
                            }
                        } else if (propertyName && !subPropertyName) {
                            if (array[i]?.[propertyName]) {
                                let minutes1 = array[i][propertyName].split(':')[1];
                                let hours1 = array[i][propertyName].split(':')[0];
                                time1 = parseInt(minutes1) + parseInt(hours1) * 60;
                            }
                            if (pivot?.[propertyName]) {
                                let minutes2 = pivot[propertyName].split(':')[1];
                                let hours2 = pivot[propertyName].split(':')[0];
                                time2 = parseInt(minutes2) + parseInt(hours2) * 60;
                            }
                        } else if (propertyName && subPropertyName) {
                            if (array[i]?.[propertyName]?.[subPropertyName]) {
                                let minutes1 = array[i][propertyName][subPropertyName].split(':')[1];
                                let hours1 = array[i][propertyName][subPropertyName].split(':')[0];
                                time1 = parseInt(minutes1) + parseInt(hours1) * 60;
                            }
                            if (pivot?.[propertyName]?.[subPropertyName]) {
                                let minutes2 = pivot[propertyName][subPropertyName].split(':')[1];
                                let hours2 = pivot[propertyName][subPropertyName].split(':')[0];
                                time2 = parseInt(minutes2) + parseInt(hours2) * 60;
                            }
                        }

                        if (time1 <= time2) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < length; i++) {
                        let time1 = 0;
                        let time2 = 0;
                        if (!propertyName) {
                            if (array[i]) {
                                let minutes1 = array[i].split(':')[1];
                                let hours1 = array[i].split(':')[0];
                                time1 = parseInt(minutes1) + parseInt(hours1) * 60;
                            }
                            if (pivot) {
                                let minutes2 = pivot.split(':')[1];
                                let hours2 = pivot.split(':')[0];
                                time2 = parseInt(minutes2) + parseInt(hours2) * 60;
                            }
                        } else if (propertyName && !subPropertyName) {
                            if (array[i]?.[propertyName]) {
                                let minutes1 = array[i][propertyName].split(':')[1];
                                let hours1 = array[i][propertyName].split(':')[0];
                                time1 = parseInt(minutes1) + parseInt(hours1) * 60;
                            }
                            if (pivot?.[propertyName]) {
                                let minutes2 = pivot[propertyName].split(':')[1];
                                let hours2 = pivot[propertyName].split(':')[0];
                                time2 = parseInt(minutes2) + parseInt(hours2) * 60;
                            }
                        } else if (propertyName && subPropertyName) {
                            if (array[i]?.[propertyName]?.[subPropertyName]) {
                                let minutes1 = array[i][propertyName][subPropertyName].split(':')[1];
                                let hours1 = array[i][propertyName][subPropertyName].split(':')[0];
                                time1 = parseInt(minutes1) + parseInt(hours1) * 60;
                            }
                            if (pivot?.[propertyName]?.[subPropertyName]) {
                                let minutes2 = pivot[propertyName][subPropertyName].split(':')[1];
                                let hours2 = pivot[propertyName][subPropertyName].split(':')[0];
                                time2 = parseInt(minutes2) + parseInt(hours2) * 60;
                            }
                        }

                        if (time1 >= time2) {
                            left.push(array[i]);
                        } else {
                            right.push(array[i]);
                        }
                    }
                }
                return newArray.concat(
                    quicksortHoursAndMinutesFromString(left, propertyName, subPropertyName, ascending),
                    pivot,
                    quicksortHoursAndMinutesFromString(right, propertyName, subPropertyName, ascending)
                );
            }
        }

        /**
         * Custom sorting that separates boolean values from an array and put them back together keeping similar values together.
         * @param {array} array The array to sort.
         * @param {string} propertyName If the sorting needs to happen on a property of the objects in the array,
         *                              specify the property name as a string, else, make it null.
         * @param {string} subPropertyName If the sorting needs to happen on a subProperty of a property of the objects
         *                                 in the array, specify the subproperty name as a string, else, make it null.
         * @param {boolean} ascending Specifies if you want to sort ascending or descending. Ascending if null.
         */
        function sortBoolean(array, propertyName, subPropertyName, ascending) {
            if (array.length <= 1) {
                return array;
            } else {
                let truthy = [];
                let falsy = [];
                let length = array.length;
                let newArray = [];

                if (ascending) {
                    for (let i = 0; i < length; i++) {
                        let value;
                        if (!propertyName) {
                            value = array[i];
                        } else if (propertyName && !subPropertyName) {
                            value = array[i][propertyName];
                        } else if (propertyName && subPropertyName) {
                            value = array[i][propertyName][subPropertyName];
                        }

                        if (value) {
                            truthy.push(array[i]);
                        } else {
                            falsy.push(array[i]);
                        }
                    }
                    return newArray.concat(truthy, falsy);
                } else {
                    for (let i = length - 1; i >= 0; i--) {
                        let value;
                        if (!propertyName) {
                            value = array[i];
                        } else if (propertyName && !subPropertyName) {
                            value = array[i][propertyName];
                        } else if (propertyName && subPropertyName) {
                            value = array[i][propertyName][subPropertyName];
                        }

                        if (value) {
                            truthy.push(array[i]);
                        } else {
                            falsy.push(array[i]);
                        }
                    }
                    return newArray.concat(falsy, truthy);
                }
            }
        }

        /**
         * Custom implementation of quicksort that allows the sorting of an array of values or an array of objects having a property
         * returning a value, the value is a timestamp.
         * @param {array} array The array to sort.
         * @param {string} propertyName If the sorting needs to happen on a property of the objects in the array,
         *                              specify the property name as a string, else, make it null.
         * @param {string} subPropertyName If the sorting needs to happen on a subProperty of a property of the objects
         *                                 in the array, specify the subproperty name as a string, else, make it null.
         * @param {boolean} ascending Specifies if you want to sort ascending or descending. Ascending if null.
         * @param {boolean} ignoreDay Specifies if you want to ignore the day in the date comparison.
         * @return {array}
         */
        function quicksortDate(array, propertyName, subPropertyName, ascending, ignoreDay = false) {
            if (array.length <= 1) {
                return array;
            } else {
                const left = [];
                const right = [];
                const newArray = [];
                const pivot = array.pop();
                const length = array.length;

                const getTimeOnly = (date) => {
                    const d = new Date(date);
                    return new Date(1970, 0, 1, d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
                };

                for (let i = 0; i < length; i++) {
                    let time1 = 0;
                    let time2 = 0;
                    if (!propertyName) {
                        if (array[i]) {
                            time1 = ignoreDay ? getTimeOnly(array[i]) : new Date(array[i]);
                        }
                        if (pivot) {
                            time2 = ignoreDay ? getTimeOnly(pivot) : new Date(pivot);
                        }
                    } else if (propertyName && !subPropertyName) {
                        if (array[i]?.[propertyName]) {
                            time1 = ignoreDay ? getTimeOnly(array[i][propertyName]) : new Date(array[i][propertyName]);
                        }
                        if (pivot?.[propertyName]) {
                            time2 = ignoreDay ? getTimeOnly(pivot[propertyName]) : new Date(pivot[propertyName]);
                        }
                    } else if (propertyName && subPropertyName) {
                        if (array[i]?.[propertyName]?.[subPropertyName]) {
                            time1 = ignoreDay
                                ? getTimeOnly(array[i][propertyName][subPropertyName])
                                : new Date(array[i][propertyName][subPropertyName]);
                        }
                        if (pivot?.[propertyName]?.[subPropertyName]) {
                            time2 = ignoreDay ? getTimeOnly(pivot[propertyName][subPropertyName]) : new Date(pivot[propertyName][subPropertyName]);
                        }
                    }

                    if (ascending ? time1 <= time2 : time1 >= time2) {
                        left.push(array[i]);
                    } else {
                        right.push(array[i]);
                    }
                }

                return newArray.concat(
                    quicksortDate(left, propertyName, subPropertyName, ascending, ignoreDay),
                    pivot,
                    quicksortDate(right, propertyName, subPropertyName, ascending, ignoreDay)
                );
            }
        }

        /**
         * Sorts an array of routes based on assignment date and ascending or descending order.
         *
         * @param {Array} routes - The array of routes to be sorted.
         * @param {boolean} ascending - A boolean value indicating whether to sort in ascending order.
         * @return {Array} - The sorted array of routes.
         */
        function quicksortAssignmentDate(routes, ascending) {
            const now = moment().startOf('day');

            const busNumberAssignedArray = [];
            // Create arrays for each type of period
            const activeNoPeriodArray = [];
            const activeWithPeriodArray = [];
            const isFutureArray = [];
            const isPastArray = [];

            routes.forEach((route) => {
                if (route.assignmentPeriod?.startDate != null) {
                    const startDate = moment(route.assignmentPeriod.startDate);
                    const endDate = moment(route.assignmentPeriod.endDate);

                    if (route.assignmentPeriod.isActive) {
                        activeWithPeriodArray.push(route);
                    } else if (startDate < now && now > endDate) {
                        isPastArray.push(route);
                    } else {
                        isFutureArray.push(route);
                    }
                } else if (route.isAssignedByBusNumber) {
                    // In case assigned by bus number, route must be very first
                    busNumberAssignedArray.push(route);
                } else {
                    // In case of no period date, route must be on the first sorting
                    activeNoPeriodArray.push(route);
                }
            });

            const busNumberAssignedArraySorted = busNumberAssignedArray;
            const activeNoPeriodArraySorted = activeNoPeriodArray;
            const activePeriodArraySorted = quicksortDate(activeWithPeriodArray, 'assignmentPeriod', 'startDate', ascending);
            const futurePeriodArraySorted = quicksortDate(isFutureArray, 'assignmentPeriod', 'startDate', ascending);
            const pastPeriodArraySorted = quicksortDate(isPastArray, 'assignmentPeriod', 'startDate', ascending);
            const routesSorted = [
                ...busNumberAssignedArraySorted,
                ...activeNoPeriodArraySorted,
                ...activePeriodArraySorted,
                ...futurePeriodArraySorted,
                ...pastPeriodArraySorted,
            ];
            return ascending ? routesSorted : routesSorted.reverse();
        }

        /**
         * Sorts an array of users based on activity status and ascending or descending order.
         * @param {Array} users - The array of users to be sorted.
         * @param {boolean} ascending - A boolean value indicating whether to sort in ascending order.
         * @return {Array} - The sorted array of routes.
         */
        function quicksortActivityStatus(users, ascending) {
            // Order of activity statuses
            const statusOrder = {
                recentlyActive: 1,
                inactiveOneWeek: 2,
                inactiveOneMonth: 3,
                inactiveSixMonths: 4,
                inactiveOneYear: 5,
                unavailable: 6,
            };

            if (users.length <= 1) {
                return users;
            }

            const pivot = users[0];
            const pivotStatus = statusOrder[pivot.activityStatus];

            // Partition the array into two subarrays "left" and "right" around a pivot, based on the activity status
            const [left, right] = users.slice(1).reduce(
                ([leftAcc, rightAcc], item) => {
                    const status = statusOrder[item.activityStatus];

                    if ((ascending && status <= pivotStatus) || (!ascending && status >= pivotStatus)) {
                        leftAcc.push(item);
                    } else {
                        rightAcc.push(item);
                    }

                    return [leftAcc, rightAcc];
                },
                [[], []]
            );

            return [...quicksortActivityStatus(left, ascending), pivot, ...quicksortActivityStatus(right, ascending)];
        }

        function compareInputType(a, b) {
            let valueA = 3;
            if (a === 'manual') {
                valueA = 0;
            } else if (a === 'qr') {
                valueA = 1;
            } else if (a === 'nfc') {
                valueA = 2;
            }
            let valueB = 3;
            if (b === 'manual') {
                valueB = 0;
            } else if (b === 'qr') {
                valueB = 1;
            } else if (b === 'nfc') {
                valueB = 2;
            }

            if (valueA < valueB) {
                return -1;
            } else if (valueA === valueB) {
                return 0;
            } else {
                return 1;
            }
        }
    },
]);
