// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('MainMapController', [
    '$scope',
    '$rootScope',
    '$timeout',
    '$controller',
    '$interval',
    '$translate',
    'sortingTools',
    function ($scope, $rootScope, $timeout, $controller, $interval, $translate, sortingTools) {
        const REFRESH_INTERVAL = 1000 * 60; // Refresh every 60 seconds

        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('FavouriteController', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('GlobalMapController', { $scope: $scope }));

        // Data are not available before that date.
        // eslint-disable-next-line no-undef
        const minDate = moment().set({
            year: 2017,
            month: 8,
            date: 18,
            hour: 23,
            minutes: 59,
            second: 59,
        });

        this.$onInit = function () {
            // Init the Map
            $scope.mapElementId = 'mainMap'; // Random DOM Element ID
            $scope.initializeGlobalMapWithoutTrip($scope.mapElementId);

            $scope.currentProgress = 100; // The loading circle is at 100% by default

            $scope.filterSections = []; // Empty, will be generated later after API call
            $scope.onlyFavorite = false; // By default, we display all data. If true, will only only the favourites trips

            $scope.buttonsGroupSelectedOption = $scope.onlyFavorite ? 1 : 0;
            $scope.buttonsGroupOptions = [
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        $scope.updateOnlyFavorite(false);
                    },
                },
                {
                    name: `${$translate.instant('favourites')} <i class="fa fa-star status-yellow"></i>`,
                    onClick: () => {
                        $scope.updateOnlyFavorite(true);
                    },
                },
            ];

            $scope.searchValue = '';
            // User should use filter to access to mainMap
            $scope.isFilterUsed = false;

            $scope.loading = true;

            // Fetch the date in the URL
            const urlDate = $scope.getDateFromURL();
            // Defining the date to use for data fetching
            // eslint-disable-next-line no-undef
            $scope.selectedDate = urlDate ? moment(urlDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
            // eslint-disable-next-line no-undef
            $scope.selectedDateString = $scope.convertToReadableDate($scope.selectedDate, 'long');

            $scope.isItAM = isAM();

            getListTrips();

            // Refresh data each 30sec
            if ($scope.isToday($scope.selectedDate)) {
                this.refreshInterval = $interval(() => {
                    if (!$scope.loading) {
                        $scope.currentProgress += 500;
                        if ($scope.currentProgress >= REFRESH_INTERVAL) {
                            $scope.currentProgress = 0;
                            refreshData();
                        }

                        // Setting the progress percentage for the loader
                        $scope.loaderProgress = 100 - ($scope.currentProgress * 100) / REFRESH_INTERVAL;
                        $('#search').focus();
                    }
                }, 500);
            }

            // Create the date pickers through jQuery. When we'll refactor to more recent framework, use proper libs
            // eslint-disable-next-line no-undef
            $('#main-map__calendar-button').daterangepicker(
                {
                    singleDatePicker: true,
                    opens: 'center',
                    autoApply: true,
                    minDate: minDate,
                    // eslint-disable-next-line no-undef
                    startDate: moment($scope.selectedDate),
                    locale: {
                        format: 'LL',
                    },
                },
                function (start) {
                    updateSelectedDate(start);
                }
            );
        };

        $scope.$on('$destroy', () => {
            // Cancel existing intervals to make sure they don't continue after we change the page
            $interval.cancel(this.refreshInterval);
        });

        /**
         * Get the list of the trips for the selected date
         */
        const getListTrips = () => {
            $scope.loading = true;

            const currentTime = $scope.isItAM ? $translate.instant('am') : $translate.instant('pm');

            $scope.requestTVTrips($scope.selectedDate, currentTime).then(
                (data) => {
                    $scope.inProgressTripsOk = data.inProgress.ok;
                    $scope.defaultInProgressTripsOk = angular.copy($scope.inProgressTripsOk);

                    $scope.inProgressTripsModerated = data.inProgress.moderated;
                    $scope.defaultInProgressTripsModerated = angular.copy($scope.inProgressTripsModerated);

                    $scope.inProgressTripsCritical = data.inProgress.critical;
                    $scope.defaultInProgressTripsCritical = angular.copy($scope.inProgressTripsCritical);

                    $scope.defaultTripsInProgressCount =
                        $scope.defaultInProgressTripsOk.length +
                        $scope.defaultInProgressTripsModerated.length +
                        $scope.defaultInProgressTripsCritical.length;

                    resetFilterOptions();

                    // If the page changes while loading trips, these functions won't be called (prevents error in console)
                    if ($scope.currentView === 'dashboard/map') {
                        $scope.applyFilters();
                        $scope.initializeGlobalMapForMultipleTrips($scope.mapElementId, getAllTrips());
                    }

                    $scope.loading = false;
                    $scope.$apply();
                },
                (error) => {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        // ***************
        // *** REFRESH ***
        // ***************

        /**
         * Refresh the list of trips. Different from getListTrips, since we must updates the array and not changing them.
         */
        const refreshData = () => {
            const currentTime = $scope.isItAM ? $translate.instant('am') : $translate.instant('pm');

            $scope.requestTVTrips($scope.selectedDate, currentTime).then(
                (data) => {
                    $scope.inProgressTripsOk = data.inProgress.ok;
                    $scope.defaultInProgressTripsOk = angular.copy($scope.inProgressTripsOk);

                    $scope.inProgressTripsModerated = data.inProgress.moderated;
                    $scope.defaultInProgressTripsModerated = angular.copy($scope.inProgressTripsModerated);

                    $scope.inProgressTripsCritical = data.inProgress.critical;
                    $scope.defaultInProgressTripsCritical = angular.copy($scope.inProgressTripsCritical);

                    $scope.defaultTripsInProgressCount =
                        $scope.defaultInProgressTripsOk.length +
                        $scope.defaultInProgressTripsModerated.length +
                        $scope.defaultInProgressTripsCritical.length;

                    // If the page changes while loading trips, these functions won't be called (prevents error in console)
                    if ($scope.currentView === 'dashboard/map') {
                        $scope.applyFilters(false);
                        $scope.refreshGlobalMapForMultipleTrips(getAllTrips());
                    }
                },
                (error) => {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        // ***************
        // *** FILTERS ***
        // ***************

        /**
         * Update the filter of favourite only with the new specified value
         * @param {Boolean} newValue
         */
        $scope.updateOnlyFavorite = (newValue) => {
            $scope.onlyFavorite = newValue;
            $scope.applyFilters();
        };

        /**
         * This function will be given to the filter component to be executed each time the filter options and search input are changing.
         * @param {String} searchValue - the string in the search input text
         */
        $scope.onFilterChange = () => {
            $scope.isFilterUsed = true;
            $scope.isFirstDisplay = false;
            $scope.applyFilters();
        };

        /**
         * This function will be given to the input of filter component to be executed each time the input value changes.
         * @param {String} searchValue - the string in the search input text
         */
        $scope.onInputChange = (searchValue) => {
            $scope.searchValue = searchValue;
            $scope.applyFilters();
        };

        /**
         * Apply the filters to the trips array
         * @param {Boolean} resetTrips - Reset trips to their default states before applying filters. Default to true.
         */
        $scope.applyFilters = (resetTrips = true) => {
            // Use deep copy to reset filters
            if (resetTrips) {
                $scope.inProgressTripsOk = angular.copy($scope.defaultInProgressTripsOk);
                $scope.inProgressTripsModerated = angular.copy($scope.defaultInProgressTripsModerated);
                $scope.inProgressTripsCritical = angular.copy($scope.defaultInProgressTripsCritical);
            }

            updateFavouriteStatus();

            $scope.inProgressTripsOk = filterTripCategory($scope.inProgressTripsOk);
            $scope.inProgressTripsModerated = filterTripCategory($scope.inProgressTripsModerated);
            $scope.inProgressTripsCritical = filterTripCategory($scope.inProgressTripsCritical);

            const allTrips = getAllTrips();

            if ($scope.isFilterUsed && $scope.searchValue.length > 0 && allTrips.length === 0) {
                $scope.noResultsForRequest = true;
            } else {
                $scope.noResultsForRequest = false;
            }

            $scope.refreshGlobalMapForMultipleTrips(allTrips);
        };

        /**
         * Filter the specified category arrays with the filters
         * @param {Object} category - the trip category (in progress, completed)
         * @return {Array} the filtered category
         */
        const filterTripCategory = (category) => {
            return category.filter((trip) => isTripInFilters(trip));
        };

        /**
         * Determine if the trip must be displayed or not
         * @param {Object} trip
         * @return {Boolean} True if the trip must be displayed, false if it's filtered out
         */
        const isTripInFilters = (trip) => {
            // Favourite filter
            if ($scope.onlyFavorite && !trip.isFavourite) {
                return false;
            }

            // Bus model filter
            const busTypeSection = $scope.filterSections.find((section) => section.id === 'busType');
            if (
                (!Object.hasOwn(trip.route.bus, 'model') &&
                    !busTypeSection.options.find((option) => option.name === $translate.instant('noBusType')).value) ||
                (Object.hasOwn(trip.route.bus, 'model') &&
                    !busTypeSection.options.find((option) => option.name === $translate.instant(trip.route.bus.model)).value)
            ) {
                return false;
            }

            if (['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole)) {
                // Carriers filter
                const carriersSection = $scope.filterSections.find((section) => section.id === 'carriers');
                if (
                    (!Object.hasOwn(trip.route, 'carrier') &&
                        !carriersSection.options.find((option) => option.name === $translate.instant('noCarrier')).value) ||
                    (Object.hasOwn(trip.route, 'carrier') && !carriersSection.options.find((option) => option.name === trip.route.carrier.name).value)
                ) {
                    return false;
                }
            } else {
                // Clients filter
                const clientsSection = $scope.filterSections.find((section) => section.id === 'clients');
                if (
                    (!Object.hasOwn(trip.route.client, 'name') &&
                        !clientsSection.options.find((option) => option.name === $translate.instant('noClient')).value) ||
                    (Object.hasOwn(trip.route.client, 'name') &&
                        !clientsSection.options.find((option) => option.name === trip.route.client.name).value)
                ) {
                    return false;
                }
            }

            // Institutions filter
            const institutionsSection = $scope.filterSections.find((section) => section.id === 'institutions');
            if (
                (countInstitutions(trip.stops) === 0 &&
                    institutionsSection.options.find((option) => option.name === $translate.instant('noSchool')) &&
                    !institutionsSection.options.find((option) => option.name === $translate.instant('noSchool')).value) ||
                (countInstitutions(trip.stops) > 0 && countInstitutionInFilters(trip.stops, institutionsSection.options) === 0)
            ) {
                return false;
            }

            // Text filter
            const dataToCompareWithSearch = {
                tripBusNumber: trip.route.bus.number,
                tripRouteName: trip.route.name,
            };

            if (['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole)) {
                dataToCompareWithSearch.routeCarrier = trip.route.carrier;
            }

            return $scope.containsSearchValue(dataToCompareWithSearch, $scope.searchValue);
        };

        /**
         * Refresh the filter options
         */
        const resetFilterOptions = () => {
            // Setup base filters.
            $scope.filterSections = [
                {
                    id: 'status',
                    name: $translate.instant('status'),
                    options: [
                        {
                            id: 'planned',
                            name: $translate.instant('planned'),
                            value: false,
                        },
                        {
                            id: 'inProgress',
                            name: $translate.instant('inProgressTrips'),
                            value: true,
                        },
                        {
                            id: 'completed',
                            name: $translate.instant('completedTrips'),
                            value: false,
                        },
                    ],
                    disabled: true,
                },
                {
                    id: 'busType',
                    name: $translate.instant('busType'),
                    options: [],
                },
                {
                    id: 'carriers',
                    name: $translate.instant('carriers'),
                    options: [],
                },
                {
                    id: 'clients',
                    name: $translate.instant('clients'),
                    options: [],
                },
                {
                    id: 'institutions',
                    name: $translate.instant('schools'),
                    options: [],
                },
            ];

            const trips = getAllTrips();

            // Generating filters for bus models
            const busTypeSection = $scope.filterSections.find((section) => section.id === 'busType');
            trips.forEach((trip) => {
                if (Object.hasOwn(trip.route.bus, 'model')) {
                    if (!busTypeSection.options.find((option) => option.name === $translate.instant(trip.route.bus.model))) {
                        busTypeSection.options.push({
                            name: $translate.instant(trip.route.bus.model),
                            value: true,
                        });
                    }
                } else if (!busTypeSection.options.find((option) => option.name === $translate.instant('noBusType'))) {
                    busTypeSection.options.push({
                        name: $translate.instant('noBusType'),
                        value: true,
                    });
                }
            });

            if (['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole)) {
                // Generating filters for carriers
                const carriersSection = $scope.filterSections.find((section) => section.id === 'carriers');
                trips.forEach((trip) => {
                    if (Object.hasOwn(trip.route, 'carrier')) {
                        if (!carriersSection.options.find((option) => option.name === trip.route.carrier.name)) {
                            carriersSection.options.push({
                                name: trip.route.carrier.name,
                                value: true,
                            });
                        }
                    } else if (!carriersSection.options.find((option) => option.name === $translate.instant('noCarrier'))) {
                        carriersSection.options.push({
                            name: $translate.instant('noCarrier'),
                            value: true,
                        });
                    }
                });
                // sorting carriers
                const carriers = carriersSection.options;
                carriersSection.options = sortingTools.sortCarriers([...carriers], 'name', true);
            } else {
                // Generating filters for clients
                const clientsSection = $scope.filterSections.find((section) => section.id === 'clients');
                trips.forEach((trip) => {
                    if (Object.hasOwn(trip.route.client, 'name')) {
                        if (!clientsSection.options.find((option) => option.name === trip.route.client.name)) {
                            clientsSection.options.push({
                                name: trip.route.client.name,
                                value: true,
                            });
                        }
                    } else if (!clientsSection.options.find((option) => option.name === $translate.instant('noClient'))) {
                        clientsSection.options.push({
                            name: $translate.instant('noClient'),
                            value: true,
                        });
                    }
                });
                // sorting clients
                const clients = clientsSection.options;
                clientsSection.options = sortingTools.sortClients([...clients], 'name', true);
            }
            // Generating filters for institutions
            const institutionsSection = $scope.filterSections.find((section) => section.id === 'institutions');
            let tripWithNoInstitutionExists = false;

            trips.forEach((trip) => {
                if (Object.hasOwn(trip, 'stops')) {
                    let tripInstitutionCount = 0;
                    trip.stops.forEach((stop) => {
                        if (Object.hasOwn(stop, 'institution')) {
                            if (!institutionsSection.options.find((option) => option.name === stop.institution.name)) {
                                institutionsSection.options.push({
                                    name: stop.institution.name,
                                    value: true,
                                });
                            }

                            tripInstitutionCount++;
                        }
                    });

                    // If this trip has no institution at all
                    if (tripInstitutionCount === 0) {
                        tripWithNoInstitutionExists = true;
                    }
                }
            });
            // sorting institutions
            const institutions = institutionsSection.options;
            institutionsSection.options = sortingTools.sortInstitutions([...institutions], 'name', true);

            if (tripWithNoInstitutionExists) {
                institutionsSection.options.unshift({
                    name: $translate.instant('noSchool'),
                    value: true,
                });
            }
        };

        // ******************
        // *** FAVOURITES ***
        // ******************

        /**
         * Toggle the favourite option of the specified trip
         * @param {String} trip
         */
        $scope.favouriteToggle = (trip) => {
            $scope.addOrRemoveRouteFavourite(trip.route.id);

            updateFavouriteStatus();
        };

        /**
         * Update favourites bools on all trips, since this info is in the cookies.
         * Note: This feature should later on be refactored to be managed in the backend.
         */
        const updateFavouriteStatus = () => {
            const favourites = $scope.getRouteFavourites();

            updateFavouriteForCategory($scope.inProgressTripsOk, favourites);
            updateFavouriteForCategory($scope.inProgressTripsModerated, favourites);
            updateFavouriteForCategory($scope.inProgressTripsCritical, favourites);
        };

        /**
         * Update the favourites bools for all trips in the given category.
         * @param {Object} category - the trip category (in progress, completed)
         * @param {String[]} favourites - all  the route favourite ids
         */
        const updateFavouriteForCategory = (category, favourites) => {
            category.forEach((trip) => {
                trip.isFavourite = favourites.indexOf(trip.route.id) > -1;
            });
        };

        // ********************
        // *** DATE CHANGES ***
        // ********************

        /**
         * Change the URL and te selected date to use the new specified date
         * @param {Date} date
         */
        const updateSelectedDate = (date) => {
            // eslint-disable-next-line no-undef
            $scope.selectedDate = moment(date).format('YYYY-MM-DD');
            // eslint-disable-next-line no-undef
            $scope.selectedDateString = $scope.convertToReadableDate($scope.selectedDate, 'long');

            // Making sure the new date does not go before the minDate
            if (date < minDate) {
                date = minDate;
            }

            const formatedDate = date.format('YYYY-MM-DD');
            const newURL = $scope.setVariableOnURL('date', formatedDate);
            // eslint-disable-next-line no-undef
            document.location = newURL;
        };

        /**
         * Changes the selected date to the previous day from the current selected date
         */
        $scope.updateSelectedDateForPreviousDay = () => {
            // eslint-disable-next-line no-undef
            updateSelectedDate(moment($scope.selectedDate).subtract(1, 'days'));
        };

        /**
         * Changes the selected date to the next day from the current selected date
         */
        $scope.updateSelectedDateForNextDay = () => {
            // eslint-disable-next-line no-undef
            updateSelectedDate(moment($scope.selectedDate).add(1, 'days'));
        };

        /**
         * Changes the selected date to today
         */
        $scope.updateSelectedDateForToday = () => {
            // eslint-disable-next-line no-undef
            updateSelectedDate(moment());
        };

        // *************
        // *** UTILS ***
        // *************
        /**
         * Call the api for each trips to get their details
         * @param {Array} trips - the trips to fetch
         * @return {Array} the trips with their details
         */
        const getTripDetails = async (trips) => {
            const tripsWithDetails = [];
            for (const trip of trips) {
                tripsWithDetails.push(await $scope.requestTripDetails(trip.id));
            }
            return tripsWithDetails;
        };

        /**
         * Get an array of all the trips (will consider the applied filters)
         * @return {Object[]}
         */
        const getAllTrips = () => {
            return [...$scope.inProgressTripsOk, ...$scope.inProgressTripsModerated, ...$scope.inProgressTripsCritical];
        };

        /**
         * Count the number of institions of the specified stops that are in the specified options
         * @param {Object[]} stops
         * @param {Object} stops.institution - optional
         * @param {String} stops.institution.name
         * @param {Object} options
         * @return {Number}
         */
        const countInstitutionInFilters = (stops, options) => {
            let count = 0;

            options.forEach((option) => {
                if (option.value) {
                    stops.find((stop) => {
                        if (Object.hasOwn(stop, 'institution') && stop.institution.name === option.name) {
                            count++;
                        }
                    });
                }
            });

            return count;
        };

        /**
         * Count the number of intitutions in the specified stops
         * @param {Object[]} stops
         * @param {Object} stops.institution - optional
         * @return {Number}
         */
        const countInstitutions = (stops) => {
            let count = 0;
            stops.forEach((stop) => {
                if (Object.hasOwn(stop, 'institution')) {
                    count++;
                }
            });
            return count;
        };

        /**
         * Verify if the specified date is one in the past
         * @param {Date} date
         * @return {Boolean} true if the date is in the past
         */
        $scope.isPast = (date) => {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            now.setUTCHours(0);
            return Date.parse(date) < now.getTime();
        };

        /**
         * Verify if the specified date is the current date
         * @param {Date} date
         * @return {Boolean} true if the date is the current date
         */
        $scope.isToday = (date) => {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            now.setUTCHours(0);
            return Date.parse(date) == now.getTime();
        };

        /**
         * Verify if a date is in AM
         * @param {Date} date
         * @return {Boolean} true if AM
         */
        const isAM = (date) => {
            /* If a date was provided, adjust the date to local timezone,
             if no date was provided, localDate receives the current date */
            const localDate = date ? new Date(date) : new Date();
            return localDate.getHours() >= 0 && localDate.getHours() <= 11;
        };
    },
]);
