// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('AssignmentController', [
    '$scope',
    '$translate',
    '$rootScope',
    '$controller',
    'sortingTools',
    'AssignmentsManager',
    function ($scope, $translate, $rootScope, $controller, sortingTools, AssignmentsManager) {
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('FavouriteController', { $scope: $scope }));

        $scope.routes = [];
        $scope.order = {
            // Default sort
            orderBy: 'routeName',
            ascending: true,
        };
        $scope.loading = true;
        $scope.filtering = false;
        $scope.showOnlyFavourites = false; // By default, we display all routes
        $scope.searchValue = {
            value: '',
        };
        $scope.drivers = [];
        $scope.driverToRemove = null;
        $scope.routeToRemoveFrom = null;
        $scope.showRemoveLinkModal = false; // The modal asking for a link removal confirmation is hidden by default

        const now = moment().startOf('day');
        const disabledColor = '#ababab';
        const blackColor = '#2d313a';

        this.$onInit = () => {
            fetchRoutes();
            // Get new count of unassigned routes
            $scope.fetchCountOfUnassignedRoutes();
        };

        /**
         * Initializes the text in the favorites toggle in the right language
         * Called when fetching routes information so that every time the page refreshes, this info does too
         */
        function initializeFavoritesToggleText() {
            $scope.buttonsGroupOptions = [
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        $scope.updateShowOnlyFavourites(false);
                    },
                },
                {
                    name: `${$translate.instant('favourites')} <i class="fa fa-star status-yellow"></i>`,
                    onClick: () => {
                        $scope.updateShowOnlyFavourites(true);
                    },
                },
            ];
        }

        /**
         * Getch all Routes in the API
         */
        const fetchRoutes = () => {
            $scope.requestRoutes().then(
                function ({ routes }) {
                    // For manager filter routes that assignment can be managed by carrier
                    if (['agent', 'manager'].includes($rootScope.loggedUserRole)) {
                        routes = routes.filter((route) => {
                            return route.carrier?.clientCanManageAssignments === true;
                        });
                    }

                    initializeFavoritesToggleText();
                    $scope.defaultRoutes = routes;
                    updateFavouriteForRoutes($scope.defaultRoutes);
                    $scope.routes = $scope.defaultRoutes;
                    $scope.applyFilters(false);
                    $scope.updateDisplayRoutes();

                    if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                        fetchDrivers('mine');
                    } else {
                        $scope.loading = false;
                        $scope.$apply();
                    }
                    $('#search').focus();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                }
            );
        };

        /**
         * Fetch all the drivers the user can link a route with
         * @param {String} carrierID - carrierId to load the drivers from
         */
        const fetchDrivers = (carrierID) => {
            $scope.requestGetDriversForCarrier(carrierID).then(
                function ({ drivers }) {
                    $scope.drivers = drivers;

                    updateAvailableDriversArray();

                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                }
            );
        };

        /**
         * Fetch all the drivers the user can link a route with
         * Returns the available drivers
         * @param {String} carrierID - carrierId to load the drivers from
         * @return {Promise} driver options
         */
        $scope.fetchDriversPromise = (carrierID) => {
            return $scope.requestGetDriversForCarrier(carrierID).then(
                function ({ drivers }) {
                    $scope.drivers = drivers;
                    updateAvailableDriversArray();
                    $scope.loading = false;
                    $scope.$apply();
                    return $scope.selectedRoute.selectDriverOptions;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                    return [];
                }
            );
        };

        $scope.getSelectedRoute = (route) => {
            $scope.loading = true;
            if (route.carrier.id) {
                $scope.selectedRoute = route;
            }
        };

        // *************
        // *** LINKS ***
        // *************

        /**
         * Opens a modal asking the user if they are sure to remove the link between the specified driver/route.
         * @param {Object} driver
         * @param {Object} route
         */
        $scope.askValidationRemoveDriverFromRoute = (driver, route) => {
            $scope.driverToRemove = driver;
            $scope.routeToRemoveFrom = route;
            $scope.showRemoveLinkModal = true;
        };

        /**
         * Removes the link between the pending driver and route
         */
        $scope.removeLink = () => {
            $scope.routeToRemoveFrom.loading = true;
            $scope.showRemoveLinkModal = false;
            const indexOfDriverToRemove = $scope.routeToRemoveFrom.drivers.indexOf($scope.driverToRemove);
            // Removes driver from the route (local array)
            if (indexOfDriverToRemove > -1) {
                // Only splice array when driver is found
                $scope.routeToRemoveFrom.drivers.splice(indexOfDriverToRemove, 1);
            }
            updateAvailableDriversArray();
            // Call API to remove driver from the route

            $scope.requestAdminRemoveDriverFromRoute($scope.routeToRemoveFrom.id, $scope.driverToRemove.id).then(
                function () {
                    $scope.routeToRemoveFrom.loading = false;
                    $scope.routeToRemoveFrom.hasNoAssignedDriverToday = AssignmentsManager.hasNoAssignedDriverToday($scope.routeToRemoveFrom);

                    // Get new count of unassigned routes
                    $scope.fetchCountOfUnassignedRoutes();

                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        /**
         * Link the selected driver with the specified route
         * @param {Object} route
         * @param {Object} route.selectedDriverToAdd - the driver selected in the select box
         */
        $scope.addLink = (route) => {
            route.loading = true;
            route.drivers.push(route.selectedDriverToAdd); // Add driver to the route (local array)

            // Call API to add driver to the route
            $scope
                .requestAdminLinkDriverToRoute(route.id, {
                    emailDriver: route.selectedDriverToAdd.email,
                })
                .then(
                    function () {
                        route.loading = false;
                        route.selectedDriverToAdd = null;
                        route.searchValue.value = '';
                        route.hasNoAssignedDriverToday = false;

                        updateAvailableDriversArray();
                        // Get new count of unassigned routes
                        $scope.fetchCountOfUnassignedRoutes();

                        $scope.$apply();
                    },
                    function (error) {
                        $rootScope.$broadcast('request-error-main', error);
                    }
                );
        };

        /**
         * Updates the array of available drivers for each route
         */
        const updateAvailableDriversArray = () => {
            if ($scope.drivers.length > 0) {
                let routes = [];
                if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                    routes = $scope.routes;

                    // For Manager and agent, get drivers only from selected route
                } else if (['agent', 'manager'].includes($rootScope.loggedUserRole)) {
                    routes = [$scope.selectedRoute];
                }

                // For each routes, we are identifying the drivers that can be added, by ignoring the ones already linked.
                for (const route of routes) {
                    route.availableDrivers = $scope.drivers.filter((driver) => !route.drivers.find((routeDriver) => driver.id === routeDriver.id));
                    // Set dropdown options to available drivers sorted by last name
                    route.selectDriverOptions = sortingTools.sortDrivers(
                        route.availableDrivers.map((driver) => ({ ...driver, searchValue: { value: '' }, optionsOpen: false })),
                        'lastName',
                        true
                    );
                }
            }
        };

        // ***************
        // *** FILTERS ***
        // ***************

        /**
         * Apply the filters to the rpties array
         * @param {Boolean} reset - Reset routes array to its default states (all routes, without filters)
         */
        $scope.applyFilters = (reset = true) => {
            $scope.filtering = true;
            // Use deep copy to reset filters
            if (reset) {
                $scope.routes = angular.copy($scope.defaultRoutes);
            }

            $scope.routes = $scope.routes.filter((trip) => isRouteInFilters(trip));

            // Sort the full set of routes
            sortRoutes();

            // Reset the current page only if we are explicitly resetting filters
            if (reset) {
                $scope.currentPage = 1;
            }

            $scope.updateDisplayRoutes(); // Update displayed routes based on new filters
            $scope.filtering = false;
        };

        /**
         * Verify if the route must be displayed or not
         * @param {Object} route
         * @return {Boolean} True if the route must be displayed, false if it's filtered out
         */
        const isRouteInFilters = (route) => {
            // Favourite filter
            if ($scope.showOnlyFavourites && !route.isFavourite) {
                return false;
            }
            if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                // Text filter
                const dataToCompareWithSearch = {
                    tripRouteName: route.name,
                    tripBusNumber: route.bus.number,
                    routeClient: route.client.name,
                    routeDriversEmail: route.drivers.map((driver) => driver.email),
                    routeDriversName: route.drivers.map((driver) => `${driver.firstName} ${driver.lastname}`),
                };

                return $scope.containsSearchValue(dataToCompareWithSearch, $scope.searchValue.value);
            } else if (['agent', 'manager'].includes($rootScope.loggedUserRole)) {
                // Text filter
                const dataToCompareWithSearch = {
                    tripRouteName: route.name,
                    tripBusNumber: route.bus.number,
                    routeClient: route.carrier.name,
                    routeDriversEmail: route.drivers.map((driver) => driver.email),
                    routeDriversName: route.drivers.map((driver) => `${driver.firstName} ${driver.lastname}`),
                };

                return $scope.containsSearchValue(dataToCompareWithSearch, $scope.searchValue.value);
            }
        };
        // ******************
        // *** FAVOURITES ***
        // ******************

        /**
         * Update the bool value of showOnlyFavourites
         * @param {Boolean} newValue
         */
        $scope.updateShowOnlyFavourites = (newValue) => {
            $scope.showOnlyFavourites = newValue;
            $scope.applyFilters();
        };

        /**
         * Toggle the favourite option of the specified route
         * @param {String} route
         */
        $scope.favouriteToggle = (route) => {
            $scope.addOrRemoveRouteFavourite(route.id);

            updateFavouriteForRoutes($scope.routes);
            updateFavouriteForRoutes($scope.defaultRoutes);
        };

        /**
         * Update the favourites bools for all routes in the given array
         * @param {Object} routes - all the routes to updates
         */
        const updateFavouriteForRoutes = (routes) => {
            const favourites = $scope.getRouteFavourites();

            routes.forEach((route) => {
                route.isFavourite = favourites.indexOf(route.id) > -1;
            });
        };

        // *****************
        // **** SORTING ****
        // *****************

        $scope.changeSorting = (orderBy) => {
            $scope.order = {
                orderBy: orderBy,
                ascending: $scope.order.orderBy === orderBy ? !$scope.order.ascending : true,
            };

            sortRoutes();
        };

        /**
         * Sort the routes in the table
         */
        const sortRoutes = () => {
            // Create 3 arrays to sort them separately
            let routesWithoutDrivers = $scope.routes.filter((route) => route.assignedDriversByBusNumber.length === 0 && route.drivers.length === 0);
            let routesWithNoAssignedDriverToday = [];
            let routesWithDrivers = $scope.routes.filter((route) => {
                route.drivers = AssignmentsManager.sortDriversByStateAndDate(route.drivers);

                if (AssignmentsManager.hasNoAssignedDriverToday(route)) {
                    route.hasNoAssignedDriverToday = true;
                    routesWithNoAssignedDriverToday.push(route);
                    return false;
                }

                return route.assignedDriversByBusNumber.length > 0 || route.drivers.length > 0;
            });

            routesWithoutDrivers = sortingTools.sortRoutes(routesWithoutDrivers, $scope.order.orderBy, $scope.order.ascending);
            routesWithNoAssignedDriverToday = sortingTools.sortRoutes(routesWithNoAssignedDriverToday, $scope.order.orderBy, $scope.order.ascending);
            routesWithDrivers = sortingTools.sortRoutes(routesWithDrivers, $scope.order.orderBy, $scope.order.ascending);

            $scope.routes = routesWithoutDrivers.concat(routesWithNoAssignedDriverToday, routesWithDrivers);
            $scope.routes.forEach((route) => {
                route.assignedDriversByBusNumber = sortingTools.sortDrivers(route.assignedDriversByBusNumber, 'lastName', true);
            });
            $scope.updateDisplayRoutes(); // Update displayed routes based on new sorting
        };

        // *************************
        // *** DATE RANGE PICKER ***
        // *************************

        /**
         * Called on calendar icon clicked
         * @param {Object} route
         * @param {Object} driver - the driver to whom we will assign the date
         */
        $scope.openDatePickerFromCalendarIcon = function (route, driver) {
            $(function () {
                buildDateRangePicker(route, driver, true);
            });
        };

        /**
         * Called on input init
         * @param {Object} route
         * @param {Object} driver - the driver to whom we will assign the date
         */
        $scope.initializeDatePicker = function (route, driver) {
            $(function () {
                buildDateRangePicker(route, driver, false);
            });
        };

        // Allow user to preselect date range by week or month
        const defineDatePickerRanges = () => {
            const ranges = {};
            ranges[$translate.instant('thisWeek')] = [moment().startOf('week'), moment().endOf('week')];
            ranges[$translate.instant('thisMonth')] = [moment().startOf('month'), moment().endOf('month')];
            return ranges;
        };

        /**
         * Build daterangepicker with some options
         * Call patch request when date range selected
         * @param {Object} route
         * @param {Object} driver - the driver to whom we will assign the date
         * @param {Boolean} showDatePicker - used to show date picker when selected by an other element than input (here calendar)
         */
        function buildDateRangePicker(route, driver, showDatePicker) {
            const ranges = defineDatePickerRanges();

            // Build a dynamic input
            const $dynamInput = $(`.form-control[data-id=${driver.id}-${route.id}]`);

            if (driver.period?.startDate != null && driver.period.endDate != null) {
                const startDate = requestStartDateFormat(driver.period.startDate);
                const endDate = requestEndDateFormat(driver.period.endDate);

                $dynamInput.val(inputDateFormat(startDate, endDate));
                checkDriverAssignationPeriod(driver, route, false);
            }

            // Initializes the date range picker with the current date if no driver period
            $dynamInput.on('click', function () {
                if (!driver.period.startDate && !driver.period.endDate) {
                    $dynamInput.val(inputDateFormat(moment(), moment()));
                    $scope.dateRange = {
                        start: moment().startOf('day'),
                        end: moment().endOf('day'),
                    };
                    updateButtonState(driver, route);
                }
            });

            // API call to change assignment date on blur to handle the case where the user clicks outside the datepicker
            $dynamInput.on('blur', function (ev, picker) {
                if ($scope.dateRange) {
                    $scope.addAssignmentDateToDriver($scope.dateRange, route, driver);
                    $scope.dateRange = null;
                }
            });

            // Create the daterangepicker through JQUERY, for it does not support angular.
            // Note that the dates are using moment.js instead of the default Javascript date object.
            $dynamInput.daterangepicker(
                {
                    alwaysShowCalendars: true,
                    autoApply: true,
                    opens: 'right',
                    ...(driver?.period?.startDate &&
                        driver?.period?.endDate && {
                            startDate: moment(driver.period.startDate),
                            endDate: moment(driver.period.endDate),
                        }),
                    showCustomRangeLabel: false,
                    ranges: ranges,
                    autoUpdateInput: false,
                },
                function (start, end, label) {
                    const dateRange = {
                        start: moment(start).format(),
                        end: moment(end).format(),
                    };
                    $scope.dateRange = dateRange;
                    $dynamInput.val(inputDateFormat($scope.dateRange.start, $scope.dateRange.end));
                    if (driver.period) {
                        checkDriverAssignationPeriod(driver, route, false);
                    }
                    if ($scope.dateRange) {
                        $scope.addAssignmentDateToDriver($scope.dateRange, route, driver);
                    }
                }
            );

            if (showDatePicker) {
                const dynamicPicker = $dynamInput.data('daterangepicker');
                dynamicPicker.show();
            }
            updateButtonState(driver, route);
        }

        // *******************
        // *** DATE FORMAT ***
        // *******************

        // Used to format date readable for user
        const inputDateFormat = (startDate, endDate) => {
            const startDateFormatted = moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
            const endDateFormatted = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return startDateFormatted + ' - ' + endDateFormatted;
        };

        // Used to send or get start date with right format
        const requestStartDateFormat = (date) => {
            const dateFormattedForRequests = moment(date).startOf('day');
            return dateFormattedForRequests;
        };

        // Used to send or get end date with right format
        const requestEndDateFormat = (date) => {
            const dateFormattedForRequests = moment(date).endOf('day');
            return dateFormattedForRequests;
        };

        // ***********************
        // *** DATE ASSIGNMENT ***
        // ***********************

        /**
         * Add date range assignment to the driver with the specified route
         * @param {Object} date - include startDate and endDate
         * @param {Object} route
         * @param {Object} driver - the driver to whom we will assign the date
         */
        $scope.addAssignmentDateToDriver = async function (date, route, driver) {
            driver.datePickerLoading = true;

            const startDateFormatted = requestStartDateFormat(date.start);
            const endDateFormatted = requestEndDateFormat(date.end);

            const data = {
                startDate: startDateFormatted,
                endDate: endDateFormatted,
            };

            // Call API to patch an assignment range of date into driver to the route
            try {
                await $scope.requestAdminPatchDriverToRoute(route.id, driver.id, data);
            } catch (error) {
                driver.datePickerLoading = false;
                $scope.catchErrorDefault(error);
                if (error.status === 400) {
                    if (error.responseJSON.error.code === 1001) {
                        error.key = $translate.instant('messageErrorEndDateBeforeStartDate');
                    }
                    $rootScope.$broadcast('request-error-assignment-driver-route', error);
                }
            } finally {
                driver.period = data;

                checkDriverAssignationPeriod(driver, route, false);
                route.hasNoAssignedDriverToday = AssignmentsManager.hasNoAssignedDriverToday(route);

                updateButtonState(driver, route);
                driver.datePickerLoading = false;

                $scope.$apply();
            }
        };

        /**
         * Remove date to the driver with the specified route by patching dates to null
         * @param {Object} route
         * @param {Object} driver - the driver to whom we will remove the date
         */
        $scope.removeDateAssignment = (route, driver) => {
            driver.datePickerLoading = true;

            // Call API to patch date assignment to null
            $scope
                .requestAdminPatchDriverToRoute(route.id, driver.id, {
                    startDate: null,
                    endDate: null,
                })
                .then(
                    function () {
                        driver.startDate = null;
                        driver.endDate = null;

                        // Reset values of date picker
                        buildDateRangePicker(route, driver, false);

                        // Delete input value when user clicks on delete button
                        const $dynamInput = $(`.form-control[data-id=${driver.id}-${route.id}]`);
                        $dynamInput.val('');

                        checkDriverAssignationPeriod(driver, route, true);
                        route.hasNoAssignedDriverToday = AssignmentsManager.hasNoAssignedDriverToday(route);

                        updateButtonState(driver, route);

                        driver.datePickerLoading = false;

                        $scope.$apply();
                    },
                    function (error) {
                        driver.datePickerLoading = false;
                        $rootScope.$broadcast('request-error-main', error);
                    }
                );
        };

        /**
         * Returns True if the route is editable, false otherwise. Carrier observer can't edit routes.
         * @param {Object} route
         * @param {Boolean} route.loading - True if the route is having an operation in progress
         * @return {Boolean}
         */
        $scope.isRouteEditable = (route) => {
            return !(route.loading || ['carrier_observer'].includes($rootScope.loggedUserRole));
        };

        // *************
        // *** STYLE ***
        // *************

        /**
         * Update style of input and label depending of assignment period of a driver for a given route.
         * @param {Object} driver
         * @param {Object} route
         * @param {boolean} reset - Reset values when user removeDateAssignment
         */
        const checkDriverAssignationPeriod = (driver, route, reset) => {
            const selector = `[data-id=${driver.id}-${route.id}]`;
            const $dynamInput = $(`.form-control${selector}`);
            const $dynamSpan = $(`.assignment__driver-label${selector}`);

            const startDate = moment(driver.period.startDate);
            const endDate = moment(driver.period.endDate);

            const originalStyle = {
                color: blackColor,
                'text-decoration': 'none',
                'text-decoration-color': blackColor,
            };

            if ((startDate <= now && now < endDate) || reset) {
                driver.period.isActive = true;
                $dynamInput.css(originalStyle);
                $dynamSpan.css(originalStyle);
            } else {
                driver.period.isFuture = startDate > now && now < endDate;
                const disabledStyle = {
                    color: disabledColor,
                    'text-decoration': driver.period.isFuture ? 'none' : 'line-through',
                };
                $dynamSpan.css(disabledStyle);
                $dynamInput.css(disabledStyle);
            }
        };

        /**
         * Update button state depending of input value
         * @param {Object} driver
         * @param {Object} route
         */
        const updateButtonState = (driver, route) => {
            const selector = `[data-id=${driver.id}-${route.id}]`;
            const $dynamInput = $(`.form-control${selector}`);
            const $dynamButton = $(`.selectInput__button${selector}`);

            // Retrieve the input value
            const inputValue = $dynamInput.val();

            // Modify ng-class and button prop based on the input value
            if (inputValue === '') {
                $dynamButton.addClass('selectInput__button--disabled');
                $dynamButton.prop('disabled', true);
                $dynamButton.removeClass('selectInput__button--add');
            } else {
                $dynamButton.addClass('selectInput__button--add');
                $dynamButton.prop('disabled', false);
                $dynamButton.removeClass('selectInput__button--disabled');
            }
        };

        // Pagination Logic
        $scope.displayRoutes = []; // Routes to display on current page
        $scope.currentPage = 1; // Initialize current page
        $scope.itemsPerPage = 20; // Items per page

        // Function to update the displayed routes based on pagination
        $scope.updateDisplayRoutes = function () {
            const start = ($scope.currentPage - 1) * $scope.itemsPerPage;
            const end = start + $scope.itemsPerPage;
            $scope.displayRoutes = $scope.routes.slice(start, end);

            // Update the available drivers for the displayed routes
            updateAvailableDriversArray();

            // Recalculate the array for pagination
            const routesLength = $scope.routes.length;
            $scope.totalPages = routesLength > 0 ? Math.ceil(routesLength / $scope.itemsPerPage) : 1;
            $scope.pageArray = Array.from({ length: $scope.totalPages }, (_, i) => i + 1);

            // initialize the searchValue for each route
            $scope.displayRoutes.forEach((route) => {
                if (!route.searchValue) {
                    route.searchValue = { value: '' };
                }
            });

            // Scroll to the top of the page
            window.scrollTo(0, 0);
        };

        // Next Page function
        $scope.nextPage = function () {
            if ($scope.currentPage < Math.ceil($scope.routes.length / $scope.itemsPerPage)) {
                $scope.currentPage++;
                $scope.updateDisplayRoutes();
            }
        };

        // Previous Page function
        $scope.prevPage = function () {
            if ($scope.currentPage > 1) {
                $scope.currentPage--;
                $scope.updateDisplayRoutes();
            }
        };

        // Function to go to a specific page
        $scope.goToPage = function (page) {
            $scope.currentPage = page;
            $scope.updateDisplayRoutes();
        };

        // Initially update displayRoutes based on existing data
        $scope.updateDisplayRoutes();
    },
]);
