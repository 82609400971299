const moment = require('moment');
/**
 * Checks if the given date is today.
 * @param {Date|string} date - The date to check. It can be a Date object or a string representing a date (e.g., '2024-04-08').
 * @return {boolean} Returns true if the given date is today, otherwise false.
 */
function dateIsToday(date) {
    return moment(date).isSame(moment(), 'day');
}

module.exports = { dateIsToday };
