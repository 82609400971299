angular.module('mTransportApp').controller('DriversInfoController', [
    '$scope',
    'sortingTools',
    '$rootScope',
    'AssignmentsManager',
    function ($scope, sortingTools, $rootScope, AssignmentsManager) {
        // INITIALIZATION ========================================================================================= //
        $scope.loadDriverInfo = function () {
            let $wrapper = $('#wrapper');
            // Make sure we don't register events twice
            $wrapper.off('show.bs.collapse hide.bs.collapse hidden.bs.collapse shown.bs.collapse', '.collapse');
            // Disable click on bootstrap collapsing events
            $wrapper.on('show.bs.collapse hide.bs.collapse', '.collapse', function () {
                $('.panel-heading.pointer').css('pointerEvents', 'none');
            });
            $wrapper.on('hidden.bs.collapse shown.bs.collapse', '.collapse', function () {
                $('.panel-heading.pointer').css('pointerEvents', 'auto');
            });

            $scope.loadingDriverInfo = true;
            $scope.loadingDriverBusNumberAssignments = true;
            $scope.loadingClients = true;
            $scope.loadingBusNumbers = true;

            $scope.loadingDriverRoutes = true;
            $scope.loadingDriverTripNotes = true;
            $scope.loadingCommunication = true;
            $scope.loadingForms = true;
            $scope.communicationModuleNotEnable = false;

            // Add Bus Number modal

            $scope.isAddBusNumberAssignmentModalDisplayed = false;
            $scope.clients = [];
            $scope.hasManyClients = false;
            $scope.busNumbers = [];
            $scope.filteredBusNumbers = [];

            $scope.showSelectClientError = false;
            $scope.showNoClientErrorBanner = false;

            $scope.clientSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedClient: null,
            };

            $scope.busNumberSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedBusNumber: null,
            };

            $scope.busNumberAssignmentsOrderType = {
                orderBy: 'busNumber',
                ascending: true,
            };
            $scope.routesOrderType = {
                orderBy: 'startDate',
                ascending: true,
            };

            $scope.tripNotesOrderType = {
                orderBy: 'date',
                ascending: false,
            };

            $scope.communicationsOrderType = {
                orderBy: 'createdAt',
                ascending: false,
            };

            $scope.disciplineOrderType = {
                orderBy: 'createdAt',
                ascending: false,
            };

            $scope.photosOrderType = {
                orderBy: 'createdAt',
                ascending: false,
            };

            $scope.currentLang = localStorage.getItem('userLanguage');

            const driverId = $rootScope.loggedUserRole === 'driver' ? 'me' : $scope.getIdFromURL();
            $scope.driverId = driverId;

            fetchDriverInfo(driverId);
            fetchDriverTripNotes(driverId);
            fetchDriverForms(driverId);
            fetchCarrierSettings(driverId);

            if (['carrier_manager', 'dispatcher', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                fetchDriverBusNumberAssignments(driverId);
                fetchDriverRoutes(driverId);
            }
        };

        // FETCH DATA ========================================================================================= //

        /**
         * Fetch driver's info
         * @param {String} driverId
         */
        function fetchDriverInfo(driverId) {
            $scope.requestGetDriverInfoForCarrier('mine', driverId).then(
                function (driver) {
                    $scope.driver = driver;
                    $scope.loadingDriverInfo = false;

                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingDriverInfo = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch Clients (school organizations)
         * @return {Promise} - Promise resolved when clients are fetched
         */
        function fetchClients() {
            $scope.clients = [];
            return $scope.requestGetClientsLinkedToCarrier('mine').then(
                function (data) {
                    data.clients.forEach((client) => {
                        $scope.clients.push(client);
                    });
                    $scope.clients = sortingTools.sortClients($scope.clients);

                    $scope.hasManyClients = $scope.clients.length > 1;
                    $scope.loadingClients = false;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingClients = false;
                    $scope.$apply();
                }
            );
        }
        /**
         * Fetch bus numbers
         */
        function fetchBusNumbers() {
            $scope.busNumbers = [];
            $scope.requestRoutes(true, false, false).then(
                function (data) {
                    data.routes.forEach((route) => {
                        const clientName = route.client.name;
                        const busNumber = route.bus.number;
                        const exists = $scope.busNumbers.some((bus) => bus.name === busNumber);

                        if (!exists) {
                            const busOption = { name: busNumber, client: clientName };
                            $scope.busNumbers.push(busOption);
                        }
                    });
                    // Sort BusNumbers by name
                    $scope.busNumbers = sortingTools.sortBusNumbers($scope.busNumbers);

                    $scope.loadingBusNumbers = false;
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingBusNumbers = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch driver's bus number assignments
         * @param {String} driverId
         */
        function fetchDriverBusNumberAssignments(driverId) {
            $scope.requestGetDriverBusAssignmentsForCarrier('mine', driverId).then(
                function (data) {
                    $scope.busNumberAssignments = data.assignments;
                    $scope.busNumberAssignments = sortDriverBusNumberAssignments([...$scope.busNumberAssignments]);

                    $scope.loadingDriverBusNumberAssignments = false;
                    $scope.$apply();
                    $('#driverBusAssignment').collapse('show');
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingDriverBusNumberAssignments = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch driver's routes
         * @param {String} driverId
         */
        function fetchDriverRoutes(driverId) {
            $scope.angRequestGetRoutesForDriver('mine', driverId).then(
                function (response) {
                    $scope.routes = response.data.routes.map((route) => ({
                        ...route,
                        readableDate:
                            route.assignmentPeriod?.startDate && route.assignmentPeriod?.endDate
                                ? readableDateFormat(route.assignmentPeriod.startDate, route.assignmentPeriod.endDate)
                                : '',
                    }));

                    // Format routes with assignment period status
                    $scope.routes = AssignmentsManager.formatRoutesWithAssignmentStatus($scope.routes);

                    $scope.routesToDisplay = sortDriverRoutes(angular.copy($scope.routes));
                    $scope.loadingDriverRoutes = false;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingDriverRoutes = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Formats the start and end dates in a readable date range format.
         *
         * @param {string} startDate - Start date in ISO 8601 format.
         * @param {string} endDate - End date in ISO 8601 format.
         * @return {string} A formatted date range string in 'YYYY-MM-DD - YYYY-MM-DD' format.
         */
        const readableDateFormat = (startDate, endDate) => {
            const startDateFormatted = moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
            const endDateFormatted = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return startDateFormatted + ' - ' + endDateFormatted;
        };

        /**
         * Fetch driver's trip notes
         * @param {String} driverId
         */
        function fetchDriverTripNotes(driverId) {
            const endDate = moment().toISOString();
            const startDate = moment().subtract(30, 'days').toISOString();
            $scope.requestReportHistoryOfDriversNotes(startDate, endDate, driverId).then(
                function (response) {
                    const tripNotes = response.trips.map((trip) => ({
                        ...trip,
                        schedule: moment(trip.schedule).format('YYYY-MM-DD'),
                        route: {
                            ...trip.route,
                            startTime: trip.route.schedule.departure,
                            busNumber: trip.route.bus.number,
                        },
                    }));

                    $scope.tripNotes = tripNotes;
                    $scope.tripNotesToDisplay = sortDriverTripNotes(angular.copy(tripNotes));
                    $scope.loadingDriverTripNotes = false;
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingDriverTripNotes = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch carrier settings to check if user is allowed to get communication module
         * @param {String} driverId
         */
        function fetchCarrierSettings(driverId) {
            $scope.angRequestAdminGetSettingsForCarrier('mine').then(
                function ({ data: { settings } }) {
                    const carrierSettings = {
                        communicationModule: settings.communication.isEnabled,
                    };

                    // Communications request called only when user is allowed
                    if (carrierSettings.communicationModule) {
                        fetchDriverCommunications(driverId);
                    } else {
                        // Display empty state
                        $scope.loadingCommunication = false;
                        $scope.communicationModuleNotEnable = true;
                    }
                },

                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch driver's communications
         * @param {String} driverId
         */
        function fetchDriverCommunications(driverId) {
            $scope
                .angRequestGetDispatchMessagesForDriver(driverId)
                .then((messages) => {
                    const communications = messages.data.messages.map((message) => ({
                        id: message.id,
                        createdAt: message.createdAt,
                        subject: message.subject,
                        message: message.message.preview,
                        sender: message.sender.email,
                        ref: `#!/dispatch/messages/message?id=${message.id}`,
                    }));

                    $scope.communications = communications;
                    $scope.communicationsToDisplay = sortDriverCommunications(angular.copy(communications));
                    $scope.loadingCommunication = false;
                })
                .catch((error) => {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingCommunication = false;
                    $scope.$apply();
                });
        }

        /**
         * Fetch driver's forms
         * @param {String} driverId
         */
        function fetchDriverForms(driverId) {
            $scope
                .angRequestGetDispatchFormsForDriver(driverId)
                .then((formsData) => {
                    const forms = formsData.data.forms.map((form) => ({
                        ...form,
                        // For sorting and display
                        student: {
                            firstName: form.student?.firstName ?? '',
                            lastName: form.student?.lastName ?? '',
                        },
                        institutions: form.student?.institutions ? form.student.institutions[0].name : '',
                        busNumber: form.trip?.route.bus.number ?? '',
                        validatedAt: form.validatedAt ?? '',
                    }));

                    const formsUnread = forms.filter((element) => element.read === false);
                    const formsRead = forms.filter((element) => element.read == null || element.read === true);
                    const formsUnreadFirst = formsUnread.concat(formsRead);
                    $scope.disciplinaryForms = sortDriverForms(
                        [...formsUnreadFirst.filter((form) => form.type === 'disciplinary')],
                        'createdAt',
                        false
                    ); // default sorting for disciplinary forms
                    $scope.photosForms = formsUnreadFirst.filter((form) => form.type === 'photos');

                    $scope.loadingForms = false;
                })
                .catch((error) => {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingForms = false;
                    $scope.$apply();
                });
        }

        // SORT FUNCTIONS ========================================================================================= //

        // SORT DRIVER BUS NUMBER ASSIGNMENTS ========================================================================================= //
        /**
         * Defines which fields the busNumberAssignments array should be sorted by
         * Then sorts a copy of $scope.busNumberAssignments
         * Then loads sorted bus number assignments into $scope.busNumberAssignments
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortDriverBusNumberAssignmentsByFields = function (orderBy) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;

            if (orderBy) {
                if ($scope.busNumberAssignmentsOrderType.orderBy === orderBy) {
                    $scope.busNumberAssignmentsOrderType = {
                        orderBy: orderBy,
                        ascending: !$scope.busNumberAssignmentsOrderType.ascending,
                    };
                } else {
                    $scope.busNumberAssignmentsOrderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }

            const sortedBusNumberAssignmentsRoutes = sortDriverBusNumberAssignments([...$scope.busNumberAssignments]);

            $scope.busNumberAssignments = sortedBusNumberAssignmentsRoutes;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        // SORT DRIVER ROUTES ========================================================================================= //
        /**
         * Defines which fields the driverRoutesToDisplay array should be sorted by
         * Then sorts a copy of $scope.driverRoutesToDisplay
         * Then loads sorted drivers into $scope.driverRoutesToDisplay
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortDriverRoutesByFields = function (orderBy) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;
            if (orderBy) {
                if ($scope.routesOrderType.orderBy === orderBy) {
                    $scope.routesOrderType = {
                        orderBy: orderBy,
                        ascending: !$scope.routesOrderType.ascending,
                    };
                } else {
                    $scope.routesOrderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedDriverRoutes = sortDriverRoutes([...$scope.routesToDisplay]);
            $scope.routesToDisplay = sortedDriverRoutes;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /**
         * Sort the busNumber assignments
         * @param {Array} busNumberAssignments
         * @return {Array} sorted array
         */
        function sortDriverBusNumberAssignments(busNumberAssignments) {
            return sortingTools.sortBusNumberAssignments(
                busNumberAssignments,
                $scope.busNumberAssignmentsOrderType.orderBy,
                $scope.busNumberAssignmentsOrderType.ascending
            );
        }
        /**
         * Sort the driver routes
         * @param {Array} routes
         * @return {Array} sorted array
         */
        function sortDriverRoutes(routes) {
            return sortingTools.sortDriverRoutes(routes, $scope.routesOrderType.orderBy, $scope.routesOrderType.ascending);
        }

        // SORT DRIVER TRIP NOTES ========================================================================================= //
        /**
         * Defines which fields the tripNotesToDisplay array should be sorted by
         * Then sorts a copy of $scope.tripNotesToDisplay
         * Then loads sorted drivers into $scope.tripNotesToDisplay
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortDriverTripNotesByFields = function (orderBy) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;
            if (orderBy) {
                if ($scope.tripNotesOrderType.orderBy === orderBy) {
                    $scope.tripNotesOrderType = {
                        orderBy: orderBy,
                        ascending: !$scope.tripNotesOrderType.ascending,
                    };
                } else {
                    $scope.tripNotesOrderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedDriverNotes = sortDriverTripNotes([...$scope.tripNotesToDisplay]);
            $scope.tripNotesToDisplay = sortedDriverNotes;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /**
         * Sort driver notes
         * @param {Array} notes
         * @return {Array} - sorted array
         */
        function sortDriverTripNotes(notes) {
            return sortingTools.sortDriverTripNotes(notes, $scope.tripNotesOrderType.orderBy, $scope.tripNotesOrderType.ascending);
        }

        // SORT DRIVER COMMUNICATIONS ========================================================================================= //

        /**
         * Defines which fields the communicationsToDisplay array should be sorted by
         * Then sorts a copy of $scope.communicationsToDisplay
         * Then loads sorted drivers into $scope.communicationsToDisplay
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortDriverCommunicationsByFields = function (orderBy) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;
            if (orderBy) {
                if ($scope.communicationsOrderType.orderBy === orderBy) {
                    $scope.communicationsOrderType = {
                        orderBy: orderBy,
                        ascending: !$scope.communicationsOrderType.ascending,
                    };
                } else {
                    $scope.communicationsOrderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedCommunications = sortDriverCommunications([...$scope.communicationsToDisplay]);
            $scope.communicationsToDisplay = sortedCommunications;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /**
         * Sort driver communications
         * @param {Array} communications
         * @return {Array} - sorted array
         */
        function sortDriverCommunications(communications) {
            return sortingTools.sortDriverCommunications(
                communications,
                $scope.communicationsOrderType.orderBy,
                $scope.communicationsOrderType.ascending
            );
        }

        // SORT DRIVER FORMS ========================================================================================= //

        /**
         * Defines which fields the disciplinaryForms array should be sorted by
         * Then sorts a copy of $scope.disciplinaryForms
         * Then loads sorted forms into $scope.disciplinaryForms
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortDisciplinaryFormsByFields = function (orderBy) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;
            if (orderBy) {
                if ($scope.disciplineOrderType.orderBy === orderBy) {
                    $scope.disciplineOrderType = {
                        orderBy: orderBy,
                        ascending: !$scope.disciplineOrderType.ascending,
                    };
                } else {
                    $scope.disciplineOrderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedForms = sortDriverForms(
                [...$scope.disciplinaryForms],
                $scope.disciplineOrderType.orderBy,
                $scope.disciplineOrderType.ascending
            );

            $scope.disciplinaryForms = sortedForms;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /**
         * Defines which fields the photosForms array should be sorted by
         * Then sorts a copy of $scope.photosForms
         * Then loads sorted forms into $scope.photosForms
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortPhotosFormsByFields = function (orderBy) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;
            if (orderBy) {
                if ($scope.photosOrderType.orderBy === orderBy) {
                    $scope.photosOrderType = {
                        orderBy: orderBy,
                        ascending: !$scope.photosOrderType.ascending,
                    };
                } else {
                    $scope.photosOrderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedForms = sortDriverForms([...$scope.photosForms], $scope.photosOrderType.orderBy, $scope.photosOrderType.ascending);
            $scope.photosForms = sortedForms;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /**
         * Sort driver forms
         * @param {Array} forms
         * @param {string}  orderBy the fields the array should be sorted by
         * @param {boolean} ascending order or not
         * @return {Array} - sorted array
         */
        function sortDriverForms(forms, orderBy, ascending) {
            return sortingTools.sortDispatchMessages(forms, orderBy, ascending);
        }

        /**
         * Formats the data object for the Create BusNumber Assignment API request.
         * @return {Object} - The formatted data object.
         */
        function formatBusNumberAssignmentData() {
            const data = {
                clientId: $scope.clientSelect.selectedClient.id,
                busNumber: $scope.busNumberSelect.searchValue.value,
            };
            return data;
        }

        /**
         * Opens the Add Bus Number Assignment modal after resetting its state.
         * Fetches necessary data.
         */
        $scope.openAddAssignmentModal = function () {
            $scope.isAddBusNumberAssignmentModalDisplayed = true;
            $scope.clientSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedClient: null,
            };
            $scope.busNumberSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedBusNumber: null,
            };
            $scope.showSelectClientError = false;

            // Fetch necessary data
            $scope.loadingClients = true;
            $scope.loadingBusNumbers = true;
            fetchClients().then(() => {
                // If no clients, show error banner
                if ($scope.clients.length === 0) {
                    $scope.isAddBusNumberAssignmentModalDisplayed = false;
                    $scope.showNoClientErrorBanner = true;
                } else {
                    fetchBusNumbers();
                    // Reset busNumber filter
                    $scope.filteredBusNumbers = $scope.busNumbers;
                }
                $scope.$apply();
            });
        };

        $scope.closeAddAssignmentModal = function () {
            $scope.isAddBusNumberAssignmentModalDisplayed = false;
        };

        // Show/Hide error message when a selectedClient changes
        $scope.$watch('clientSelect.selectedClient', function (newValue) {
            if (newValue != null) {
                // Show only buses from selected client
                $scope.filteredBusNumbers = $scope.busNumbers.filter(function (bus) {
                    return bus.client === newValue.name;
                });
            }

            if (newValue === null) {
                $scope.showSelectClientError = true;
            } else {
                $scope.showSelectClientError = false;
            }
        });

        /**
         * Submits the Add Bus Number Assignment form
         * Validates the form, shows warning options not selected, formats the data,
         *          and sends a request to create a BN-assignment.
         * @param {Object} form - The submitted form object.
         */
        $scope.submitAddBusNumberAssignmentForm = async function (form) {
            // Update warning if no option selected user has many clients to choose from
            if ($scope.hasManyClients) {
                $scope.showSelectClientError = $scope.clientSelect?.selectedClient == null;
            } else {
                // If only one client, set it as selected
                $scope.clientSelect.selectedClient = $scope.clients[0];
            }

            if (form.$valid) {
                try {
                    if ($scope.clientSelect?.selectedClient != null && $scope.busNumberSelect?.searchValue != '') {
                        // form correctly filled
                        $scope.loading = true;
                        const data = formatBusNumberAssignmentData();

                        const answer = await $scope.requestCreateBusNumberAssignment('mine', $scope.driverId, data);

                        if (answer.error) {
                            handleError();
                        } else {
                            // No error
                            $scope.loadingDriverBusNumberAssignments = true;
                            fetchDriverBusNumberAssignments($scope.driverId);
                            fetchDriverRoutes($scope.driverId);
                            resetAddBusNumberAssignmentModalState();
                        }
                    }
                } catch (error) {
                    handleError(error);
                }
            }
        };
        /**
         * Handles errors during form submission.
         * @param {Object} error - The error object.
         */
        function handleError(error) {
            $rootScope.$broadcast('request-error-users', error);
            $scope.loading = false;
            $scope.isAddBusNumberAssignmentModalDisplayed = false;
            $scope.$apply();
        }

        /**
         * Closes the add BusNumber assignment modal
         * and Resets its state.
         */
        function resetAddBusNumberAssignmentModalState() {
            $scope.loading = false;
            $scope.isAddBusNumberAssignmentModalDisplayed = false;
            $scope.clientSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedClient: null,
            };

            $scope.busNumberSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedBusNumber: null,
            };
            $scope.showSelectClientError = false;
            $scope.$apply();
        }

        /**
         * Opens a modal asking the user if they are sure to remove the link between the specified driver/route.
         * @param {Object} route
         */
        $scope.askValidationRemoveDriverFromRoute = (route) => {
            $scope.routeToRemove = route;
            $scope.showRemoveLinkModal = true;
        };

        /**
         * Update routes and displayed routes and make the request to server
         */
        $scope.removeLink = () => {
            $scope.showRemoveLinkModal = false;

            // Call API to remove driver from the route
            $scope.angRequestAdminRemoveDriverFromRoute($scope.routeToRemove.id, $scope.driverId).then(
                function () {
                    // Get new count of unassigned routes
                    $scope.fetchCountOfUnassignedRoutes();

                    fetchDriverRoutes($scope.driverId);
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        /**
         * Opens a modal asking the user if they are sure to remove the bus number assignment.
         * @param {Object} busNumberAssignment
         * @param {Object} busNumberAssignment.id
         * @param {Object} busNumberAssignment.bus
         * @param {Object} busNumberAssignment.bus.number
         * @param {Object} busNumberAssignment.client.name
         * @param {Object} busNumberAssignment.client.fullName
         * @param {Object} busNumberAssignment.routeCount
         */
        $scope.askValidationRemoveBusNumberAssignment = (busNumberAssignment) => {
            $scope.busNumberAssignmentToRemove = busNumberAssignment;
            $scope.showRemoveBusNumberAssignment = true;
        };

        /**
         * Remove bus number assignment and update the displayed bus number assignments
         */
        $scope.removeBusNumberAssignment = () => {
            $scope.angRequestRemoveDriverBusNumberAssignment('mine', $scope.driverId, $scope.busNumberAssignmentToRemove.id).then(
                function () {
                    $scope.busNumberAssignments = $scope.busNumberAssignments.filter((busNumberAssignment) => {
                        return busNumberAssignment.id !== $scope.busNumberAssignmentToRemove.id;
                    });
                    $scope.busNumberAssignments = sortDriverBusNumberAssignments([...$scope.busNumberAssignments]);
                    fetchDriverRoutes($scope.driverId);
                    $scope.showRemoveBusNumberAssignment = false;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.showRemoveBusNumberAssignment = false;
                }
            );
        };
    },
]);
