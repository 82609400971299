/* eslint-disable require-jsdoc */
angular.module('mTransportApp').controller('GlobalMapController', [
    '$scope',
    '$rootScope',
    '$timeout',
    '$translate',
    'NgMap',
    function ($scope, $rootScope, $timeout, $translate, NgMap) {
        /* =======================================================================================================================
     INITIALIZATION
  =======================================================================================================================  */
        function initializeGlobalMapVariables() {
            $scope.loading = true;
            $scope.refreshing = false;
            $scope.displayFilterText = true;
            $scope.nothingToShow = false;
            $scope.trips = [];
            $scope.tripInProgressCount = 0;
            $scope.montrealCoordinates = new google.maps.LatLng(45.5336498, -73.6233209);
            $scope.singleTripView = ['trips/trip', 'routes/route', 'routes/:id/radius'].includes($scope.currentView);
            $scope.colors = [
                '#E74C3C', // Red (0)
                '#E67E22',
                '#F1C40F', // Yellow (2)
                '#2ECC71',
                '#1ABC9C',
                '#3498DB',
                '#9B59B6',
                '#C0392B',
                '#D35400',
                '#F39C12',
                '#27AE60', // Green (10)
                '#16A085',
                '#2980B9',
                '#8E44AD',
            ];
            $scope.squarePath = 'M -1,-1 1,-1 1,1 -1,1 z';
            $scope.traces = [];
            $scope.markers = [];
            $scope.lines = [];
            $scope.speedDots = [];
            $scope.showingSpeedData = false;
            $scope.showingWaypoints = false;
            $scope.tripStops = [];
            $scope.radiuses = [];
            $scope.infoWindow;
            $scope.ZOOM_PIVOT = 10;
            $scope.currentZoomLevel = 9;
            $scope.map = null;
            $scope.aggIndex = 20;
            $scope.icons = {};
            $scope.userLocation = null;
            $scope.iconSizes = {
                xs: 10,
                s: 10,
                m: 20,
                l: 26,
                xl: 40,
            };
            $scope.formLocationMarker = null;
            $scope.formLocation = null;
            $scope.deviceLocationMarkers = [];
            fetchBusIcons();

            $scope.replayTime = null;
            $scope.replayStartTime = null;
            $scope.replayEndTime = null;
            $scope.replayBusMarkers = [];
            $scope.replayRenders = [];
            $scope.startReplayEngine();
        }

        function initializeMap(mapId) {
            return NgMap.getMap({ id: mapId })
                .then(function (map) {
                    $scope.map = map;
                    // Default fallback to Montréal shows if drawing map fails after
                    fallbackSetPosition();
                    return gatherMapData();
                })
                .then(function (promiseResult) {
                    return drawMap(false);
                });
        }

        /**
         *
         * Initialization of map position for the dispatch form details page, calls drawing of the map
         * @param {String} mapId
         * @return {Promise} resolution
         */
        function initializeMapDispatchForm(mapId) {
            return NgMap.getMap({ id: mapId })
                .then(function (map) {
                    $scope.map = map;
                    // Default fallback to Montréal shows if drawing map fails after
                    fallbackSetPosition();
                })
                .then(function (promiseResult) {
                    return drawMap(false, true);
                });
        }

        // Stop watching user position on controller's destroy event
        $scope.$on('$destroy', function () {
            navigator.geolocation.clearWatch($scope.watchId);
        });

        /* =======================================================================================================================
     REFRESH
  =======================================================================================================================  */
        function prepareScopeForRefresh() {
            $scope.refreshing = true;
            $scope.trips = [];
            $scope.traces = [];
            $scope.markers = [];
            $scope.lines = [];

            if ($scope.currentView === 'routes/:id/radius') {
                // before deleting radiuses, remove them from map
                for (const radius of $scope.radiuses) {
                    radius.setMap(null);
                }
                $scope.radiuses = [];
            }

            // before deleting speedDots, remove them from map
            for (const dot of $scope.speedDots) {
                dot.setMap(null);
            }
            $scope.speedDots = [];
            $scope.tripStops = [];
        }

        function refreshMap() {
            gatherMapData().then(function (promiseResult) {
                return drawMap(true);
            });
        }

        function hideTrips() {
            hideAllMarkers();
            hideAllTraces();
            // invalidate InfoBox?
        }

        function showTrips() {
            showAllMarkers();
            showAllTraces();
        }

        function showAllMarkers() {
            if ($scope.map) {
                for (const marker of $scope.markers) {
                    marker.setMap($scope.map);
                }
            }
        }

        function hideAllMarkers() {
            for (const marker of $scope.markers) {
                marker.setMap(null);
            }
        }

        function showAllTraces() {
            if ($scope.map) {
                for (const polyline of $scope.lines) {
                    polyline.setMap($scope.map);
                }
            }
        }

        function hideAllTraces() {
            for (const polyline of $scope.lines) {
                polyline.setMap(null);
            }
        }

        /**
         * Remove all traces from map
         */
        function removeAllTraces() {
            hideAllTraces();
            $scope.lines = [];
        }

        function showSpeedData() {
            if ($scope.map) {
                for (const speedDot of $scope.speedDots) {
                    speedDot.setMap($scope.map);
                }
                $scope.showingSpeedData = true;
            }
        }

        function hideSpeedData() {
            for (const speedDot of $scope.speedDots) {
                speedDot.setMap(null);
            }
            $scope.showingSpeedData = false;
        }

        function showWaypointsData() {
            if ($scope.map) {
                for (const marker of $scope.markers) {
                    if (marker.class === 'waypointMarker') marker.setMap($scope.map);
                }
            }
        }

        function hideWaypointsData() {
            for (const marker of $scope.markers) {
                if (marker.class === 'waypointMarker') marker.setMap(null);
            }
        }

        /* =======================================================================================================================
     TRIPS LOGIC, DATA AND ANALYSIS
  =======================================================================================================================  */
        function gatherMapData() {
            return new Promise(function (resolve) {
                findAllMarkersToDraw();
                findAllTracesToDraw();
                calculateReplayStartAndEndTime();
                createAllStopMarkers();
                resolve();
            });
        }

        /**
         * Determinates where we should place planned(white filled) and "real" (red filled) stops markers on the map (doesn't create them on the map yet)
         * based on stops coordinates in stops array and on locations coordinates in locations array
         */
        function findAllMarkersToDraw() {
            // Use requestTVTrips request for mainMap
            if ($scope.currentView === 'dashboard/map') {
                let dashboardColors;
                if ($scope.inProgressTripsOk.length > 0) {
                    $scope.inProgressTripsOk.forEach((tripBusOk) => {
                        dashboardColors = getColorFromIndex(10);
                        if (tripBusOk.lastLocation) {
                            const busCoordinate = {
                                latitude: tripBusOk.lastLocation.latitude,
                                longitude: tripBusOk.lastLocation.longitude,
                                stopSequenceNumber: tripBusOk.currentStopSequenceNumber,
                            };
                            createBusMarker(busCoordinate, tripBusOk.lastLocation.timestamp, dashboardColors, tripBusOk.id, tripBusOk);
                        }
                    });
                }

                if ($scope.inProgressTripsModerated.length > 0) {
                    $scope.inProgressTripsModerated.forEach((tripBusModerated) => {
                        dashboardColors = getColorFromIndex(2);
                        if (tripBusModerated.lastLocation) {
                            const busCoordinate = {
                                latitude: tripBusModerated.lastLocation.latitude,
                                longitude: tripBusModerated.lastLocation.longitude,
                                stopSequenceNumber: tripBusModerated.currentStopSequenceNumber,
                            };
                            createBusMarker(
                                busCoordinate,
                                tripBusModerated.lastLocation.timestamp,
                                dashboardColors,
                                tripBusModerated.id,
                                tripBusModerated
                            );
                        }
                    });
                }

                if ($scope.inProgressTripsCritical.length > 0) {
                    $scope.inProgressTripsCritical.forEach((tripBusCritical) => {
                        dashboardColors = getColorFromIndex(0);
                        if (tripBusCritical.lastLocation) {
                            const busCoordinate = {
                                latitude: tripBusCritical.lastLocation.latitude,
                                longitude: tripBusCritical.lastLocation.longitude,
                                stopSequenceNumber: tripBusCritical.currentStopSequenceNumber,
                            };
                            createBusMarker(
                                busCoordinate,
                                tripBusCritical.lastLocation.timestamp,
                                dashboardColors,
                                tripBusCritical.id,
                                tripBusCritical
                            );
                        }
                    });
                }
                $scope.tripInProgressCount =
                    $scope.inProgressTripsCritical.length + $scope.inProgressTripsModerated.length + $scope.inProgressTripsOk.length;
            }

            // Use getTripDetails request for others maps
            if ($scope.currentView !== 'dashboard/map') {
                const trips = $scope.trips;

                for (let i = 0; i < trips.length; i++) {
                    const trip = trips[i];
                    const tripStatus = trip.status;
                    const tripId = trip.id;

                    // Get markers for planned stops, if we have location data for them.
                    const stops = trip.stops;

                    const color = getColorFromIndex(i);
                    for (const stop of stops) {
                        if (stop.location) {
                            if (stop?.arrivalLocation != null) {
                                const stopLocation = {
                                    latitude: parseFloat(stop.arrivalLocation.latitude.toFixed(4)),
                                    longitude: parseFloat(stop.arrivalLocation.longitude.toFixed(4)),
                                };
                                // Check if the planned stop is at the same position as the trip stop.
                                const isPlannedStopAtSamePositionAsTripStop = isSamePositionStops(stopLocation, stop.location);
                                // In case of true, planned stop will not created to show only the trip stop.
                                if (!isPlannedStopAtSamePositionAsTripStop) {
                                    createStop(stop.location, null, color, tripId, trip, stop, true);
                                }
                            } else {
                                createStop(stop.location, null, color, tripId, trip, stop, true);
                            }
                        }
                    }

                    const shouldUseStopArrivalLocation = stops.some((stop) => stop.arrivalLocation != null);
                    if (tripStatus != 'planned') {
                        const locations = filterLocationsFromTrip(trip);

                        // Get markers for trip locations
                        for (let j = 0; j < locations.length; j++) {
                            const location = locations[j];
                            if (location) {
                                const locationCoordinates = {
                                    latitude: location.latitude,
                                    longitude: location.longitude,
                                };
                                const timestamp = location.timestamp;
                                if ($scope.mustCreateSpeedDot) {
                                    // Generate a speed dot for speed view.
                                    createSpeedDot(location);
                                }
                                // See if we need to create a marker at this location.
                                if (j === locations.length - 1) {
                                    // Create a bus marker.
                                    if (trip.status === 'inProgress') {
                                        if (location && $scope.isShowingBus) {
                                            const busCoordinate = {
                                                latitude: location.latitude,
                                                longitude: location.longitude,
                                                stopSequenceNumber: trip.currentStopSequenceNumber,
                                            };
                                            createBusMarker(busCoordinate, timestamp, color, tripId, trip);
                                        }
                                    } else if (trip.status === 'completed' && location) {
                                        // Create a special marker for the end that is not a stop.
                                        const endCoordinate = {
                                            latitude: location.latitude,
                                            longitude: location.longitude,
                                            stopSequenceNumber: trip.currentStopSequenceNumber,
                                        };
                                        // createEndMarker(endCoordinate, timestamp, color, tripId, trip);
                                    }
                                }
                                // See if a stop marker should be put here.
                                if (!shouldUseStopArrivalLocation && location.stopSequenceNumber && trip.stops) {
                                    const stop = findStopBySequenceNumber(trip, location.stopSequenceNumber);
                                    if (stop) {
                                        createStop(locationCoordinates, timestamp, color, tripId, trip, stop, false);
                                    }
                                }
                            }
                        }

                        if (shouldUseStopArrivalLocation) {
                            // Draw completed stops markers
                            for (let j = 0; j <= trip.stops.length - 1; j++) {
                                const stop = findStopBySequenceNumber(trip, stops[j].sequenceNumber);

                                if (stop?.arrivalLocation != null) {
                                    const stopLocation = {
                                        latitude: parseFloat(stop.arrivalLocation.latitude.toFixed(4)),
                                        longitude: parseFloat(stop.arrivalLocation.longitude.toFixed(4)),
                                    };
                                    const isPositionValid = stopLocation.latitude !== 0 || stopLocation.longitude !== 0;
                                    if (isPositionValid) {
                                        const stopArrivalTimestamp = stop.arrivalLocation.timestamp;
                                        createStop(stopLocation, stopArrivalTimestamp, color, tripId, trip, stop, false);
                                    }
                                    // If coordinates are not valid, make an average of the stop before and after.
                                    else if (locations.length > 0) {
                                        const stopArrivalTimestamp = stop.arrivalLocation.timestamp;
                                        // Find out if the first location in the locations array is a stop (does the first location have stopSequenceNumber ?)
                                        const isFirstLocationAStop = stop.sequenceNumber === 1 && locations[0].stopSequenceNumber != null;
                                        // Find out if the last location in the locations array is a stop (does the last location have a stopSequenceNumber ?)
                                        const isLastLocationAStop =
                                            stops.length === stop.sequenceNumber && locations[locations.length - 1].stopSequenceNumber != null;

                                        // If it's not the isFirstLocationAStop, and not isFirstLocationAStop, then we calculate the average coordinates based on the locations array
                                        if (!isFirstLocationAStop && !isLastLocationAStop) {
                                            // If the stop does have an arrivalLocation
                                            if (trip.stops[j].arrivalLocation != null) {
                                                for (let k = 1; k <= trip.locations.length - 1; k++) {
                                                    const location = trip.locations[k];
                                                    if (location.stopSequenceNumber != null) {
                                                        if (trip.stops[j].sequenceNumber === location.stopSequenceNumber) {
                                                            //  Don't make an average if next stop or after stop is not valid.
                                                            let isPositionBeforeAndAfterValid = false;
                                                            if (trip.locations[k + 1] && trip.locations[k - 1]) {
                                                                isPositionBeforeAndAfterValid =
                                                                    (trip.locations[k - 1].latitude !== 0 && trip.locations[k + 1].latitude !== 0) ||
                                                                    (trip.locations[k + 1].longitude !== 0 && trip.locations[k - 1].longitude !== 0);
                                                            }

                                                            if (isPositionBeforeAndAfterValid) {
                                                                const averageLatitude =
                                                                    (trip.locations[k - 1].latitude + trip.locations[k + 1].latitude) / 2;
                                                                const averageLongitude =
                                                                    (trip.locations[k - 1].longitude + trip.locations[k + 1].longitude) / 2;
                                                                const averageStopLocation = {
                                                                    latitude: parseFloat(averageLatitude.toFixed(4)),
                                                                    longitude: parseFloat(averageLongitude.toFixed(4)),
                                                                };

                                                                createStop(
                                                                    averageStopLocation,
                                                                    stopArrivalTimestamp,
                                                                    color,
                                                                    tripId,
                                                                    trip,
                                                                    stop,
                                                                    false
                                                                );
                                                            } else {
                                                                createStop(stopLocation, stopArrivalTimestamp, color, tripId, trip, stop, false);
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                createStop(stopLocation, stopArrivalTimestamp, color, tripId, trip, stop, false);
                                            }
                                        } else {
                                            createStop(stopLocation, stopArrivalTimestamp, color, tripId, trip, stop, false);
                                        }
                                    } else {
                                        const stopArrivalTimestamp = stop.arrivalLocation.timestamp;
                                        createStop(stopLocation, stopArrivalTimestamp, color, tripId, trip, stop, false);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /**
         * Displaying device locations on the map after creating their markers
         * @return {Promise}
         */
        $scope.displayDeviceLocations = () => {
            return new Promise(function (resolve, reject) {
                $scope.eraseDeviceLocations();

                if ($scope.deviceLocations) {
                    $scope.deviceLocations.forEach((device) => createDeviceLocationMarker(device));
                }

                if ($scope.deviceLocationMarkers) {
                    $scope.deviceLocationMarkers.forEach((marker) => {
                        marker.setMap($scope.map);
                    });
                }

                recenterMap();
                resolve();
            });
        };

        /**
         * Erase device location markers
         */
        $scope.eraseDeviceLocations = () => {
            if ($scope.deviceLocationMarkers) {
                $scope.deviceLocationMarkers.forEach((marker) => marker.setMap(null));
                $scope.deviceLocationMarkers = [];
            }
        };

        /**
         * Merge arrivalLocation and departureLocation in locations. It is usefull to know where the bus is stopped and where it has left.
         * @param {object} locations locations object
         * @param {object} stops stops object
         */
        const mergeLocationsAndStops = (locations, stops) => {
            // find stops that have arrivalLocation and departure location
            const stopsWithArrivalDepartureLocation = stops.filter((stop) => stop.arrivalLocation != null && stop.departureLocation != null);
            stopsWithArrivalDepartureLocation.forEach((location, index) => {
                // if statement to check if a location is a stop and this stop has arrival location
                if (location.sequenceNumber) {
                    // get stop matching stopSequenceNumber
                    const stop = stopsWithArrivalDepartureLocation.find((stop) => stop.sequenceNumber === location.sequenceNumber);
                    if (stop) {
                        if (stop.arrivalLocation.latitude != 0 || stop.arrivalLocation.longitude != 0) {
                            const stopArrivalLocation = {
                                latitude: stop.arrivalLocation.latitude,
                                longitude: stop.arrivalLocation.longitude,
                                accuracy: stop.arrivalLocation.accuracy,
                                speed: stop.arrivalLocation.speed,
                                timestamp: stop.arrivalLocation.timestamp,
                                stopSequenceNumber: stop.sequenceNumber,
                                largerTrace: true,
                            };

                            const stopDepartureLocation = {
                                latitude: stop.departureLocation.latitude,
                                longitude: stop.departureLocation.longitude,
                                accuracy: stop.departureLocation.accuracy,
                                speed: stop.departureLocation.speed,
                                timestamp: stop.departureLocation.timestamp,
                                stopDeparture: true,
                            };
                            // Replace value of locations with theses values
                            locations.splice(index, 0, stopArrivalLocation, stopDepartureLocation);
                        }
                    }
                }
            });

            let prevStopHasNoDeparture = false;
            stops.forEach((stop, index) => {
                // if index is out of bound, return
                if (index > locations?.length - 1) {
                    return;
                }
                // if previous stop has no departure, the next line is a larger trace
                if (prevStopHasNoDeparture) {
                    locations[index].largerTrace = true;
                }

                if (stop.arrivalLocation == null) {
                    // skip stop
                    return;
                }

                prevStopHasNoDeparture = stop.departureLocation == null;

                // find stops that have only arrivalLocation and no departureLocation
                if (stop.departureLocation == null) {
                    const stopArrivalLocation = {
                        latitude: stop.arrivalLocation.latitude,
                        longitude: stop.arrivalLocation.longitude,
                        accuracy: stop.arrivalLocation.accuracy,
                        speed: stop.arrivalLocation.speed,
                        timestamp: stop.arrivalLocation.timestamp,
                        stopSequenceNumber: stop.sequenceNumber,
                        largerTrace: true,
                    };
                    // Replace value of locations with theses values
                    locations.splice(index, 0, stopArrivalLocation);
                }
            });

            return locations;
        };

        /**
         * find traces to draw
         */
        function findAllTracesToDraw() {
            const trips = $scope.trips;
            const ACCEPTABLE_ACCURACY = 500;
            for (let i = 0; i < trips.length; i++) {
                const trip = trips[i];
                const stops = trip.stops;
                const tripId = trip.id;
                const tripStatus = trip.status;
                let beforeFirstStop = true;
                let pastFirstStop = false;
                if (tripStatus != 'planned') {
                    const mergedLocations = mergeLocationsAndStops(trip.locations, stops);
                    const locations = filterLocationsFromMergedLocations(mergedLocations);
                    if (locations.length > 0) {
                        const color = getColorFromIndex(i);
                        let currentTrace = null;
                        let largerTrace = null;
                        let betweenArrivalAndDeparture = false;
                        let distance;
                        const tripTraces = {
                            tripId: tripId,
                            color: color,
                            traces: [],
                        };

                        for (let j = 0; j < locations.length - 1; j++) {
                            const arrivalLocation = locations[j];
                            const arrivalLocationCoordinates = {
                                latitude: parseFloat(arrivalLocation.latitude.toFixed(4)),
                                longitude: parseFloat(arrivalLocation.longitude.toFixed(4)),
                                timestamp: arrivalLocation.timestamp,
                            };
                            const departureLocation = locations[j + 1];
                            const departureLocationCoordinates = {
                                latitude: parseFloat(departureLocation.latitude.toFixed(4)),
                                longitude: parseFloat(departureLocation.longitude.toFixed(4)),
                                timestamp: arrivalLocation.timestamp,
                            };
                            // Draw largerTrace only on single trip map
                            if ($scope.currentView === 'trips/trip') {
                                if (arrivalLocation.largerTrace) {
                                    betweenArrivalAndDeparture = true;
                                }
                                if (arrivalLocation.stopDeparture) {
                                    betweenArrivalAndDeparture = false;
                                }
                                if (betweenArrivalAndDeparture) {
                                    largerTrace = {
                                        beforeFirstStop: beforeFirstStop,
                                        certain: true,
                                        largerTrace: true,
                                        coordinates: [],
                                    };
                                    distance = calculateDistance(arrivalLocationCoordinates, departureLocationCoordinates);
                                    if (distance > 0 && distance <= ACCEPTABLE_ACCURACY) {
                                        largerTrace.coordinates.push(arrivalLocationCoordinates);
                                        largerTrace.coordinates.push(departureLocationCoordinates);
                                        tripTraces.traces.push(largerTrace);
                                    }
                                }
                            }
                            distance = calculateDistance(arrivalLocationCoordinates, departureLocationCoordinates);
                            // Validate if we reached first stop.
                            if (beforeFirstStop && (arrivalLocation.stopSequenceNumber || departureLocation.stopSequenceNumber)) {
                                beforeFirstStop = false;
                            }
                            // Create new trace if we just reached first stop
                            if (!beforeFirstStop && !pastFirstStop) {
                                // Remember we did this.
                                pastFirstStop = true;
                                // Save the current trace if it's not empty.
                                if (currentTrace && currentTrace.coordinates.length > 0) {
                                    tripTraces.traces.push(currentTrace);
                                }
                                // Reset the current trace.
                                currentTrace = null;
                            }
                            if (distance > 0 && distance <= ACCEPTABLE_ACCURACY) {
                                // Add these coordinates to the current trace.
                                // Don't forget to instanciate it if it's not already done.
                                if (!currentTrace) {
                                    currentTrace = {
                                        beforeFirstStop: beforeFirstStop,
                                        certain: true,
                                        largerTrace: false,
                                        coordinates: [],
                                    };
                                }
                                currentTrace.coordinates.push(arrivalLocationCoordinates);
                                currentTrace.coordinates.push(departureLocationCoordinates);
                            } else if (distance > ACCEPTABLE_ACCURACY) {
                                // Save the current trace if it's not empty.
                                if (currentTrace && currentTrace.coordinates.length > 0) {
                                    tripTraces.traces.push(currentTrace);
                                }

                                // Create and save an uncertain trace
                                const uncertainTrace = {
                                    beforeFirstStop: beforeFirstStop,
                                    certain: false,
                                    largerTrace: false,
                                    coordinates: [],
                                };
                                uncertainTrace.coordinates.push(arrivalLocationCoordinates);
                                uncertainTrace.coordinates.push(departureLocationCoordinates);
                                tripTraces.traces.push(uncertainTrace);

                                // Reset the current trace.
                                currentTrace = null;
                            }
                        }
                        // Save the current trace if it's not empty.
                        if (currentTrace && currentTrace.coordinates.length > 0) {
                            tripTraces.traces.push(currentTrace);
                        }
                        $scope.traces.push(tripTraces);
                    }
                }
            }
        }

        /**
         * Filter locations from trip
         * @param {object} trip trip object
         * @return {object} locations
         */
        function filterLocationsFromTrip(trip) {
            const ACCEPTABLE_ACCURACY = 250;
            let locations = [];
            for (const locationIndex in trip.locations) {
                const location = trip.locations[locationIndex];
                const isAccuracyOk = location.accuracy != null && location.accuracy <= ACCEPTABLE_ACCURACY;
                const isSpeedOk = location.speed != null && location.speed >= 0;
                const isStop = location.stopSequenceNumber != null;
                if (isStop || (isAccuracyOk && isSpeedOk)) {
                    location.latitude = parseFloat(location.latitude.toFixed(4));
                    location.longitude = parseFloat(location.longitude.toFixed(4));
                    locations.push(location);
                }
            }
            locations = locations.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

            // Skip first and last locations to prevent out of bound exceptions
            for (let i = 1; i < locations.length - 1; i++) {
                const location = locations[i];
                const ACCEPTABLE_ACCURACY = 200;
                const isPositionValid = location.latitude !== 0 && location.longitude !== 0;
                // Check if the stop before and after is valid, if not keep position.
                const isPositionBeforeAndAfterValid =
                    (locations[i - 1].latitude !== 0 && locations[i + 1].latitude !== 0) ||
                    (locations[i + 1].longitude !== 0 && locations[i - 1].longitude !== 0);
                if (isPositionBeforeAndAfterValid) {
                    // When stopSequenceNumber doesn't have position valid, make an average of coordinates (location before and next)
                    if (location.stopSequenceNumber != null && !isPositionValid) {
                        const previousLocation = locations[i - 1];
                        const nextLocation = locations[i + 1];
                        const averageLatitude = (previousLocation.latitude + nextLocation.latitude) / 2;
                        const averageLongitude = (previousLocation.longitude + nextLocation.longitude) / 2;
                        locations[i].latitude = parseFloat(averageLatitude.toFixed(4));
                        locations[i].longitude = parseFloat(averageLongitude.toFixed(4));
                    }
                    // When stopSequenceNumber doesn't have acceptable accuracy, make an average of coordinates (location before and next)
                    if (location.stopSequenceNumber != null && location.accuracy >= ACCEPTABLE_ACCURACY) {
                        const previousLocation = locations[i - 1];
                        const nextLocation = locations[i + 1];
                        const averageLatitude = (previousLocation.latitude + nextLocation.latitude) / 2;
                        const averageLongitude = (previousLocation.longitude + nextLocation.longitude) / 2;
                        locations[i].latitude = parseFloat(averageLatitude.toFixed(4));
                        locations[i].longitude = parseFloat(averageLongitude.toFixed(4));
                    }
                }
            }
            return locations;
        }

        /**
         * Filter locations with arrivalLocation and departureLocation values.
         * @param {object} mergedLocations mergedLocations object
         * @return {object} locations
         */
        function filterLocationsFromMergedLocations(mergedLocations) {
            const ACCEPTABLE_ACCURACY = 250;
            let locations = [];
            for (const locationIndex in mergedLocations) {
                const location = mergedLocations[locationIndex];
                const isPositionValid = location.latitude !== 0 && location.longitude !== 0;
                const isAccuracyOk = location.accuracy != null && location.accuracy <= ACCEPTABLE_ACCURACY;
                const isSpeedOk = location.speed != null && location.speed >= 0;
                const isStop = location.stopSequenceNumber != null;
                const stopDeparture = location.stopDeparture;

                if (isPositionValid && (isStop || (isAccuracyOk && isSpeedOk) || stopDeparture)) {
                    location.latitude = parseFloat(location.latitude.toFixed(4));
                    location.longitude = parseFloat(location.longitude.toFixed(4));
                    locations.push(location);
                }
            }
            locations = locations.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

            // Skip first and last locations to prevent out of bound exceptions
            for (let i = 1; i < locations.length - 1; i++) {
                const location = locations[i];
                const ACCEPTABLE_ACCURACY = 200;
                const isPositionValid = location.latitude !== 0 && location.longitude !== 0;
                // Check if the stop before and after is valid, if not keep position.
                const isPositionBeforeAndAfterValid =
                    (locations[i - 1].latitude !== 0 && locations[i + 1].latitude !== 0) ||
                    (locations[i + 1].longitude !== 0 && locations[i - 1].longitude !== 0);
                if (isPositionBeforeAndAfterValid) {
                    // When stopSequenceNumber doesn't have position valid, make an average of coordinates (location before and next)
                    if (location.stopSequenceNumber != null && !isPositionValid) {
                        const previousLocation = locations[i - 1];
                        const nextLocation = locations[i + 1];
                        const averageLatitude = (previousLocation.latitude + nextLocation.latitude) / 2;
                        const averageLongitude = (previousLocation.longitude + nextLocation.longitude) / 2;
                        locations[i].latitude = parseFloat(averageLatitude.toFixed(4));
                        locations[i].longitude = parseFloat(averageLongitude.toFixed(4));
                    }
                    // When stopSequenceNumber doesn't have acceptable accuracy, make an average of coordinates (location before and next)
                    if (location.stopSequenceNumber != null && location.accuracy >= ACCEPTABLE_ACCURACY) {
                        const previousLocation = locations[i - 1];
                        const nextLocation = locations[i + 1];
                        const averageLatitude = (previousLocation.latitude + nextLocation.latitude) / 2;
                        const averageLongitude = (previousLocation.longitude + nextLocation.longitude) / 2;
                        locations[i].latitude = parseFloat(averageLatitude.toFixed(4));
                        locations[i].longitude = parseFloat(averageLongitude.toFixed(4));
                    }
                }
            }
            return locations;
        }

        function findStopBySequenceNumber(trip, stopSequenceNumber) {
            return trip.stops.find((stop) => stop.sequenceNumber === stopSequenceNumber);
        }

        function getStopNameForATrip(currentStopSequenceNumber, stops) {
            if (currentStopSequenceNumber === null || stops === null || stops.length < 1) {
                return null;
            }
            const numberLastStop = Math.floor(currentStopSequenceNumber);
            for (const stop of stops) {
                if (stop.sequenceNumber === numberLastStop) {
                    return stop.name;
                }
            }
            return null;
        }

        function calculateDistance(departureCoordinates, arrivalCoordinates) {
            const firstPoint = new google.maps.LatLng(departureCoordinates.latitude, departureCoordinates.longitude);
            const secondPoint = new google.maps.LatLng(arrivalCoordinates.latitude, arrivalCoordinates.longitude);
            return google.maps.geometry.spherical.computeDistanceBetween(firstPoint, secondPoint);
        }

        function createAllStopMarkers() {
            $scope.aggIndex = 0;
            for (const key in $scope.tripStops) {
                const stopsAtKeyCoord = $scope.tripStops[key];
                if (stopsAtKeyCoord.length > 1) {
                    createAgreggationMarker(key, stopsAtKeyCoord.length, stopsAtKeyCoord);
                } else if (stopsAtKeyCoord.length === 1) {
                    const tripStop = stopsAtKeyCoord[0];
                    createMarker(
                        tripStop.position,
                        tripStop.timestamp,
                        tripStop.markerClass,
                        tripStop.icon,
                        tripStop.zIndex,
                        tripStop.tripId,
                        tripStop.trip,
                        tripStop.color,
                        tripStop.stop,
                        null,
                        tripStop.stopSeq
                    );

                    tripStop.waypoints?.forEach((waypoint) => {
                        createMarker(
                            waypoint.position,
                            waypoint.timestamp,
                            waypoint.markerClass,
                            waypoint.icon,
                            waypoint.zIndex,
                            tripStop.tripId,
                            tripStop.trip,
                            waypoint.color,
                            tripStop.stop,
                            waypoint
                        );
                    });
                }
            }
        }

        /* =======================================================================================================================
     MAP CENTERING, ZOOM AND LOCATION
  =======================================================================================================================  */
        function recenterMap() {
            return new Promise(function (resolve, reject) {
                let bounds;
                if ($scope.markers) {
                    bounds = getMarkersBounds(bounds);
                }
                if ($scope.traces) {
                    bounds = getTracesBounds(bounds);
                }
                if (bounds) {
                    $scope.map.fitBounds(bounds);
                    // If too much zoomed in, zoom out to a more appropriate value.
                    if ($scope.map.getZoom() > 13) {
                        $scope.map.setZoom(13);
                    }
                } else {
                    fallbackSetPosition();
                }
                $scope.redrawMap();
                resolve();
            });
        }

        /**
         * Recentering map for the dispatch form details page
         * @return {Promise}
         */
        function recenterMapDispatchFormLocation() {
            return new Promise(function (resolve, reject) {
                let bounds;
                bounds = new google.maps.LatLngBounds();
                if ($scope.formLocationMarker) {
                    bounds.extend($scope.formLocationMarker.getPosition());
                }
                if (bounds) {
                    $scope.map.fitBounds(bounds);
                    $scope.map.setZoom(15);
                } else {
                    fallbackSetPosition();
                }
                $scope.redrawMap();
                resolve();
            });
        }

        function getMarkersBounds(bounds) {
            if ($scope.markers.length > 0) {
                if (!bounds) {
                    bounds = new google.maps.LatLngBounds();
                    for (const marker in $scope.markers) {
                        bounds.extend($scope.markers[marker].getPosition());
                    }
                }
            }
            if ($scope.deviceLocationMarkers && $scope.deviceLocationMarkers.length > 0) {
                if (!bounds) {
                    bounds = new google.maps.LatLngBounds();
                }
                $scope.deviceLocationMarkers.forEach((marker) => {
                    bounds.extend(marker.getPosition());
                });
            }

            return bounds;
        }

        function getTracesBounds(bounds) {
            if ($scope.traces.length > 0) {
                if (!bounds) {
                    bounds = new google.maps.LatLngBounds();
                }
                for (const tripTrace of $scope.traces) {
                    for (const trace of tripTrace.traces) {
                        for (const coordinate of trace.coordinates) {
                            const position = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
                            bounds.extend(position);
                        }
                    }
                }
            }
            return bounds;
        }

        const centerOnUserLocation = function () {
            if (navigator.geolocation) {
                let zoom = $scope.map.getZoom();
                if (zoom < $scope.ZOOM_PIVOT) {
                    zoom = $scope.ZOOM_PIVOT;
                }
                setMapPosition($scope.userLocation, zoom);
            } else {
                fallbackSetPosition();
            }
        };

        /**
         * Set user position of portal user device in $scope.userPosition
         * To use with navigator.geolocation.watchPosition(setCurrentPosition) or .getCurrentPosition(setCurrentPosition)
         */
        const setUserPosition = function (location) {
            $scope.userPosition = new google.maps.LatLng(location.coords.latitude, location.coords.longitude);
        };

        const fallbackSetPosition = function (error) {
            setMapPosition($scope.montrealCoordinates);
        };

        function setMapPosition(coordinates, zoom = 9) {
            if ($scope.map != null) {
                $scope.map.setCenter(coordinates);
                $scope.map.setZoom(zoom);
            }
        }

        function setMarkerIconForZoom(marker) {
            const zoom = $scope.map.getZoom();
            let iconPath = marker.icon.url != null ? marker.icon.url : '';
            // Figure out what kind of icon we have and how to grow/shrink it.
            if (iconPath.indexOf('bus') < 0 && iconPath.indexOf('berline') < 0) {
                // A square or circle without a vehicule
                // is big
                if (zoom <= $scope.ZOOM_PIVOT) {
                    if (marker.class != 'aggregation' && marker.label == null) {
                        // make small
                        const color = marker.icon.strokeColor === '#FFFFFF' ? marker.icon.fillColor : marker.icon.strokeColor;
                        marker.icon = getOrCreateIcon(
                            zoom,
                            marker.isSchool,
                            marker.isStopTransfer,
                            marker.isSkipped,
                            marker.icon.isFuture,
                            color,
                            's'
                        );
                    }
                } else if (marker.class != 'aggregation' && marker.label == null) {
                    // make big
                    const color = marker.icon.strokeColor === '#FFFFFF' ? marker.icon.fillColor : marker.icon.strokeColor;
                    marker.icon = getOrCreateIcon(zoom, marker.isSchool, marker.isStopTransfer, marker.isSkipped, marker.icon.isFuture, color, 'm');
                }
                if (marker.label != null) {
                    if (marker.label.text.length > 1) {
                        marker.icon.size = google.maps.Size($scope.iconSizes['l'], $scope.iconSizes['l']);
                    }
                }
            } else if (iconPath.indexOf('berline') >= 0 || iconPath.indexOf('bus') >= 0) {
                // A marker containing a vehicule
                if (iconPath.indexOf('-48') >= 0) {
                    // is big
                    if (zoom <= $scope.ZOOM_PIVOT) {
                        // make small
                        iconPath = iconPath.replace('-48', '-24');
                        marker.icon.url = iconPath;
                        marker.icon.size = new google.maps.Size(24, 24);
                        // Anchor must be size divided by two on each axis to center the marker on the position.
                        marker.icon.anchor = new google.maps.Point(12, 12);
                    }
                }
                if (iconPath.indexOf('-24') >= 0) {
                    // is small
                    if (zoom > $scope.ZOOM_PIVOT) {
                        // make big
                        iconPath = iconPath.replace('-24', '-48');
                        marker.icon.url = iconPath;
                        marker.icon.size = new google.maps.Size(48, 48);
                        // Anchor must be size divided by two on each axis to center the marker on the position.
                        marker.icon.anchor = new google.maps.Point(24, 24);
                    }
                }
            }
        }
        const getDotRadiusFromZoom = function (zoom, currentRadius) {
            const zoomToRadiusMap = {
                12: 80,
                13: 50,
                14: 25,
                15: 20,
                16: 16,
                17: 10,
                18: 6,
                19: 4,
                20: 3,
                21: 2,
                22: 1,
            };

            const newRadius = zoomToRadiusMap[zoom] || 80;
            return newRadius === currentRadius ? false : newRadius;
        };

        const setDotsRadius = function (zoom, dots) {
            const radius = getDotRadiusFromZoom(zoom, dots[0].radius);

            if (radius) {
                for (const speedDot of dots) {
                    speedDot.setRadius(radius);
                }
            }
        };

        /**
         * Sets size of Bus labels
         * @param {*} zoom - Current zoom of the map
         * @param {*} markers - All markers on map
         */
        const setBusMarkersSize = function (zoom, markers) {
            markers.forEach((marker) => {
                if (marker.class === 'busMarker') {
                    const busNumber = marker.icon.busNumber;
                    const busModel = marker.icon.busModel;
                    const color = marker.icon.color;

                    const sizeFactor = getBusMarkerSizeFactor(zoom);
                    marker.setIcon(createBusMarkerForGeneralMap(busNumber, busModel, color, sizeFactor));
                }
            });
        };

        $scope.$on('mapInitialized', function (event, evtMap) {
            google.maps.event.addListener(evtMap, 'click', function () {
                if ($scope.infoWindow) {
                    $scope.infoWindow.close();
                }
                if ($scope.currentView !== 'routes/:id/radius' && $scope.radiuses?.length > 0) {
                    for (const radius of $scope.radiuses) {
                        radius.setMap(null);
                    }
                }
            });
            google.maps.event.addListener(evtMap, 'zoom_changed', function () {
                let newZoom;
                let needsResizing;

                if ($scope.map) {
                    newZoom = $scope.map.getZoom();

                    if (!$scope.currentZoomLevel) {
                        $scope.currentZoomLevel = newZoom;
                    }

                    needsResizing =
                        ($scope.currentZoomLevel <= $scope.ZOOM_PIVOT && newZoom > $scope.ZOOM_PIVOT) ||
                        ($scope.currentZoomLevel > $scope.ZOOM_PIVOT && newZoom <= $scope.ZOOM_PIVOT);
                }

                // Resize form location marker
                if ($scope.formLocationMarker) {
                    if (newZoom <= $scope.ZOOM_PIVOT) {
                        $scope.formLocationMarker.icon.scaledSize = new google.maps.Size($scope.iconSizes['l'], $scope.iconSizes['l']);
                        $scope.formLocationMarker.anchor = new google.maps.Size($scope.iconSizes['l'] / 2, $scope.iconSizes['l'] / 2);
                    } else {
                        $scope.formLocationMarker.icon.scaledSize = new google.maps.Size($scope.iconSizes['xl'], $scope.iconSizes['xl']);
                        $scope.formLocationMarker.anchor = new google.maps.Size($scope.iconSizes['xl'] / 2, $scope.iconSizes['xl'] / 2);
                    }
                }

                if ($scope.map && $scope.markers && $scope.markers.length > 0) {
                    // Validate if assets resizing is required.
                    if (needsResizing) {
                        // Delete all traced paths and markers
                        hideTrips();
                        $scope.lines = [];

                        // Update all markers
                        if ($scope.currentView !== 'dashboard/map') {
                            for (const markerIndex in $scope.markers) {
                                const marker = $scope.markers[markerIndex];
                                setMarkerIconForZoom(marker);
                            }
                        }

                        // Draw the new markers and paths
                        drawAllMarkers().then(function (promiseResult) {
                            drawAllTraces().then(function (promiseResult) {
                                hideWaypointsData();
                                $scope.redrawMap();
                                if ($scope.showingSpeed) {
                                    // hideTrips();
                                }
                            });
                        });
                    } else {
                        // Update zoom level.
                        $scope.currentZoomLevel = newZoom;
                    }
                }

                if ($scope.map && $scope.speedDots && $scope.speedDots.length > 0) {
                    setDotsRadius($scope.map.getZoom(), $scope.speedDots);
                }
                if ($scope.map && $scope.markers && $scope.markers.length > 0 && $scope.currentView != 'trips/trip') {
                    setBusMarkersSize($scope.map.getZoom(), $scope.markers);
                }
            });
        });

        /* =======================================================================================================================
     MAP DRAWING
  =======================================================================================================================  */
        function drawMap(refresh, isForDispatchForm = false) {
            if (isForDispatchForm) {
                createDispatchFormLocationMarker()
                    .then(function (promiseResult) {
                        return recenterMapDispatchFormLocation();
                    })
                    .then(function (promiseResult) {
                        return doneDrawingMapDispatchForm();
                    })
                    .then(function (promiseResult) {
                        $timeout(function () {
                            $scope.$apply(function () {
                                $scope.redrawMap();
                            });
                        });
                    });
            } else if (refresh) {
                drawAllTraces()
                    .then(function (promiseResult) {
                        return drawAllMarkers();
                    })
                    .then(function (promiseResult) {
                        return doneRefreshingMap();
                    })
                    .then(function (promiseResult) {
                        $timeout(function () {
                            $scope.$apply(function () {
                                $scope.redrawMap();
                            });
                        });
                    });
            } else {
                drawAllTraces()
                    .then(function () {
                        return drawAllMarkers();
                    })
                    .then(function (promiseResult) {
                        return recenterMap();
                    })
                    .then(function (promiseResult) {
                        return doneDrawingMap();
                    })
                    .then(function (promiseResult) {
                        $timeout(function () {
                            $scope.$apply(function () {
                                $scope.redrawMap();
                            });
                        });
                    });
            }
        }

        function drawAllTraces() {
            return new Promise(function (resolve, reject) {
                drawAllBackgroundTraces();
                drawAllForegroundTraces();
                resolve();
            });
        }

        function drawAllBackgroundTraces() {
            if ($scope.currentView !== 'dashboard/map') {
                for (const tripTrace of $scope.traces) {
                    for (const trace of tripTrace.traces) {
                        createBackgroundPolyline(trace);
                    }
                }
            }
        }

        function drawAllForegroundTraces() {
            if ($scope.currentView !== 'dashboard/map') {
                for (const tripTrace of $scope.traces) {
                    const color = tripTrace.color;
                    for (const trace of tripTrace.traces) {
                        createForegroundPolyline(trace, color);
                    }
                }
            }
        }

        function drawAllMarkers() {
            return new Promise(function (resolve, reject) {
                for (const marker of $scope.markers) {
                    marker.setMap($scope.map);
                }
                resolve();
            });
        }

        function doneDrawingMap() {
            return new Promise(function (resolve, reject) {
                $scope.nothingToShow = !!(
                    ($scope.markers && $scope.markers.length <= 0 && $scope.traces && $scope.traces.length <= 0) ||
                    (!$scope.markers && !$scope.traces)
                );

                const noTripOverlay = $scope.map.customControls.noTripOverlay;
                const loadingOverlay = $scope.map.customControls.loadingOverlay;
                if (!$scope.loading) {
                    loadingOverlay.style.display = 'none';
                    if ($scope.nothingToShow) {
                        noTripOverlay.style.display = 'flex';
                    }
                }
                resolve();
            });
        }

        function firstDisplayedMap() {
            return new Promise(function (resolve, reject) {
                $scope.nothingToShow = !!(
                    ($scope.markers && $scope.markers.length <= 0 && $scope.traces && $scope.traces.length <= 0) ||
                    (!$scope.markers && !$scope.traces)
                );

                if ($scope.map != null) {
                    const noTripOverlay = $scope.map.customControls.noTripOverlay;
                    const loadingOverlay = $scope.map.customControls.loadingOverlay;
                    if (!$scope.loading) {
                        loadingOverlay.style.display = 'none';
                        if ($scope.nothingToShow) {
                            noTripOverlay.style.display = 'flex';
                        }
                    }
                }
                resolve();
            });
        }

        /**
         * If necessary, overlays on map for dispatch form details page (loading or no form location)
         * @return {Promise}
         */
        function doneDrawingMapDispatchForm() {
            return new Promise(function (resolve, reject) {
                const noFormOverlay = $scope.map.customControls.noFormOverlay;
                const loadingOverlay = $scope.map.customControls.loadingOverlay;
                if (!$scope.loading) {
                    loadingOverlay.style.display = 'none';
                    if ($scope.nothingToShow) {
                        noFormOverlay.style.display = 'flex';
                    }
                }
                resolve();
            });
        }

        function doneRefreshingMap() {
            return new Promise(function (resolve, reject) {
                $scope.refreshing = false;
                if (
                    ($scope.markers && $scope.markers.length <= 0 && $scope.traces && $scope.traces.length <= 0) ||
                    (!$scope.markers && !$scope.traces)
                ) {
                    $scope.nothingToShow = true;
                } else {
                    $scope.nothingToShow = false;
                }

                if ($scope.map) {
                    const noTripOverlay = $scope.map.customControls.noTripOverlay;
                    const loadingOverlay = $scope.map.customControls.loadingOverlay;
                    if (!$scope.loading) {
                        loadingOverlay.style.display = 'none';
                        if ($scope.nothingToShow) {
                            noTripOverlay.style.display = 'flex';
                        } else {
                            noTripOverlay.style.display = 'none';
                        }
                    }
                }

                resolve();
            });
        }

        function getColorFromIndex(index) {
            const colorIndex = index - Math.floor(index / $scope.colors.length) * $scope.colors.length;
            const color = $scope.colors[colorIndex];
            return color;
        }

        /**
         * Calculate and set the start, the end time and the current time of the replay
         */
        function calculateReplayStartAndEndTime() {
            let startTimestamp = null;
            let endTimestamp = null;
            $scope.traces.forEach((tripTrace) => {
                tripTrace.traces.forEach((trace) => {
                    trace.coordinates.forEach((coordinate) => {
                        if (startTimestamp == null || new Date(coordinate.timestamp) < startTimestamp) {
                            startTimestamp = new Date(coordinate.timestamp);
                        }
                        if (endTimestamp == null || new Date(coordinate.timestamp) > endTimestamp) {
                            endTimestamp = new Date(coordinate.timestamp);
                        }
                    });
                });
            });

            $scope.replayTime = endTimestamp;
            $scope.replayStartTime = startTimestamp ? new Date(startTimestamp.getTime() - 1) : null;
            $scope.replayEndTime = endTimestamp;
        }

        /**
         * Get the new date that corresponds to the progress
         * @param {number} progress - The progress of the replay, between 0 and 1000
         * @return {Date} The new date that corresponds to the progress
         */
        function getReplayTimeFromProgress(progress) {
            const difference = new Date($scope.replayEndTime.getTime() - $scope.replayStartTime.getTime());
            const time = new Date(difference.getTime() * (progress / 1000) + $scope.replayStartTime.getTime());
            return time;
        }

        /**
         * @param {object} trace
         * @param {boolean} trace.beforeFirstStop
         * @param {boolean} trace.certain
         * @param {boolean} trace.largerTrace
         * @param {object[]} trace.coordinates
         * @param {number} trace.coordinates.latitude
         * @param {number} trace.coordinates.longitude
         * @param {string} trace.coordinates.timestamp
         * @param {string} color
         */
        function createForegroundPolyline(trace, color) {
            const certain = trace.certain;
            const largerTrace = trace.largerTrace;
            const pathBeforeReplayTime = [];
            const pathAfterReplayTime = [];

            for (const coordinate of trace.coordinates) {
                const latLng = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
                if ($scope.replayTime == null || new Date(coordinate.timestamp) <= $scope.replayTime) {
                    pathBeforeReplayTime.push(latLng);
                } else {
                    pathAfterReplayTime.push(latLng);
                }
            }

            const zoom = $scope.map.getZoom();
            let strokeSize;
            let repeat;
            if (zoom <= $scope.ZOOM_PIVOT) {
                strokeSize = 2;
                repeat = '10px';
            } else {
                strokeSize = 4;
                repeat = '20px';
            }

            const opacity = trace.beforeFirstStop ? 0.37 : 1.0;
            // LargerTrace is used to show the difference between bus arriving at the stop marker and bus leaving.
            if (largerTrace) {
                const lineAfter = new google.maps.Polyline({
                    clickable: false,
                    path: pathAfterReplayTime,
                    strokeColor: '#808080',
                    strokeOpacity: opacity,
                    strokeWeight: strokeSize * 2,
                    zIndex: -2000,
                });

                addLineToMap(lineAfter);

                const lineBefore = new google.maps.Polyline({
                    clickable: false,
                    path: pathBeforeReplayTime,
                    strokeColor: getColorFromIndex(0),
                    strokeOpacity: opacity,
                    strokeWeight: strokeSize * 2,
                    zIndex: -2000,
                });

                addLineToMap(lineBefore);
            }
            // Certain trace show where the bus passed on the map
            if (certain) {
                const lineAfter = new google.maps.Polyline({
                    clickable: false,
                    path: pathAfterReplayTime,
                    strokeColor: '#808080',
                    strokeOpacity: opacity,
                    strokeWeight: strokeSize,
                    zIndex: -2000,
                });

                addLineToMap(lineAfter);

                const lineBefore = new google.maps.Polyline({
                    clickable: false,
                    path: pathBeforeReplayTime,
                    strokeColor: getColorFromIndex(0),
                    strokeOpacity: opacity,
                    strokeWeight: strokeSize,
                    zIndex: -2000,
                });

                addLineToMap(lineBefore);
            } else {
                const lineAfter = new google.maps.Polyline({
                    clickable: false,
                    path: pathAfterReplayTime,
                    strokeOpacity: 0,
                    zIndex: -2000,
                    icons: [
                        {
                            icon: {
                                path: 'M 0,-1 0,1',
                                strokeOpacity: opacity,
                                scale: strokeSize,
                                strokeColor: '#808080',
                            },
                            offset: '0',
                            repeat: repeat,
                        },
                    ],
                });
                addLineToMap(lineAfter);

                const lineBefore = new google.maps.Polyline({
                    clickable: false,
                    path: pathBeforeReplayTime,
                    strokeOpacity: 0,
                    zIndex: -2000,
                    icons: [
                        {
                            icon: {
                                path: 'M 0,-1 0,1',
                                strokeOpacity: opacity,
                                scale: strokeSize,
                                strokeColor: color,
                            },
                            offset: '0',
                            repeat: repeat,
                        },
                    ],
                });
                addLineToMap(lineBefore);
            }
        }

        /**
         * @param {object} trace object
         * @param {string} color color for trace
         */
        function createBackgroundPolyline(trace) {
            const path = [];
            const certain = trace.certain;
            const largerTrace = trace.largerTrace;
            const coordinates = trace.coordinates;
            for (const coordinate of coordinates) {
                const latLng = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
                path.push(latLng);
            }

            const zoom = $scope.map.getZoom();
            let strokeSize;
            if (zoom <= $scope.ZOOM_PIVOT) {
                strokeSize = 4;
            } else {
                strokeSize = 8;
            }

            const opacity = trace.beforeFirstStop ? 0.37 : 1.0;
            // LargerTrace is used to show the difference between bus arriving at the stop marker and bus leaving.
            if (largerTrace) {
                const line = new google.maps.Polyline({
                    clickable: false,
                    path: path,
                    strokeColor: 'white',
                    strokeOpacity: opacity,
                    strokeWeight: strokeSize * 2,
                    zIndex: -20000,
                });
                addLineToMap(line);
            }
            // Certain trace show where the bus passed on the map
            if (certain) {
                const line = new google.maps.Polyline({
                    clickable: false,
                    path: path,
                    strokeColor: 'white',
                    strokeOpacity: opacity,
                    strokeWeight: strokeSize,
                    zIndex: -20000,
                });
                addLineToMap(line);
            }
        }

        function addLineToMap(polyline) {
            polyline.setMap($scope.map);
            $scope.lines.push(polyline);
        }

        /* =======================================================================================================================
     MARKERS AND SPEED DOTS CREATION
  =======================================================================================================================  */
        function createStartMarker(coordinates, timestamp, color, tripId, trip) {
            const position = new google.maps.LatLng(coordinates.latitude, coordinates.longitude);
            const markerClass = 'startMarker';
            const colorIndex = $scope.colors.indexOf(color);
            let icon;
            const zIndex = 0;

            createMarker(position, timestamp, markerClass, icon, zIndex, tripId, trip, color);
        }

        /**
         * @param {object} coordinates object including latitude longitude
         * @param {number} timestamp time stamp for icon
         * @param {string} color color for icon
         * @param {string} tripId trip id
         * @param {object} trip trip object
         * @param {object} stop stop object
         * @param {boolean} isFuture trip is future trip or not
         */
        function createStop(coordinates, timestamp, color, tripId, trip, stop, isFuture) {
            const isSchool = stop.institution != null;
            const isStopTransfer = stop.stopTransfer != null;
            // isSkipped can be false or null
            const isStopSkipped = stop.isSkipped === true;
            const {
                preferences: { studentsBoardingRegistrations: isStudentsBoardingRegistrations },
            } = trip;
            const zoom = $scope.map.getZoom();
            let size;

            if ($scope.singleTripView && stop.sequenceNumber != null && stop.sequenceNumber.toString().length > 1) {
                size = 'l';
            } else {
                size = 'm';
            }

            const icon = getOrCreateIcon(zoom, isSchool, isStopTransfer, isStopSkipped, isFuture, color, size);
            const zIndex = isFuture ? -1500 : -100;

            const waypoints = stop.waypoints?.map((waypoint) => {
                const waypointIcon = getOrCreateIcon(zoom, false, false, false, true, color, size);
                return {
                    waypointSeq: waypoint.sequenceNumber,
                    position: new google.maps.LatLng(waypoint.location.latitude, waypoint.location.longitude),
                    name: waypoint.name,
                    time: waypoint.time,
                    markerClass: 'waypointMarker',
                    icon: waypointIcon,
                    zIndex: zIndex,
                    color: color,
                };
            });

            const tripStop = {
                tripId: tripId,
                stopSeq: stop.sequenceNumber,
                // eslint-disable-next-line no-undef
                position: new google.maps.LatLng(coordinates.latitude.toFixed(4), coordinates.longitude.toFixed(4)),
                timestamp: timestamp,
                markerClass: 'stopMarker',
                icon: icon,
                zIndex: zIndex,
                trip: trip,
                color: color,
                stop: stop,
                isSchool: isSchool,
                isStopSkipped: isStopSkipped,
                isStopTransfer: isStopTransfer,
                isStudentsBoardingRegistrations,
                waypoints,
            };

            let exist = false;
            if ($scope.tripStops[tripStop.position] && $scope.tripStops[tripStop.position].length > 0) {
                const stopsAtCoordinates = $scope.tripStops[tripStop.position];
                for (const stopToCompare of stopsAtCoordinates) {
                    if (stopToCompare.tripId === tripStop.tripId && stopToCompare.stopSeq === tripStop.stopSeq) {
                        exist = true;
                    }
                }
            }

            if (!exist) {
                if ($scope.tripStops[tripStop.position] && $scope.tripStops[tripStop.position].length > 0) {
                    $scope.tripStops[tripStop.position].push(tripStop);
                } else {
                    $scope.tripStops[tripStop.position] = [tripStop];
                }
            }
        }

        function createBusMarker(busCoordinate, timestamp, color, tripId, trip, options) {
            const position = new google.maps.LatLng(busCoordinate.latitude, busCoordinate.longitude);
            const stopSequenceNumber = busCoordinate.stopSequenceNumber;
            const busModel = trip.route.bus.model;
            const busNumber = trip.route.bus.number;
            let zIndex = -2000;
            let url;
            let icon;
            let markerClass;
            let stop = null;
            let zoom;
            if ($scope.map) {
                zoom = $scope.map.getZoom();
            }

            if ($scope.currentView !== 'dashboard/map') {
                let anchorPoint;
                let urlSize;
                if (stopSequenceNumber % 1 === 0) {
                    // on stop
                    stop = findStopBySequenceNumber(trip, stopSequenceNumber);
                    let isSchool = false;
                    if (stop.institution) {
                        isSchool = true;
                    }
                    url = getBusOnStopIcon(color, isSchool, busModel);
                    markerClass = 'busOnStopMarker';
                } else {
                    // between stops
                    // Anchor must be size divided by two on each axis to center the marker on the position.
                    if (zoom <= $scope.ZOOM_PIVOT) {
                        urlSize = '-24';
                        anchorPoint = new google.maps.Point(12, 12);
                    } else {
                        urlSize = '-48';
                        anchorPoint = new google.maps.Point(24, 24);
                    }
                    url = './assets/img/markers/vehicule-' + busModel + urlSize + '.png';
                    markerClass = 'busMarker';
                }
                icon = {
                    url: url,
                    anchor: anchorPoint,
                };
            } else {
                markerClass = 'busMarker';
                // Update zIndex to highlight short bus numbers
                for (let i = 2; i < busNumber.length; i++) {
                    zIndex -= 5;
                }

                const sizeFactor = getBusMarkerSizeFactor($scope.currentZoomLevel);
                icon = createBusMarkerForGeneralMap(busNumber, busModel, color, sizeFactor);
            }

            return createMarker(position, timestamp, markerClass, icon, zIndex, tripId, trip, color, stop, null, 0, options);
        }

        /**
         * Computes busMarker size factor based on the zoom level.
         *
         * @param {number} zoom - current zoom level
         * @return {string} the sizeFactor : 'smallest', 'smaller' or ''
         */
        function getBusMarkerSizeFactor(zoom) {
            let sizeFactor = '';
            if (zoom <= 10) {
                sizeFactor = 'smallest';
            } else if (zoom <= 12) {
                sizeFactor = 'smaller';
            }
            return sizeFactor;
        }

        /**
         * Checks if two stops (trip stop and planned stop) have the same position by comparing their coordinates.
         *
         * @param {object} stopTripLocation - Coordinates of the trip stop.
         * @param {object} stopPlannedLocation - Coordinates of the planned stop.
         * @return {boolean} `true` if the locations have the same coordinates, `false` otherwise.
         */
        function isSamePositionStops(stopTripLocation, stopPlannedLocation) {
            const stopTripLocationLatitude = stopTripLocation.latitude.toFixed(4);
            const stopTripLocationLongitude = stopTripLocation.longitude.toFixed(4);
            const stopPlannedLocationLatitude = stopPlannedLocation.latitude.toFixed(4);
            const stopPlannedLocationLongitude = stopPlannedLocation.longitude.toFixed(4);

            return stopTripLocationLatitude === stopPlannedLocationLatitude && stopTripLocationLongitude === stopPlannedLocationLongitude;
        }

        function createBusMarkerForGeneralMap(busNumber, busModel, color, sizeFactor) {
            const config = {
                default: {
                    svgWidth: 100,
                    svgHeight: 55,
                    rectWidth: 95,
                    rectHeight: 50.2,
                    fontSize: 22,
                    busNumberTranslate: '60.59 35.1',
                    vehicleIconSizeFactor: 1,
                    vehicleIconTranslate: '',
                },
                smaller: {
                    svgWidth: 80,
                    svgHeight: 44,
                    rectWidth: 76,
                    rectHeight: 40.16,
                    fontSize: 18.7,
                    busNumberTranslate: '46 28',
                    vehicleIconSizeFactor: 0.8,
                    vehicleIconTranslate: '',
                },
                smallest: {
                    svgWidth: 60,
                    svgHeight: 35.75,
                    rectWidth: 57,
                    rectHeight: 32.63,
                    fontSize: 15.4,
                    busNumberTranslate: '32 24',
                    vehicleIconSizeFactor: 0.55,
                    vehicleIconTranslate: 'translate(0 3)',
                },
            };

            const selectedConfig = config[sizeFactor] || config.default;

            let {
                svgWidth,
                svgHeight,
                rectWidth,
                rectHeight,
                fontSize,
                busNumberTranslate,
                vehicleIconSizeFactor,
                vehicleIconTranslate,
            } = selectedConfig;

            // Ajust width depending of bus characters length
            // 2 is busNumber length minimum allowed
            for (let i = 2; i < busNumber.length; i++) {
                rectWidth *= 1.12;
                svgWidth *= 1.12;
            }

            const rectWidthString = rectWidth.toString();
            const svgWidthString = svgWidth.toString();

            let svg;
            svg = `<svg xmlns="http://www.w3.org/2000/svg" height="${svgHeight}" width="${svgWidthString}">`;
            svg += `<rect x="2" y="2" width="${rectWidthString}" height="${rectHeight}" rx="${20}" ry="${20}" style="fill:#fff; stroke: ${color}; stroke-miterlimit: 10; stroke-width: 4px;"/>`;

            // Apply a transform attribute to bus icon
            svg += `<g transform="`;
            svg += vehicleIconTranslate;
            svg += `scale(${vehicleIconSizeFactor})">${getSVGVehicleIcon(busModel)}</g>`;

            // Add Bus Number to marker
            svg += `<text transform="translate(${busNumberTranslate})" style="fill: ${color}; font-family: monospace; font-size: ${fontSize}px;"><tspan x="0" y="0">${busNumber}</tspan></text></svg>`;

            const icon = {
                url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
                anchor: new google.maps.Point(27.5, 27.5),
                busNumber: busNumber,
                busModel: busModel,
                color: color,
            };

            return icon;
        }

        function createEndMarker(coordinate, timestamp, color, tripId, trip) {
            const position = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
            const markerClass = 'endMarker';
            const colorIndex = $scope.colors.indexOf(color);
            const icon = {};
            const zIndex = 0;
            createMarker(position, timestamp, markerClass, icon, zIndex, tripId, trip, color);
        }

        function getBusOnStopIcon(color, isSchool, busModel) {
            const basePath = './assets/img/markers/';
            const colorPath = color.split('#')[1];
            let iconPath = '';
            const zoom = $scope.map.getZoom();
            let urlSize;
            if (zoom <= $scope.ZOOM_PIVOT) {
                urlSize = '-24';
            } else {
                urlSize = '-48';
            }
            if (isSchool) {
                iconPath = '-square-' + busModel + urlSize + '.png';
            } else {
                iconPath = '-circle-' + busModel + urlSize + '.png';
            }
            const completePath = basePath + colorPath + iconPath;
            return completePath;
        }

        /**
         * Fetch all SVG icons on init
         */
        async function fetchBusIcons() {
            $scope.svgBusIcon = await fetchIcon('/assets/img/markers/bus.svg');
            $scope.svgMinibusIcon = await fetchIcon('/assets/img/markers/minibus.svg');
            $scope.svgBerlineIcon = await fetchIcon('/assets/img/markers/berline.svg');
        }

        /**
         * Fetch icon from url
         * @param url
         */
        async function fetchIcon(url) {
            const response = await fetch(url);
            return await response.text();
        }

        function getSVGVehicleIcon(busModel) {
            let svgIcon;
            switch (busModel) {
                case 'bus':
                    svgIcon = $scope.svgBusIcon;
                    break;
                case 'minibus':
                    svgIcon = $scope.svgMinibusIcon;
                    break;
                case 'berline':
                    svgIcon = $scope.svgBerlineIcon;
                    break;
                default:
                    break;
            }
            return svgIcon;
        }

        function createSpeedDot(location) {
            const speedColors = ['#3D9CE6', '#00AC41', '#DEF209', '#FFF10E', '#FAB007', '#EA3203', '#B02A08'];
            const speedLimits = [5, 30, 40, 50, 90, 110];
            const speed = location.speed;
            const kmh = (speed / 1000) * 60 * 60;
            let colorIndex = -1;
            for (let i = speedLimits.length - 1; i >= 0; i--) {
                if (kmh <= speedLimits[i]) {
                    colorIndex = i;
                } else {
                    break;
                }
            }
            if (colorIndex === -1) colorIndex = 0;
            const color = speedColors[colorIndex];
            const position = new google.maps.LatLng(location.latitude, location.longitude);
            const zoom = $scope.map.getZoom();
            let radius = 50;
            if (zoom > 15 && zoom <= 17) {
                radius = 10;
            } else if (zoom > 17) {
                radius = 5;
            } else if (zoom <= 15 && zoom > 13) {
                radius = 25;
            } else if (zoom <= 13 && zoom > 11) {
                radius = 50;
            } else {
                radius = 100;
            }

            const dot = new google.maps.Circle({
                fillColor: color,
                fillOpacity: 1,
                strokeWeight: 0.2,
                strokeOpacity: 0.5,
                center: position,
                radius: radius,
                map: null,
                zIndex: 999999,
            });

            dot.addListener('click', function () {
                const speedString = $scope.toChosenUnit(kmh) + '/h';
                const timestamp = moment(location.timestamp).format('LL LTS');

                const content = speedString + '<br>' + timestamp;

                const newSpeedIndicator = new google.maps.InfoWindow({
                    content: content,
                    position: position,
                });

                if ($scope.speedIndicator) {
                    if ($scope.speedIndicator.position === newSpeedIndicator.position && $scope.speedIndicator.getMap() != null) {
                        const zoom = $scope.map.getZoom() + 2;
                        setMapPosition(position, zoom);
                    } else {
                        $scope.speedIndicator.close();
                        $scope.speedIndicator = newSpeedIndicator;
                        $scope.speedIndicator.open($scope.map);
                    }
                } else {
                    $scope.speedIndicator = newSpeedIndicator;
                    $scope.speedIndicator.open($scope.map);
                }
            });

            $scope.speedDots.push(dot);
        }

        /**
         * create stop icons
         * @param {Number} zoom map zoom
         * @param {Boolean} isSchool a stop is stop or not
         * @param {Boolean} isStopTransfer a stop is stop transfer or not
         * @param {Boolean} isStopSkipped a stop is stop skipped or not
         * @param {String} color icon color
         * @param {Number} width icon width
         * @param {Number} size icon size
         * @param {String} strokeColor stoke color for the icon
         * @param {String} fillColor icon color
         * @return {String}
         */
        function createIconUrl(zoom, isSchool, isStopTransfer, isStopSkipped, color, width, size, strokeColor, fillColor) {
            const colorTextIndex = color.replace('#', '');
            // eslint-disable-next-line no-undef
            const c = document.getElementById('paint_icon');
            const ctx = c.getContext('2d');
            const strokeWidth = Math.floor(width / 10);
            let hexagonSide = 0;

            const hexagonSize = 10;
            const hexagonX = 10.5;
            const hexagonY = 11;

            // Clear canvas
            ctx.clearRect(0, 0, c.width, c.height);

            // Resize canvas to avoid cropped icons
            c.width = width + strokeWidth;
            c.height = width + strokeWidth;

            ctx.beginPath();
            ctx.lineWidth = strokeWidth;
            ctx.strokeStyle = strokeColor;
            ctx.fillStyle = fillColor;

            if (isStopTransfer) {
                // start at strokeWidth (as stroke width isnt considered in starting point nor fill)
                ctx.moveTo(hexagonX + hexagonSize * Math.cos(0), hexagonY + hexagonSize * Math.sin(0));
                for (hexagonSide; hexagonSide < 7; hexagonSide++) {
                    ctx.lineTo(
                        hexagonX + hexagonSize * Math.cos((hexagonSide * 2 * Math.PI) / 6),
                        hexagonY + hexagonSize * Math.sin((hexagonSide * 2 * Math.PI) / 6)
                    );
                }
                // Schools are square
            } else if (isSchool) {
                // Skipped stops are crossed
                if (isStopSkipped && strokeColor == '#FFFFFF') {
                    ctx.lineWidth = 3;
                    ctx.rect(strokeWidth, strokeWidth, width - strokeWidth, width - strokeWidth);
                    ctx.stroke();
                    ctx.fill();
                    ctx.beginPath();
                    ctx.lineWidth = 3;
                    ctx.moveTo(2, 2);
                    ctx.lineTo(30, 30);
                } else {
                    ctx.rect(strokeWidth, strokeWidth, width - strokeWidth, width - strokeWidth);
                }

                // Skipped stops are crossed
            } else if (isStopSkipped && strokeColor == '#FFFFFF') {
                ctx.lineWidth = 3;
                ctx.arc(c.width / 2, c.width / 2, c.width / 2 - strokeWidth, 0, 2 * Math.PI);
                ctx.stroke();
                ctx.fill();
                ctx.beginPath();
                ctx.lineWidth = 4;
                ctx.moveTo(5, 5);
                ctx.lineTo(20, 20);
            } else {
                ctx.arc(c.width / 2, c.width / 2, c.width / 2 - strokeWidth, 0, 2 * Math.PI);
            }

            ctx.closePath();
            ctx.fill();
            ctx.stroke();

            // Create indexes in global icon obj if it doesnt exist
            if (typeof $scope.icons[colorTextIndex] == 'undefined') {
                $scope.icons[colorTextIndex] = {};
            }
            if (typeof $scope.icons[colorTextIndex][status] == 'undefined') {
                $scope.icons[colorTextIndex][status] = {};
            }
            $scope.icons[colorTextIndex][status][size] = c.toDataURL();
            return c.toDataURL();
        }

        /**
         * create  icon object
         * @param {Number} zoom map zoom
         * @param {Boolean} isSchool a stop is stop or not
         * @param {Boolean} isStopTransfer a stop is stop transfer or not
         * @param {Boolean} isStopSkipped a stop is stop skipped or not
         * @param {String} isFuture ia stop is future stop or not
         * @param {Number} color icon width
         * @param {Number} size icon size
         * @return {Object}
         */
        function getOrCreateIcon(zoom, isSchool, isStopTransfer, isStopSkipped, isFuture, color, size) {
            const icon = {
                isSchool: isSchool,
                isStopTransfer: isStopTransfer,
                isStopSkipped: isStopSkipped,
                isFuture: isFuture,
                strokeOpacity: 1,
                fillOpacity: 1,
            };

            let width = $scope.iconSizes[size];

            // Set filled or empty svg based on whether or not stop is in the future
            if (isFuture) {
                icon.strokeColor = color;
                icon.fillColor = '#FFFFFF';
            } else {
                // If border is white, make fill larger to avoid icon looking smaller
                width += Math.floor(width / 10);
                icon.strokeColor = '#FFFFFF';
                icon.fillColor = color;
            }

            const colorTextIndex = color.replace('#', '');
            let status = isFuture ? 'future' : 'past';
            status += isSchool ? '_school' : '';

            if (
                typeof $scope.icons[colorTextIndex] == 'undefined' ||
                typeof $scope.icons[colorTextIndex][status] == 'undefined' ||
                typeof $scope.icons[colorTextIndex][status][size] == 'undefined'
            ) {
                icon.url = createIconUrl(zoom, isSchool, isStopTransfer, isStopSkipped, color, width, size, icon.strokeColor, icon.fillColor);
            } else {
                icon.url = $scope.icons[colorTextIndex][status][size];
            }

            // Increase icon total width by strokes width to avoid cropped shapes
            width += Math.floor(width / 10);
            // eslint-disable-next-line no-undef
            icon.size = new google.maps.Size(width, width);
            // Center icon and labels
            // eslint-disable-next-line no-undef
            icon.anchor = new google.maps.Point(width / 2, width / 2);
            // eslint-disable-next-line no-undef
            icon.origin = new google.maps.Point(0, 0);
            // eslint-disable-next-line no-undef
            icon.labelOrigin = new google.maps.Point(width / 2, width / 2);
            // eslint-disable-next-line no-undef
            icon.labelAnchor = new google.maps.Point(width / 2, width / 2);
            return icon;
        }

        /**
         * Display radius of stop
         * @param {Array} stop stop object
         * @param {Number} stop.radius radius of stop
         * @param {Number} stop.newRadius new radius of stop
         * @param {Object} position position of marker
         * @param {Boolean} isFuture stop is future stop or not
         * @param {Boolean} isAggregationMarker stop marker is in an aggregation or not
         */
        function displayRadius(stop, position, isFuture, isAggregationMarker = false) {
            const opacityRadius = 0.3;
            let opacityNewRadius = 0.3;

            if (stop?.radius != null && stop?.newRadius != null) {
                if (stop.radius > stop.newRadius) {
                    opacityNewRadius = isAggregationMarker ? 0.5 : 0.7;
                } else if (stop.radius === stop.newRadius) {
                    opacityNewRadius = 0;
                }
            }
            if (stop?.radius != null && isFuture) {
                const color = getColorFromIndex(0);
                $scope.radiusCircle = new google.maps.Circle({
                    strokeColor: color,
                    strokeOpacity: 0.9,
                    strokeWeight: 1.5,
                    radius: stop.radius,
                    center: position,
                    map: $scope.map,
                    fillColor: color,
                    fillOpacity: opacityRadius,
                });
                $scope.radiuses.push($scope.radiusCircle);
            }
            if (stop?.newRadius != null && isFuture) {
                const color = getColorFromIndex(2);
                $scope.newRadiusCircle = new google.maps.Circle({
                    strokeColor: color,
                    strokeOpacity: 0.9,
                    strokeWeight: 1.5,
                    radius: stop.newRadius,
                    center: position,
                    map: $scope.map,
                    fillColor: color,
                    fillOpacity: opacityNewRadius,
                });
                $scope.radiuses.push($scope.newRadiusCircle);
            }
        }

        /**
         * @param {string} key position key
         * @param {number} count coutner postion
         * @param {object} stops stop object
         */
        function createAgreggationMarker(key, count, stops) {
            $scope.aggIndex++;

            const stopListRealStopFirst = stops.sort((a, b) => (a.timestamp && !b.timestamp ? -1 : 1));
            const firstStop = stopListRealStopFirst[0];
            const isFuture = firstStop.timestamp == null;
            const iconColor = isFuture ? firstStop.icon.strokeColor : firstStop.icon.fillColor;
            // isSkipped can be false or null
            const isStopSkipped = firstStop.isSkipped === true;
            // NOTE: We always assume that the aggregation marker is not a stop transfer.
            // This is because the aggregation marker should always be a circle.
            const isStopTransfer = false;
            // Make sure the icon is created big.
            const icon = getOrCreateIcon($scope.map.getZoom(), firstStop.isSchool, isStopTransfer, isStopSkipped, isFuture, iconColor, 'm');
            const color = isFuture ? firstStop.icon.strokeColor : '#FFFFFF';
            const label = '+';
            let lat = key.split(',')[0].trim();
            lat = lat.split('(')[1];
            let long = key.split(',')[1].trim();
            long = long.split(')')[0];

            const coords = {
                latitude: lat,
                longitude: long,
            };

            // eslint-disable-next-line no-undef
            const marker = new google.maps.Marker({
                draggable: false,
                // eslint-disable-next-line no-undef
                position: new google.maps.LatLng(coords.latitude, coords.longitude),
                class: 'aggregation',
                icon: icon,
                label: {
                    text: label,
                    color: color,
                    fontWeight: 'bold',
                    fontSize: '18px',
                    textAlign: 'center',
                },
                zIndex: 100 + $scope.aggIndex,
                isFuture: isFuture,
                // optimized: false
            });

            const position = new google.maps.LatLng(coords.latitude, coords.longitude);

            if ($scope.singleTripView) {
                marker.optimized = false;
            }

            // Stop radiuses for radius map are always shown
            if ($scope.currentView === 'routes/:id/radius') {
                for (const stop of stopListRealStopFirst) {
                    displayRadius(stop.stop, position, isFuture, true);
                }
            }

            marker.addListener('click', function () {
                if ($scope.currentView !== 'routes/:id/radius') {
                    for (const radius of $scope.radiuses) {
                        radius.setMap(null);
                    }
                    for (const stop of stopListRealStopFirst) {
                        displayRadius(stop.stop, position, isFuture, true);
                    }

                    const newInfoWindow = createMultiMarkersInfoWindow(key, count);
                    closeInfoWindow(newInfoWindow, position, marker);
                }
            });

            marker.addListener('dblclick', function () {
                const zoom = $scope.map.getZoom() + 2;
                // eslint-disable-next-line no-undef
                setMapPosition(new google.maps.LatLng(coords.latitude, coords.longitude), zoom);
            });

            $scope.markers.push(marker);
        }

        /**
         * @param {object} position object marker position
         * @param {string} timestamp time stamp used for icon
         * @param {string} markerClass marker css class
         * @param {object} icon marker icon
         * @param {number} zIndex index for marker
         * @param {string} tripId trip id
         * @param {object} trip trip object
         * @param {string} color color for marker
         * @param {object} stop stop trip object
         * @param {object} waypoint waypoint trip object
         * @param {number} stopSeq stop sequence
         * @param {options} options options for marker
         * @param {options.disableSave} disableSave disable save marker in markers array
         * @param {options.disableClickEvents} disableClickEvents disable click events on this marker
         * @return {object} Created marker
         */
        function createMarker(position, timestamp, markerClass, icon, zIndex, tripId, trip, color, stop, waypoint, stopSeq, options) {
            const isSchool = stop?.isSchool ?? false;

            // eslint-disable-next-line no-undef
            const marker = new google.maps.Marker({
                draggable: false,
                position: position,
                class: markerClass,
                tripId: tripId,
                icon: icon,
                zIndex: zIndex + stopSeq,
                isSchool: isSchool,
            });

            // Number stops by sequence number on single trip, route details or radius modification pages
            if ($scope.singleTripView) {
                marker.optimized = false;
                if (stopSeq != null && stopSeq != 0) {
                    const tripColor = color === marker.icon.fillColor ? '#FFFFFF' : color;
                    marker.setTitle('#' + stopSeq);
                    marker.setLabel({
                        text: stopSeq.toString(),
                        color: tripColor,
                        fontWeight: 'bold',
                        fontSize: '14px',
                        zIndex: zIndex + stopSeq,
                    });
                }
            }

            if ($scope.currentView === 'routes/:id/radius') {
                if (marker.class !== 'waypointMarker') {
                    displayRadius(stop, position, marker.icon.isFuture);
                }
            }

            // When you click on the marker
            if (options?.disableClickEvents !== true) {
                marker.addListener('click', function () {
                    if ($scope.currentView !== 'routes/:id/radius') {
                        for (const radius of $scope.radiuses) {
                            radius.setMap(null);
                        }
                        if (marker.class !== 'waypointMarker') {
                            displayRadius(stop, position, marker.icon.isFuture);
                        }

                        const newInfoWindow = createInfoWindow(marker, trip, timestamp, color, stop, waypoint);
                        closeInfoWindow(newInfoWindow, position, marker);
                    }
                });

                marker.addListener('dblclick', function () {
                    const zoom = $scope.map.getZoom() + 2;
                    setMapPosition(position, zoom);
                });
            }

            if (options?.disableSave !== true) {
                $scope.markers.push(marker);
            }

            return marker;
        }

        /**
         * Creating the device location marker of map for the trip details page
         * @param {Object} device
         */
        function createDeviceLocationMarker(device) {
            const position = new google.maps.LatLng(device.location.latitude, device.location.longitude);

            const size = $scope.iconSizes['l'];
            const anchorPoint = size / 2;
            const icon = {
                url: './assets/img/markers/ic_device_location.png',
                anchor: new google.maps.Point(anchorPoint, anchorPoint),
                scaledSize: new google.maps.Size(size, size),
            };

            const marker = new google.maps.Marker({
                position: position,
                map: $scope.map,
                icon: icon,
            });

            const content = generateInfoBoxDeviceLocation(device);

            marker.addListener('click', function () {
                const newInfoWindow = new google.maps.InfoWindow();
                newInfoWindow.setPosition(position);
                newInfoWindow.setContent(content);

                if ($scope.infoWindow) $scope.infoWindow.close();
                $scope.infoWindow = newInfoWindow;
                $scope.infoWindow.open($scope.map, marker);
            });

            $scope.deviceLocationMarkers.push(marker);
        }

        /**
         * Creating the form location marker of map for the dispatch form details page if there is a formLocation
         * @return {Promise}
         */
        function createDispatchFormLocationMarker() {
            return new Promise(function (resolve, reject) {
                if ($scope.formLocation?.longitude && $scope.formLocation?.latitude) {
                    const position = new google.maps.LatLng($scope.formLocation.latitude, $scope.formLocation.longitude);

                    const size = $scope.iconSizes['xl'];
                    const anchorPoint = size / 2;
                    const icon = {
                        url: './assets/img/markers/ic_form_user-location.svg',
                        anchor: new google.maps.Point(anchorPoint, anchorPoint),
                        scaledSize: new google.maps.Size(size, size),
                    };

                    $scope.formLocationMarker = new google.maps.Marker({
                        position: position,
                        map: $scope.map,
                        title: $translate.instant('dispatchFormDetails_formLocationMap'),
                        icon: icon,
                    });

                    $scope.formLocationMarker.addListener('click', function () {
                        const infoWindow = new google.maps.InfoWindow();
                        infoWindow.setPosition(position);
                        infoWindow.setContent($translate.instant('dispatchFormDetails_formLocationMap'));
                        infoWindow.open($scope.map);
                    });
                } else {
                    $scope.nothingToShow = true;
                }
                resolve();
            });
        }

        function createInfoWindow(marker, trip, timestamp, color, stop, waypoint) {
            const content = getInfoWindowContent(marker, trip, timestamp, color, stop, waypoint);
            const infoWindow = new google.maps.InfoWindow({
                content: content,
                maxWidth: '600px',
            });
            return infoWindow;
        }

        function getInfoWindowContent(marker, trip, timestamp, color, stop, waypoint) {
            const markerClass = marker.class;
            let content;
            if ($scope.currentView !== 'dashboard/map') {
                switch (markerClass) {
                    case 'startMarker':
                        // TODO?
                        break;
                    case 'busMarker':
                        content = generateInfoBoxContentForBus(marker, trip, timestamp, color);
                        break;
                    case 'stopMarker':
                        content = generateInfoBoxContentForStop(marker, trip, timestamp, color, stop);
                        break;
                    case 'waypointMarker':
                        content = generateInfoBoxContentForWaypoint(stop, waypoint);
                        break;
                    case 'busOnStopMarker':
                        content = generateInfoBoxContentForBusOnStop(marker, trip, timestamp, color, stop);
                        break;
                    case 'endMarker':
                        // TODO?
                        break;
                    default:
                        break;
                }
                return content;
            } else {
                if (markerClass === 'busOnStopMarker' || markerClass === 'busMarker') {
                    content = generateInfoBoxContentForBus(marker, trip, timestamp, color);
                }
                return content;
            }
        }

        function generateInfoBoxContentForBusOnStop(marker, trip, timestamp, color, stop) {
            // Generate titles
            const typeTitle = $translate.instant('passengers');
            const etaTitle = $translate.instant('plannedTime');
            const realTimeTitle = $translate.instant('realTime');
            const arrivalTypeTitleText = $translate.instant('arrivalType');
            const departureTypeTitleText = $translate.instant('departureType');
            const timeAtStopTitle = $translate.instant('timeAtStop');

            // Generate the info
            const stopName = stop.name;
            const stopAdress = stop.address;
            const currentStop = stop.sequenceNumber;
            const stopKind = stop.kind;
            const studentCount = stop.studentCount;
            const eta = stop.schedule;

            let stopKindSymbol;
            if (stopKind === 'pickup') {
                stopKindSymbol = '<i class="fa fa-arrow-up infoBoxStopPickup" aria-hidden="true"></i>';
            } else {
                stopKindSymbol = '<i class="fa fa-arrow-down infoBoxStopDrop" aria-hidden="true"></i>';
            }

            let timeAtStop;
            let delta;
            let realTime;
            if (timestamp) {
                realTime = moment(!stop.arrival ? timestamp : stop.arrival).format('H:mm');
                delta = $scope.timeDelta(realTime, eta, true);
                if (stop.departure) {
                    const departureAsDate = new Date(stop.departure);
                    const arrivalAsDate = new Date(stop.arrival ? stop.arrival : timestamp);
                    const stopDurationInMinutes = (departureAsDate - arrivalAsDate) / (1000 * 60);
                    timeAtStop = Math.round(stopDurationInMinutes);
                    if (timeAtStop < 1) {
                        timeAtStop = '< 1 min';
                    } else {
                        timeAtStop += ' min';
                    }
                } else {
                    timeAtStop = '- - - - - -';
                }
            } else {
                realTime = '-';
                delta = '-';
                timeAtStop = '-';
            }

            // Display or not arrivalType and departureType columns when stop is skipped
            const arrivalTypeTitle = '<div class="col-2 infoBoxBodyTitleNoPad"><p>' + arrivalTypeTitleText + '</p></div>';
            const automaticTypeArrivalText = $scope.stopDepartureArrivalType(stop.arrival, stop.isAutomaticArrival);
            const automaticTypeArrival = '<div class="col-2 infoBoxBodySubtitles"><p>' + automaticTypeArrivalText + '</p></div>';
            const departureTypeTitle = '<div class="col-2 infoBoxBodyTitleNoPad"><p>' + departureTypeTitleText + '</p></div>';
            const automaticTypeDepartureText = $scope.stopDepartureArrivalType(stop.departure, stop.isAutomaticDeparture);
            const automaticTypeDeparture = '<div class="col-2 infoBoxBodySubtitles"><p>' + automaticTypeDepartureText + '</p></div>';

            const columnsNumber = stop.isSkipped ? 3 : 2;
            const classWordsPosition = stop.isSkipped ? 'infoBox__white-space' : 'infoBox__word-spacing';

            // Get colors for delta
            let colorDelta;
            if (delta != null) {
                colorDelta = delta.charAt(1) == '-' ? 'class="delta negative"' : 'class="delta"';
            }

            // BUS INFO
            const busNumber = trip.route.bus.number;
            const plugged = trip.battery.state === 'charging' || trip.battery.state === 'full';
            const batteryPercentage = $scope.getBatteryPercentage(trip.battery.level);
            const carrier = ['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole) ? trip.route.carrier.name || trip.route.carrier : '';
            const client = ['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole) ? trip.route.client.name : '';
            const busNumberTitle = $translate.instant('busNumber');
            const lastSynchroTitle = $translate.instant('lastSynchro');
            const lastSynchro = makeDateRelative(moment(timestamp));
            const onBoardCount = trip.studentCounts.onBoard;
            const noShowCount = trip.studentCounts.noShow;
            let noShowTitle;
            if (noShowCount <= 1) {
                noShowTitle = $translate.instant('absent');
            } else {
                noShowTitle = $translate.instant('absents');
            }

            // Build box header
            let content = '<div class="infoBox">';
            const tripName = busNumber + ' - ' + trip.route.name;
            const tripid = trip.id;
            let header;

            // Add behavior when user is in single trip map
            const singleTripMap = $scope.currentView === 'trips/trip';

            if (singleTripMap) {
                header = `
                <div class="infoBoxTitleBox col-12" style="background-color:${color}">
                    <span class= "infoBoxNoClickableTitle">
                        ${tripName}
                    </span>
                </div>`;
            } else {
                header = `
                <div class="infoBoxTitleBox col-12" style="background-color:${color}">
                    <a class="infoBoxTitle" href="#!/trips/trip?id=${tripid}">
                        ${tripName}
                    </a>
                </div>`;
            }

            // Build box body.
            let body = '<div class="infoBoxBodyBox">';
            body =
                body +
                `
                <div class="col-12">
                <p class="infoBoxBodyTitle">#  ${currentStop} - ${stopName}</p>
                <p class="infoBoxBodySubtitles">
                  ${stopAdress}
                </p>
                <p class="infoBoxBodySubtitles">
                    ${carrier} ${client}
                </p>
                <p class="infoBoxBodySubtitles">
                  <span class="makeItBold">
                    ${busNumberTitle}
                  </span>
                    : ${busNumber}
                </p>
              <br />
          </div>
          <div class="col-12 d-flex justify-content-center">
            <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                <p class='infoBox__no-wrap'>${typeTitle}</p>
            </div>
            <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                <p class='${classWordsPosition}'>${etaTitle}</p>
            </div>
            <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                <p class='${classWordsPosition}'>${realTimeTitle}</p>
            </div>
            ${stop.isSkipped ? '' : `${arrivalTypeTitle}`}
            ${stop.isSkipped ? '' : `${departureTypeTitle}`}
            <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
            <p class='infoBox__white-space'>${timeAtStopTitle}</p> 
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center">
        <div class="col-${columnsNumber} infoBoxBodySubtitles">
            <p>${studentCount} ${stopKindSymbol}</p>
          </div>
          <div class="col-${columnsNumber} infoBoxBodySubtitles">
            <p>${eta}</p>
          </div>
          <div class="col-${columnsNumber} infoBoxBodySubtitles">
          <p>${realTime}</p>
          <p><span ${colorDelta}>${delta}</span></p>
          </div>
          ${stop.isSkipped ? '' : `${automaticTypeArrival}`}
          ${stop.isSkipped ? '' : `${automaticTypeDeparture}`}
        <div class="col-${columnsNumber} infoBoxBodySubtitles">
            <p>${timeAtStop}</p>
          </div>
          </div>
          <p class="mapInfoBoxLastSynchro col-12 pt-1">
          <span class="makeItBold">${lastSynchroTitle}</span>
           ${lastSynchro}
       </p>
    <div class="col-12 d-flex pt-1">
      <div class="col-6 infoBoxBusPassengerBox">
        <p class="infoBoxOnBoardCount">${onBoardCount}</p>
        <img class="infoBoxOnBoardImg" src="./assets/img/ui/GrayChildren.svg" width="18" height="18" />
        <p class="infoBoxAbsentText">${noShowCount} ${noShowTitle}</p>
      </div>
      <div class="col-6">
          <div class="batteryInfoBox">
            `;
            if (plugged) {
                body = body + '<span class="infoBoxBusStatusIcon status-yellow fa fa-bolt" aria-hidden="true"></span>';
            }
            if (trip.battery.level <= 0.05) {
                body =
                    body +
                    `<span class="infoBoxBusStatusIcon status-red status-slowBlink fa fa-battery-0" aria-hidden="true"></span>
                    <p class="infoBoxTextLabel status-red status-slowBlink">${batteryPercentage}</p>`;
            } else if (trip.battery.level > 0.05 && trip.battery.level <= 0.1) {
                body =
                    body +
                    `<span class="infoBoxBusStatusIcon status-red fa fa-battery-1" aria-hidden="true"></span>
                    <p class="infoBoxTextLabel status-red">${batteryPercentage}</p>`;
            } else if (trip.battery.level > 0.1 && trip.battery.level <= 0.2) {
                body =
                    body +
                    `<span class="infoBoxBusStatusIcon status-yellow fa fa-battery-1" aria-hidden="true"></span>
                  <p class="infoBoxTextLabel status-yellow">${batteryPercentage}</p>`;
            } else if (trip.battery.level > 0.2 && trip.battery.level <= 0.3) {
                body =
                    body +
                    `<span class="infoBoxBusStatusIcon fa fa-battery-1" aria-hidden="true"></span>
                    <p class="infoBoxTextLabel">${batteryPercentage}</p>`;
            } else if (trip.battery.level > 0.3 && trip.battery.level <= 0.5) {
                body =
                    body +
                    `<span class="infoBoxBusStatusIcon fa fa-battery-2" aria-hidden="true"></span>
                    <p class="infoBoxTextLabel">${batteryPercentage}</p>`;
            } else if (trip.battery.level > 0.5 && trip.battery.level <= 0.75) {
                body =
                    body +
                    `<span class="infoBoxBusStatusIcon fa fa-battery-3" aria-hidden="true"></span>
                    <p class="infoBoxTextLabel">${batteryPercentage}</p>`;
            } else if (trip.battery.level > 0.75 && trip.battery.level <= 1.0) {
                body =
                    body +
                    `<span class="infoBoxBusStatusIcon fa fa-battery-4" aria-hidden="true"></span>
                    <p class="infoBoxTextLabel">${batteryPercentage}</p>`;
            }
            body = body + '</div>' + '</div>' + '</div>';
            content = content + header + body;
            content = content + '</div>';
            return content;
        }

        /**
         * @param {Number} currentStopSequenceNumber
         * @param {Array} stops
         * @return {String} bus status
         */
        function progressBusStatus(currentStopSequenceNumber, stops) {
            let progressBusStatus = '';
            // last stop status
            if (stops.length < currentStopSequenceNumber) {
                progressBusStatus = $translate.instant('tripProgress_tripFinished');
            } else {
                const isBusAtStop = currentStopSequenceNumber % 1 === 0;
                const translateKey = isBusAtStop ? 'tripProgress_atStop' : 'tripProgress_onDirection';
                // Math.ceil used to round off number passed as parameter to its nearest integer so as to get greater value
                const stopSequenceNumber = Math.ceil(currentStopSequenceNumber);
                // When bus is on direction, display the future stop name
                const stopName = getStopNameForATrip(stopSequenceNumber, stops);

                progressBusStatus = $translate.instant(translateKey, {
                    stopSequence: stopSequenceNumber,
                    stopName: stopName,
                });
            }

            return progressBusStatus;
        }

        function generateInfoBoxContentForBus(marker, trip, timestamp, color) {
            // Build the info needed
            const busNumber = trip.route.bus.number;
            const carrier = ['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole) ? trip.route.carrier.name || trip.route.carrier : '';
            const client = ['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole) ? trip.route.client.name : '';
            const vehicleIdentifier =
                trip.vehicle != null && trip.vehicle.identifier.length > 1 ? trip.vehicle.identifier : $translate.instant('notAvailable');
            const plannedSchedule = `${trip.route.schedule.departure} - ${trip.route.schedule.arrival}`;
            const carrierTitle = ['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole) ? $translate.instant('carrier') : '';
            const clientTitle = ['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)
                ? $translate.instant('client')
                : '';
            const busNumberTitle = $translate.instant('busNumber');
            const vehicleTitle = $translate.instant('vehicle');
            const plannedScheduleTitle = $translate.instant('plannedSchedule');
            const locateOnGoogleMaps = $translate.instant('locateVehicleGoogleMaps');
            const statusTitle = $translate.instant('status');
            const progressTitle = $translate.instant('tripProgress_progress');
            const progressStopName = progressBusStatus(trip.currentStopSequenceNumber, trip.stops);

            const currentStatusData = [];

            if ($scope.currentView === 'dashboard/map') {
                const delayIndex = trip.states.findIndex((stateItem) => stateItem.state === 'late');
                if (delayIndex > -1) {
                    const tripDelayValue = trip.states[delayIndex].value;
                    if (tripDelayValue <= 15) {
                        currentStatusData.push($translate.instant('tripStatus_10To15MinLate'));
                    } else {
                        currentStatusData.push($translate.instant('tripStatus_moreThan15MinLate'));
                    }
                }

                const studentKeptOnBoardIndex = trip.states.findIndex((stateItem) => stateItem.state === 'studentKeptOnBoard');
                if (studentKeptOnBoardIndex > -1 && trip.states[studentKeptOnBoardIndex].value > 0) {
                    currentStatusData.push($translate.instant('tripStatus_studentsKeptOnBoard'));
                }

                const isOffline = trip.states.findIndex((stateItem) => stateItem.state === 'offline') > -1;
                if (isOffline === true) {
                    currentStatusData.push($translate.instant('tripStatus_lostCommunication'));
                }

                const batteryIndex = trip.states.findIndex((stateItem) => stateItem.state === 'battery');
                if (batteryIndex > -1) {
                    const batteryData = trip.states[batteryIndex];
                    if (batteryData.batteryState === 'unknown') {
                        currentStatusData.push($translate.instant('tripStatus_unknownBatteryLevel'));
                    } else if (batteryData.value <= 0.1) {
                        currentStatusData.push($translate.instant('tripStatus_lowBattery'));
                    }
                }

                if (currentStatusData.length === 0) {
                    currentStatusData.push($translate.instant('tripStatus_allGood'));
                }

                let lastStop = getStopNameForATrip(trip.currentStopSequenceNumber, trip.stops);
                if (lastStop === null) {
                    lastStop = $translate.instant('none');
                }
            }

            const lastSynchroTitle = $translate.instant('lastSynchro');
            const lastSynchro = makeDateRelative(moment(timestamp));

            // Build box header
            let content = `<div class="infoBox">`;

            const tripName = busNumber + ' - ' + trip.route.name;
            const tripid = trip.id;

            let header;
            if ($scope.currentView === 'trips/trip') {
                header = `<div class="infoBoxTitleBox col-12" style="background-color:${color}">
                    <span class="infoBoxNoClickableTitle">${tripName}</span>
                </div>`;
            } else {
                header = `<div class="infoBoxTitleBox col-12" style="background-color:${color}">
                    <a class="infoBoxTitle" href="#!/trips/trip?id=${tripid}">${tripName}</a>
                </div>`;
            }

            // Build box body.
            let body = `<div class="infoBoxBodyBox">`;
            body =
                body +
                `<div class="col-12 d-flex justify-content-around">
                    <div>
                        <p class="infoBoxBodyTitle px-0">${busNumberTitle}</p>
                        <p class="infoBoxBodySubtitles">${busNumber}</p>
                    </div>
                    <div>
                        <p class="infoBoxBodyTitle px-0">${vehicleTitle}</p>
                        <p class="infoBoxBodySubtitles">${vehicleIdentifier}</p>
                    </div>
                    <div>
                        <p class="infoBoxBodyTitle px-0">${clientTitle}${carrierTitle}</p>
                        <p class="infoBoxBodySubtitles">${client}${carrier}</p>
                    </div>
                    <div>
                        <p class="infoBoxBodyTitle px-0">${plannedScheduleTitle}</p>
                        <p class="infoBoxBodySubtitles">${plannedSchedule}</p>
                    </div>
            </div>`;

            // Adds status only for main map
            if ($scope.currentView === 'dashboard/map') {
                body =
                    body +
                    `<div class="col-12 pl-0">
                        <div class="d-flex mt-4">
                            <div class="col-12 pl-2">
                                <span class="infoBoxBodyTitle pl-1">${statusTitle}:</span>`;
                currentStatusData.forEach((stateItem, stateItemIndex) => {
                    body =
                        body +
                        `<span class="infoBoxBodySubtitles">
                            ${stateItem} ${stateItemIndex < currentStatusData.length - 1 ? '/' : ''}
                        </span>`;
                });
                body =
                    body +
                    `       </div>
                        </div>
                    </div>`;
                body =
                    body +
                    `<div class="col-12 pl-0">
                        <div class="d-flex mt-1">
                            <div class="col-12 pl-2">
                                <span class="infoBoxBodyTitle pl-1">${progressTitle}</span>`;

                body =
                    body +
                    `<span class="infoBoxBodySubtitles">
                        ${progressStopName}
                        </span>`;

                body =
                    body +
                    `       </div>
                        </div>
                    </div>`;
            }

            // Adds last sync info
            body =
                body +
                `
                        </div>
                    </div>
                </div>
                <p class="mapInfoBoxLastSynchro col-12">
                    <span class="makeItBold">${lastSynchroTitle} </span>${lastSynchro}
                    <a href="https://www.google.com/maps/search/?api=1&query=${marker.getPosition().lat()},${marker.getPosition().lng()}"
                        class="mapExternalLink"
                        target="_blank"
                    > 
                        ${locateOnGoogleMaps}
                        <img class="mapExternalLinkIcon"/>
                    </a>
                </p>
            </div>`;

            content = content + header + body;
            content = content + '</div>';
            return content;
        }

        /**
         * Generate the content for the info box of the Waypoint marker
         * @param {Object} stop
         * @param {Object} waypoint
         * @return {String} content of the info box
         */
        function generateInfoBoxContentForWaypoint(stop, waypoint) {
            const { name: stopName, sequenceNumber: stopSequenceNumber, waypoints } = stop;
            const { name: waypointName, waypointSeq, time } = waypoint;

            const sequenceNumberTitle = $translate.instant('waypointSequenceNumber');
            const etaTitle = $translate.instant('plannedTime');
            const waypointToStopTitle = $translate.instant('waypointToStopTitle', {
                stopSequenceNumber: stopSequenceNumber,
                stopName: stopName,
            });

            return `
                <div class="infoBox">
                    <div class="infoBoxTitleBox col-12" style="border:1px solid red;">
                        <span class="infoBoxTitle__stopPlanned" />
                            ${waypointName}
                        </span>
                        <br />
                        <span class="infoBoxTitle__stopPlanned" />
                            ${waypointToStopTitle}
                        </span>
                    </div>
                    <div class="infoBoxBodyBox">
                        <br />
                        <br />
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                            <div class="col-6 infoBoxBodyTitleNoPad">
                                <p>${sequenceNumberTitle}</p>                     
                            </div>
                            <div class="col-6 infoBoxBodyTitleNoPad">
                                <p>${etaTitle}</p>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                            <div class="col-6 infoBoxBodySubtitles">
                                <p>${waypointSeq}/${waypoints.length}</p>
                            </div>
                            <div class="col-6 infoBoxBodySubtitles">
                                <p>${time || '-'}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              `;
        }

        function generateInfoBoxContentForStop(marker, trip, timestamp, color, stop) {
            // Generate titles
            const typeTitle = $translate.instant('passengers');
            const etaTitle = $translate.instant('plannedTime');
            const detectionRadiusTitle = $translate.instant('detectionRadius');
            const realTimeTitle = $translate.instant('realTime');
            const arrivalTypeTitleText = $translate.instant('arrivalType');
            const departureTypeTitleText = $translate.instant('departureType');
            const none = $translate.instant('none');
            const timeAtStopTitle = $translate.instant('timeAtStop');

            // Generate the info
            const {
                name: stopName,
                address: stopAdress,
                sequenceNumber: currentStop,
                kind: stopKind,
                studentCount: count,
                schedule,
                onBoardedCount,
                offBoardedCount,
                arrival,
                departure,
                isSkipped,
                isAutomaticArrival,
                isAutomaticDeparture,
                radius,
            } = stop;

            let skippedAt;
            if (isSkipped && isAutomaticArrival && isAutomaticDeparture) {
                skippedAt = $translate.instant('skippedAutomaticallyAt');
            } else {
                skippedAt = $translate.instant('skippedManuallyAt');
            }

            let stopKindSymbol;
            if (stopKind === 'pickup') {
                stopKindSymbol = '<i class="fa fa-arrow-up infoBoxStopPickup" aria-hidden="true"></i>';
            } else {
                stopKindSymbol = '<i class="fa fa-arrow-down infoBoxStopDrop" aria-hidden="true"></i>';
            }

            let studentCount;
            if (timestamp && schedule && trip.preferences.studentsBoardingRegistrations) {
                const displayedCount = stopKind === 'pickup' ? onBoardedCount : offBoardedCount;
                studentCount = `${displayedCount}/${count}`;
            } else {
                studentCount = count;
            }

            const detectionRadius = radius != null ? `${radius} m` : $translate.instant('notAvailable');

            let timeAtStop;
            let delta;
            let realTime;
            if (timestamp && schedule && !isSkipped) {
                realTime = moment(!arrival ? timestamp : arrival).format('H:mm');
                delta = $scope.timeDelta(realTime, schedule, true);
                if (departure) {
                    const departureAsDate = new Date(departure);
                    const arrivalAsDate = new Date(arrival ? arrival : timestamp);
                    const stopDurationInMinutes = (departureAsDate - arrivalAsDate) / (1000 * 60);
                    timeAtStop = Math.round(stopDurationInMinutes);
                    if (timeAtStop < 1) {
                        timeAtStop = '< 1 min';
                    } else {
                        timeAtStop += ' min';
                    }
                } else {
                    timeAtStop = '- - - - - -';
                }
            } else if (isSkipped && arrival) {
                realTime = `${skippedAt} ${moment(arrival).format('H:mm')}`;
                delta = '';
                timeAtStop = none;
            }

            // Display or not arrivalType and departureType columns when stop is skipped
            const arrivalTypeTitle = '<div class="col-2 infoBoxBodyTitleNoPad"><p>' + arrivalTypeTitleText + '</p></div>';
            const automaticTypeArrivalText = $scope.stopDepartureArrivalType(stop.arrival, stop.isAutomaticArrival);
            const automaticTypeArrival = '<div class="col-2 infoBoxBodySubtitles"><p>' + automaticTypeArrivalText + '</p></div>';
            const departureTypeTitle = '<div class="col-2 infoBoxBodyTitleNoPad"><p>' + departureTypeTitleText + '</p></div>';
            const automaticTypeDepartureText = $scope.stopDepartureArrivalType(stop.departure, stop.isAutomaticDeparture);
            const automaticTypeDeparture = '<div class="col-2 infoBoxBodySubtitles"><p>' + automaticTypeDepartureText + '</p></div>';

            const columnsNumber = stop.isSkipped ? 3 : 2;
            const classWordsPosition = stop.isSkipped ? 'infoBox__white-space' : 'infoBox__word-spacing';

            // Get colors for delta
            let colorDelta;
            if (delta != null) {
                colorDelta = delta.charAt(1) == '-' ? 'class="delta negative"' : 'class="delta"';
            }
            // Build box header

            let content = '<div class="infoBox">';

            let infoBoxTitleClass;
            let header;
            if ($scope.singleTripView) {
                infoBoxTitleClass = timestamp && stop.schedule ? 'infoBoxNoClickableTitle' : 'infoBoxTitle__stopPlanned';
                header = `
                <div class="infoBoxTitleBox col-12" style="background-color:${timestamp && stop.schedule ? color : '#fff'};border:1px solid red;">
                    <span class="${infoBoxTitleClass}" />
                        #${currentStop} - ${stopName}
                    </span>
                </div>`;
            } else {
                infoBoxTitleClass = timestamp && stop.schedule ? 'infoBoxTitle' : 'infoBoxTitle__red';
                header = `
                <div class="infoBoxTitleBox col-12" style="background-color:${
                    timestamp && stop.schedule ? color : '#fff'
                };border:1px solid ${color};">
                    <a class="${infoBoxTitleClass}" href="#!/trips/trip?id=${trip.id}" />
                        ${trip.route.bus.number} - ${trip.route.name}
                    </a>
                </div>`;
            }

            // Build the body
            let body = `
                  <div class="infoBoxBodyBox">
                  <div class="col-12">`;
            if (!$scope.singleTripView) {
                body += `<p class="infoBoxBodyTitle">#${currentStop}-${stopName}</p>`;
            } else {
                body += '<br />';
            }
            body += `
                      <p class="infoBoxBodySubtitles">${stopAdress}</p>
                      <br />
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                        <p class='infoBox__no-wrap'>${typeTitle}</p>
                      </div>
                      <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                          <p class=${classWordsPosition}>${etaTitle}</p>
                      </div>
              
                    <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                          <p class=${classWordsPosition}>${realTimeTitle}</p>
                      </div>
                      ${stop.isSkipped ? '' : `${arrivalTypeTitle}`}
                      ${stop.isSkipped ? '' : `${departureTypeTitle}`}
                        <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                        <p class='infoBox__white-space'>${timeAtStopTitle}</p> 
                        </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                      <div class="col-${columnsNumber} infoBoxBodySubtitles">
                          <p>${studentCount} ${stopKindSymbol}</p>
                      </div>
                      <div class="col-${columnsNumber} infoBoxBodySubtitles">
                          <p>${schedule}</p>
                      </div>
                      <div class="col-${columnsNumber} infoBoxBodySubtitles">
                      <p>${realTime}</p>
                      <p><span ${colorDelta}>${delta}</span></p>
                      </div>

                      ${stop.isSkipped ? '' : `${automaticTypeArrival}`}
                      ${stop.isSkipped ? '' : `${automaticTypeDeparture}`}
                   <div class="col-${columnsNumber} infoBoxBodySubtitles">
                        <p>${timeAtStop}</p>
                      </div>
                  </div>
              </div>
            `;

            let bodyForPlannedStop = `
                  <div class="infoBoxBodyBox">
                  <div class="col-12 ">`;
            if (!$scope.singleTripView) {
                bodyForPlannedStop += `<p class="infoBoxBodyTitle">#${currentStop}-${stopName}</p>`;
            } else {
                bodyForPlannedStop += '<br />';
            }
            bodyForPlannedStop += `
                      <p class="infoBoxBodySubtitles">${stopAdress}</p>
                      <br />
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <div class="col-4 infoBoxBodyTitleNoPad">
                          <p>${typeTitle}</p>                     
                     </div>
                      <div class="col-4 infoBoxBodyTitleNoPad">
                          <p>${etaTitle}</p>
                      </div>
                      <div class="col-4 infoBoxBodyTitleNoPad">
                          <p>${detectionRadiusTitle}</p>
                      </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                      <div class="col-4 infoBoxBodySubtitles">
                          <p>${studentCount} ${stopKindSymbol}</p>
                      </div>
                      <div class="col-4 infoBoxBodySubtitles">
                          <p>${schedule}</p>
                      </div>
                      <div class="col-4 infoBoxBodySubtitles">
                      <p>${detectionRadius}</p>
                  </div>
                  </div>
              </div>
              `;
            content = content + header + (timestamp && stop.schedule ? body : bodyForPlannedStop);
            content = content + '</div>';
            return content;
        }

        function createMultiMarkersInfoWindow(key, count) {
            const stackedStops = $scope.tripStops[key];
            const color = '#00aaff';
            const none = $translate.instant('none');
            // Build box header
            let content = `<div class="infoBox">`;
            const header = `
              <div class="infoBoxTitleBox col-12" style="background-color:${color}">
                <p class="infoBoxNoClickableTitle">${count}  ${$translate.instant('stackedStops')}</p>
              </div>`;
            let svgIcon;
            let body = `<div class="infoBoxBodyBox">`;

            for (const stopIndex in stackedStops) {
                const completeStop = stackedStops[stopIndex];
                const busNumber = completeStop.trip.route.bus.number;

                // Generate the titles
                const typeTitle = $translate.instant('passengers');
                const etaTitle = $translate.instant('plannedTime');
                const detectionRadiusTitle = $translate.instant('detectionRadius');
                const realTimeTitle = $translate.instant('realTime');
                const arrivalTypeTitleText = $translate.instant('arrivalType');
                const departureTypeTitleText = $translate.instant('departureType');
                const timeAtStopTitle = $translate.instant('timeAtStop');

                let skippedAt;
                if (completeStop.stop.isSkipped && completeStop.stop.isAutomaticArrival && completeStop.stop.isAutomaticDeparture) {
                    skippedAt = $translate.instant('skippedAutomaticallyAt');
                } else {
                    skippedAt = $translate.instant('skippedManuallyAt');
                }

                // Generate the info
                const detectionRadius = completeStop.stop.radius != null ? `${completeStop.stop.radius} m` : $translate.instant('notAvailable');
                const stopKind = completeStop.stop.kind;
                const stopKindSymbol =
                    stopKind === 'pickup'
                        ? '<i class="fa fa-arrow-up infoBoxStopPickup" aria-hidden="true"></i>'
                        : '<i class="fa fa-arrow-down infoBoxStopDrop" aria-hidden="true"></i>';

                let onBoardedCount = 0;
                if (!completeStop.icon.isFuture) {
                    onBoardedCount = stopKind === 'pickup' ? completeStop.stop.onBoardedCount : completeStop.stop.offBoardedCount;
                }
                const studentCount =
                    completeStop.isStudentsBoardingRegistrations && !completeStop.icon.isFuture
                        ? onBoardedCount + '/' + completeStop.stop.studentCount
                        : completeStop.stop.studentCount;
                const eta = completeStop.stop.schedule;
                let timeAtStop;
                let delta;
                let realTime;
                if (completeStop.timestamp && !completeStop.stop.isSkipped) {
                    realTime = moment(!completeStop.stop.arrival ? completeStop.timestamp : completeStop.stop.arrival).format('H:mm');
                    delta = $scope.timeDelta(realTime, eta, true);
                    if (completeStop.stop.departure) {
                        const departureAsDate = new Date(completeStop.stop.departure);
                        const arrivalAsDate = new Date(completeStop.stop.arrival ? completeStop.stop.arrival : completeStop.timestamp);
                        const stopDurationInMinutes = (departureAsDate - arrivalAsDate) / (1000 * 60);
                        timeAtStop = Math.round(stopDurationInMinutes);
                        if (timeAtStop < 1) {
                            timeAtStop = '< 1 min';
                        } else {
                            timeAtStop += ' min';
                        }
                    } else {
                        timeAtStop = '- - - - - -';
                    }
                } else if (completeStop.stop.isSkipped && completeStop.stop.arrival) {
                    realTime = `${skippedAt} ${moment(completeStop.stop.arrival).format('H:mm')}`;
                    delta = '';
                    timeAtStop = none;
                } else if (completeStop.stop.isSkipped && completeStop.stop.arrivalLocation) {
                    realTime = `${skippedAt} ${moment(completeStop.stop.arrivalLocation.timestamp).format('H:mm')}`;
                    delta = '';
                    timeAtStop = none;
                }

                // Display or not arrivalType and departureType columns when stop is skipped
                const arrivalTypeTitle = '<div class="col-2 infoBoxBodyTitleNoPad"><p>' + arrivalTypeTitleText + '</p></div>';
                const automaticTypeArrivalText = $scope.stopDepartureArrivalType(completeStop.stop.arrival, completeStop.stop.isAutomaticArrival);
                const automaticTypeArrival = '<div class="col-2 infoBoxBodySubtitles"><p>' + automaticTypeArrivalText + '</p></div>';
                const departureTypeTitle = '<div class="col-2 infoBoxBodyTitleNoPad"><p>' + departureTypeTitleText + '</p></div>';
                const automaticTypeDepartureText = $scope.stopDepartureArrivalType(
                    completeStop.stop.departure,
                    completeStop.stop.isAutomaticDeparture
                );
                const automaticTypeDeparture = '<div class="col-2 infoBoxBodySubtitles"><p>' + automaticTypeDepartureText + '</p></div>';

                const columnsNumber = completeStop.stop.isSkipped ? 3 : 2;
                const classWordsPosition = completeStop.stop.isSkipped ? 'infoBox__white-space' : 'infoBox__word-spacing';

                // Get colors for delta
                let colorDelta;
                if (delta != null) {
                    colorDelta = delta.charAt(1) == '-' ? 'class="delta negative"' : 'class="delta"';
                }

                let headerTitlePlannedStop;
                let headerTitleRealStop;
                if ($scope.singleTripView) {
                    headerTitlePlannedStop = `<span class="infoBoxNoClickableTitle" style="color:${completeStop.color};">
                        #${completeStop.stop.sequenceNumber} - ${completeStop.stop.name}
                    </span>`;

                    headerTitleRealStop = `<span class= "infoBoxNoClickableTitle">
                        #${completeStop.stop.sequenceNumber} - ${completeStop.stop.name}
                    </span>`;
                } else {
                    headerTitlePlannedStop = `<a class="infoBoxTitle" href="#!/trips/trip?id=${completeStop.tripId}" style="color:${completeStop.color};">
                        ${busNumber} - ${completeStop.trip.route.name}
                    </a>`;

                    headerTitleRealStop = `<a class= "infoBoxTitle" href="#!/trips/trip?id=${completeStop.tripId}">
                    ${busNumber} - ${completeStop.trip.route.name}
                    </a>`;
                }

                if (completeStop.icon.isStopTransfer) {
                    svgIcon = `<svg height="20" width="20">`;
                    svgIcon += `
                  <g>
                 <path
                    fill="${completeStop.icon.fillColor}"
                    stroke="${completeStop.icon.strokeColor}"
                    stroke-width="2"
                    d="M 15.708888,6.7812955 11.817784,13.604296 3.96334,13.646002 9.6442028e-8,6.8647063 3.8911041,0.04170564 11.745548,2.2091287e-7 Z"
                    transform="matrix(0.97483804,0,0,1.1222059,2.834736,2.8503496)" />
                </g>`;
                    svgIcon += `</svg>`;
                } else if (completeStop.icon.isSchool) {
                    svgIcon = `<svg height="20" width="20" style="border:1px ${
                        completeStop.timestamp ? 'white' : completeStop.color
                    } solid;padding:2px;">`;

                    if (completeStop.icon.isFuture) {
                        svgIcon += `
                              <path transform="scale(33)"
                              fill="${completeStop.icon.fillColor}"
                              stroke="${completeStop.icon.strokeColor}"
                              stroke-width="${completeStop.icon.strokeWeight}"
                              d=" ${$scope.squarePath}"
                              />`;
                    }
                    svgIcon += '</svg>';
                } else {
                    svgIcon = `<svg height="20" width="20">`;

                    if (completeStop.icon.strokeColor != 'white' && completeStop.icon.strokeColor != '#FFFFFF' && completeStop.timestamp) {
                        svgIcon += `
                            <circle fill="${completeStop.icon.fillColor}"
                            stroke=" ${completeStop.icon.strokeColor}"
                            stroke-width="${completeStop.icon.strokeWeight}"
                            cx="10" cy="10" r="7"/>;`;
                    }
                    svgIcon += `<circle cx="10" cy="10" r="9" stroke="${
                        completeStop.timestamp ? 'white' : completeStop.color
                    }" stroke-width="2" fill="transparent"/> </svg>`;
                }
                let bodyForPlannedStop = `
                <div class="infoBoxTitleBox infoBoxTitleBoxMultipleStops col-12 d-flex align-items-center" style="border: 1px solid${completeStop.color}">
                    <div class="infoBoxMarkerImage">
                        ${svgIcon}
                    </div>
                    <div class="col-12 infoBoxTitleBoxMultipleStopsTitle" style="text-align: center;">
                        ${headerTitlePlannedStop}
                    </div>
                </div>
                <div class="infoBoxBodyBox">
                <div class="col-12">`;

                if (!$scope.singleTripView) {
                    bodyForPlannedStop += `
                    <p class="infoBoxBodyTitle"># ${completeStop.stop.sequenceNumber} - ${completeStop.stop.name}</p>`;
                } else {
                    bodyForPlannedStop += '<br `>';
                }
                bodyForPlannedStop += `
                    <p class="infoBoxBodySubtitles">${completeStop.stop.address}</p>
                    <br />
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="col-3 infoBoxBodyTitleNoPad">
                        <p>${typeTitle}</p>
                    </div>
                    <div class="col-3 infoBoxBodyTitleNoPad">
                        <p>${etaTitle}</p>
                    </div>
                    <div class="col-3 infoBoxBodyTitleNoPad">
                        <p>${detectionRadiusTitle}</p>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="col-3 infoBoxBodySubtitles">
                        <p>${studentCount} ${stopKindSymbol}</p>
                    </div>
                    <div class="col-3 infoBoxBodySubtitles">
                        <p>${eta}</p>
                    </div>
                    <div class="col-3 infoBoxBodySubtitles">
                        <p>${detectionRadius}</p>
                    </div>
                </div>
                <div class="col-12 spacer-25"></div>
                </div>
            `;
                let bodyForRealStop = `
            <div class="infoBoxTitleBox infoBoxTitleBoxMultipleStops col-12 d-flex align-items-center" style="background-color: ${completeStop.color}">
                <div class="infoBoxMarkerImage">
                    ${svgIcon}
                </div>
                <div class="col-12 infoBoxTitleBoxMultipleStopsTitle" style="text-align: center;">
                ${headerTitleRealStop}
                </div>
            </div>
            <div class="infoBoxBodyBox">
            <div class="col-12">`;
                if (!$scope.singleTripView) {
                    bodyForRealStop += `<p class="infoBoxBodyTitle"># ${completeStop.stop.sequenceNumber} - ${completeStop.stop.name}</p>`;
                } else {
                    bodyForRealStop += '<br />';
                }
                bodyForRealStop += `
                <p class="infoBoxBodySubtitles">${completeStop.stop.address}</p>
                <br />
            </div>
            <div class="col-12 d-flex justify-content-center">
                <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                    <p class='infoBox__no-wrap'>${typeTitle}</p>
                </div>
                <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                    <p class='${classWordsPosition}'>${etaTitle}</p>
                </div>
                    <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                    <p class='${classWordsPosition}'>${realTimeTitle}</p>
                </div>
                ${completeStop.stop.isSkipped ? '' : `${arrivalTypeTitle}`}
                ${completeStop.stop.isSkipped ? '' : `${departureTypeTitle}`}
                <div class="col-${columnsNumber} infoBoxBodyTitleNoPad">
                    <p class='infoBox__white-space'>${timeAtStopTitle}</p> 
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <div class="col-${columnsNumber} infoBoxBodySubtitles">
                    <p>${studentCount} ${stopKindSymbol}</p>
                </div>
                <div class="col-${columnsNumber} infoBoxBodySubtitles">
                    <p>${eta}</p>
                </div>
                <div class="col-${columnsNumber} infoBoxBodySubtitles">
                    <p>${realTime}</p>
                    <p><span ${colorDelta}>${delta}</span></p>
                </div>
                ${completeStop.stop.isSkipped ? '' : `${automaticTypeArrival}`}
                ${completeStop.stop.isSkipped ? '' : `${automaticTypeDeparture}`}
                <div class="col-${columnsNumber} infoBoxBodySubtitles">
                    <p>${timeAtStop}</p>
                </div>
            </div>
            <div class="col-12 spacer-25"></div>
            </div>
        `;
                body = completeStop.timestamp ? body.concat(bodyForRealStop) : body.concat(bodyForPlannedStop);
            }

            body = body + '</div>';

            content = content + header + body;
            content = content + '</div>';

            const infoWindow = new google.maps.InfoWindow({
                content: content,
            });
            return infoWindow;
        }

        /**
         * Generation of the device location info box when we click on the icon
         * @param {Object} device information about device
         * @return {String} content of infobox
         */
        function generateInfoBoxDeviceLocation(device) {
            let content = `<div style="width: 300px">`;

            const header = `
              <div class="infoBoxTitleBox mb-3" style="background-color:white;border:1px solid black">
                <p class="infoBoxNoClickableTitle" style="color:black">${$translate.instant('deviceLocation')}</p>
              </div>`;

            const body = `<div class="infoBoxBodyBox">
                <div><span class="makeItBold">${$translate.instant('driver')}</span> : ${device.driver.email}</div>
                <div> ${$translate.instant('locatedAt')} ${$scope.convertToReadableDate(device.location.timestamp, 'timeOnly')}</div>
            </div>`;

            content = content + header + body;
            content = content + '</div>';

            return content;
        }

        /**
         * Closing function for a new info window when we click elsewhere on the map or on the X
         * @param {Object} newInfoWindow
         * @param {Object} position
         * @param {Object} marker
         */
        function closeInfoWindow(newInfoWindow, position, marker) {
            if ($scope.infoWindow) {
                if ($scope.infoWindow.content === newInfoWindow.content && $scope.infoWindow.getMap() != null) {
                    const zoom = $scope.map.getZoom() + 2;
                    setMapPosition(position, zoom);
                } else {
                    $scope.infoWindow.close();
                    $scope.infoWindow = newInfoWindow;
                    $scope.infoWindow.open($scope.map, marker);
                }
            } else {
                $scope.infoWindow = newInfoWindow;
                $scope.infoWindow.open($scope.map, marker);
            }
        }

        function makeDateRelative(dateToChange) {
            const now = moment();
            if (moment(dateToChange).get('date') < now.get('date') || moment(dateToChange).get('date') > now.get('date')) {
                return moment(dateToChange).format('LLL');
            } else if (moment(dateToChange).add(2, 'hours').isAfter(now)) {
                return moment(dateToChange).fromNow();
            } else {
                return moment(dateToChange).format('LLL');
            }
        }

        /* =======================================================================================================================
     EXTERNAL COMMANDS
  =======================================================================================================================  */
        $scope.initializeGlobalMapForMultipleTrips = function (mapId, trips) {
            return new Promise(function (resolve, reject) {
                if (mapId && trips) {
                    initializeGlobalMapVariables();
                    if (trips.length <= 0) {
                        $scope.noTripToShow();
                        resolve();
                    }
                    for (const trip of trips) {
                        $scope.trips.push(trip);
                    }
                    return initializeMap(mapId).then(function () {
                        $scope.redrawMap();
                        $scope.loading = false;
                        resolve();
                    });
                }
            });
        };

        $scope.refreshGlobalMapForMultipleTrips = function (trips) {
            if (trips) {
                hideTrips();
                prepareScopeForRefresh();
                for (const trip of trips) {
                    $scope.trips.push(trip);
                }

                refreshMap();

                if ($scope.showingSpeedData) {
                    showSpeedData();
                }

                if ($scope.showingWaypoints) {
                    showWaypointsData();
                }
            }
        };

        $scope.initializeGlobalMapWithoutTrip = function (mapId) {
            initializeGlobalMapVariables();
        };

        $scope.noTripToShow = function () {
            $scope.nothingToShow = true;
            $scope.loading = false;
        };

        /**
         * Initialization of map for the dispatch form details page
         * @param {String} mapId
         * @param {Object} location
         * @param {String} location.latitude
         * @param {String} location.longitude
         * @return {Promise} resolution
         */
        $scope.initializeGlobalMapForFormLocation = function (mapId, location = null) {
            return new Promise(function (resolve, reject) {
                initializeGlobalMapVariables();
                $scope.formLocation = location;
                return initializeMapDispatchForm(mapId).then(function () {
                    $scope.redrawMap();
                    $scope.loading = false;
                    resolve();
                });
            });
        };

        $scope.redrawMap = function () {
            google.maps.event.trigger($scope.map, 'resize');
            if ($scope.map) {
                $scope.currentZoomLevel = $scope.map.getZoom();
            }
        };

        $scope.recenterBtnClicked = function () {
            $scope.formLocationMarker ? recenterMapDispatchFormLocation() : recenterMap();
        };

        $scope.toggleSpeedBtnClicked = function () {
            $scope.showingSpeed = !$scope.showingSpeed;
            if ($scope.showingSpeed) {
                showSpeedData();
            } else {
                hideSpeedData();
            }
        };

        $scope.toggleWaypointsBtnClicked = function () {
            $scope.showingWaypoints = !$scope.showingWaypoints;
            if ($scope.showingWaypoints) {
                showWaypointsData();
            } else {
                hideWaypointsData();
            }
        };

        /**
         * Update the map with the new replay time
         * @param {number} progress - number between 0 and 1000
         */
        $scope.updateReplayProgress = function (progress) {
            $scope.replayRenders.push(progress);
        };

        /**
         * Find the coordinate of the bus at the current replay time
         * @param {object} trace
         * @return {object} currentReplayCoordinate
         */
        function findCurrentReplayCoordinate(trace) {
            let currentReplayCoordinate = null;
            trace?.traces?.forEach((tracePart) => {
                tracePart?.coordinates?.forEach((coordinate) => {
                    if (currentReplayCoordinate === null) {
                        currentReplayCoordinate = coordinate;
                    }

                    const replayDateTime = new Date($scope.replayTime);
                    const coordinateDateTime = new Date(coordinate.timestamp);

                    if (coordinateDateTime <= replayDateTime) {
                        currentReplayCoordinate = coordinate;
                    }
                });
            });

            return currentReplayCoordinate;
        }

        /**
         * Find the coordinate of the busses at the current replay time
         * @param {object} traces
         * @return {object} currentReplayCoordinates
         */
        function findCurrentReplayCoordinates(traces) {
            return traces ? traces.map((trace) => findCurrentReplayCoordinate(trace)).filter((t) => t) : [];
        }

        /**
         * Move the bus markers to the current replay time
         */
        function updateReplayBusMarkers() {
            if ($scope.replayBusMarkers.length === 0) {
                const busCoordinates = findCurrentReplayCoordinates($scope.traces);

                $scope.trips.forEach((trip) => {
                    busCoordinates.forEach((busCoordinate) => {
                        const marker = createBusMarker(busCoordinate, $scope.replayEndTime, getColorFromIndex(10), trip.id, trip, {
                            disableSave: true,
                            disableClickEvents: true,
                        });
                        $scope.replayBusMarkers.push(marker);
                        marker.setMap($scope.map);
                    });
                });
            } else {
                const busCoordinates = findCurrentReplayCoordinates($scope.traces);

                busCoordinates.forEach((busCoordinate, index) => {
                    const position = new google.maps.LatLng(busCoordinate.latitude, busCoordinate.longitude);
                    const marker = $scope.replayBusMarkers[index];
                    marker.setPosition(position);
                });
            }
        }

        /**
         * Start the replay engine
         * Render the last position of the bus if there is a new position waiting to be rendered every X frames per second
         * @param {number} fps - number of frames per second (default 24)
         */
        $scope.startReplayEngine = function (fps = 24) {
            const interval = 1000 / fps;

            setInterval(() => {
                if ($scope.replayRenders.length > 0) {
                    const progress = $scope.replayRenders.pop();
                    $scope.replayRenders = [];
                    $scope.replayTime = getReplayTimeFromProgress(progress);
                    removeAllTraces();
                    drawAllTraces();
                    updateReplayBusMarkers();
                }
            }, interval);
        };
    },
]);
