/**
 * Component: selectInput
 * Html tags: <select-input>
 * @param {Array} options to put in the dropdown
 * @param {Number} position position of the select input in the parent table
 * @param {Number} maxPosition max position in the parent table
 * @param {Object} searchValue search value of select input
 * @param {String} searchValue.value
 * @param {Function} fetchOptions optional, function that fetches the options if not already done
 * @param {Object} selectedOption
 * @param {Boolean} optionsOpen options dropdown open or not
 * @param {Boolean} formName if select input is in a form, name of the form
 * @param {Boolean} required if select input is in a form, is it required or not
 * @param {Function} onFocus optional, function is called when input is focused
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('selectInput', {
    templateUrl: './shared/selectInput/selectInput.html',
    bindings: {
        options: '<',
        position: '<',
        maxPosition: '<',
        searchValue: '=',
        fetchOptions: '&?',
        selectedOption: '=',
        optionsOpen: '=',
        formName: '<?',
        required: '<?',
        onFocus: '&?',
    },
    controller: function ($scope, $controller) {
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('ToolsController', { $scope: $scope }));

        this.$onInit = () => {
            this.loading = this.loading ?? false;
            this.options = this.options ?? null;
            this.position = this.position ?? null;
            this.maxPosition = this.maxPosition ?? null;
            this.optionsComplete = null;
            this.searchValue = { value: '' };
            this.optionsOpen = false;
            this.formName = this.formName ?? null;
            this.required = this.required ?? false;
            this.dropdownAbove = false;
            this.dropdownStyle = {};
            this.onFocus = this.onFocus ?? null;
        };

        /**
         * Fetch options if needed, then display them
         * @param {Object} e event
         */
        $scope.fetchData = () => {
            if (this.fetchOptions) {
                Promise.resolve(this.fetchOptions()).then((res) => {
                    this.options = res;
                    this.optionsComplete = angular.copy(this.options);
                    calculateDropdownSize();
                    this.optionsOpen = true;
                    $scope.$apply();
                });
            } else {
                if (this.optionsComplete == null) this.optionsComplete = angular.copy(this.options); // Copies this.options on first click only
                calculateDropdownSize();
                this.optionsOpen = true;
            }
        };

        /**
         * Determine if dropdown is displayed on top of select or at bottom
         * as well as dropdown size
         */
        const calculateDropdownSize = () => {
            this.dropdownAbove = false;

            const dataLength = this.options.length;
            // Sizes in pixels
            const maxOptionsDisplayCount = 5;
            const optionSelectSize = 28;
            const bufferAroundSize = 15;
            const selectSize = 15;

            const newStyle = {};

            if (this.maxPosition == null || this.position == null) {
                this.dropdownAbove = false;
            }

            // If user clicks on actions dropdown that are at the end of the page, display it above the input
            // When this.maxPosition is less than this.position, it includes the previous dropdownItem to be positioned above
            else if (this.maxPosition <= this.position) {
                const dropdownTop =
                    dataLength > maxOptionsDisplayCount
                        ? `-${maxOptionsDisplayCount * optionSelectSize + selectSize + bufferAroundSize}px`
                        : `-${this.options.length * optionSelectSize + selectSize + bufferAroundSize}px`;
                newStyle.top = dropdownTop;
                this.dropdownAbove = true;
            }

            if (dataLength > maxOptionsDisplayCount) {
                const dropdownSize = `${maxOptionsDisplayCount * optionSelectSize + bufferAroundSize}px`;
                newStyle.height = dropdownSize;
            }

            this.dropdownStyle = newStyle;
        };

        /**
         * Filters options according to search value
         * Then re-calculates the options dropdown dimensions
         */
        $scope.applySearch = () => {
            this.selectedOption = null;
            if (this.searchValue.value !== '') {
                if (this.optionsComplete.length > 0) {
                    if (this.optionsComplete[0].name) {
                        this.options = this.optionsComplete.filter((option) =>
                            $scope.containsSearchValue({ name: option.name }, this.searchValue.value)
                        );
                        // If the search value exactly matches an option, select it
                        const exactMatch = this.optionsComplete.find((option) => option.name === this.searchValue.value);
                        if (exactMatch) {
                            $scope.selectOption(exactMatch);
                        }
                    } else {
                        this.options = this.optionsComplete.filter((option) =>
                            $scope.containsSearchValue({ firstName: option.firstName, lastName: option.lastName }, this.searchValue.value)
                        );
                    }
                }
            } else {
                this.options = this.optionsComplete;
            }
            calculateDropdownSize();
        };

        /**
         * Select option and closes the dropdown
         * @param {Object} option
         */
        $scope.selectOption = (option) => {
            this.selectedOption = option;
            this.searchValue.value = option.name ? option.name : `${option.lastName}, ${option.firstName}`;
            this.optionsOpen = false;
        };
    },
});
